<banners></banners>

<quote-management-summary-totals [opportunityQuotes]="data"></quote-management-summary-totals>
<gfb
    class="btn-group flex-btn-group flex-btn-group-first"
    [barId]="barId"
    [entities]="GFB_ENTITIES"
    [defaultKey]="DEFAULT_KEY"
    [defaultEntity]="DEFAULT_ENTITY"
></gfb>
<quote-management-table
    [data]="data"
    [itemsPerPage]="pageSize"
    [offset]="offset"
    [totalItemCount]="total"
    [totalUnfiltered]="totalUnfiltered"
    [selectedRangeFilters]="selectedRangeFilters"
    [isLoading]="loading"
    [filter]="listCallParameters?.filter"
    (pageChange)="onPageChange($event)"
    (itemsPerPageChange)="onPageSizeChange($event)"
    (quoteClick)="onQuoteClick($event)"
    (quoteDownload)="onQuoteDownload($event)"
    (quoteRequestChanges)="onQuoteRequestChanges($event)"
    (quoteAccept)="onQuoteAccept($event)"
    (quotePOUpload)="onQuotePOUpload($event)"
    (sortChange)="onSortChange($event)"
    (selectTimeRangeFilters)="onTimeFilterChanges($event)"
>
</quote-management-table>

<ng-template #quoteManagementModal let-modal>
    <quote-management-modal [opportunityQuote]="quoteToReview" [modal]="modal"> </quote-management-modal>
</ng-template>

<ng-template #quoteRequestChangesModal let-modal>
    <quote-request-changes-modal
        [opportunityQuote]="quoteToReview"
        [modal]="modal"
        [requestChangeAllowed]="hasWritePermission"
        (submitChangesComplete)="fetchData(true)"
    >
    </quote-request-changes-modal>
</ng-template>

<quote-accept-modal
    #quoteAcceptModal
    [opportunityQuote]="quoteToReview"
    [acceptAllowed]="hasWritePermission"
    (acceptComplete)="onAcceptComplete($event)"
/>

<quote-poupload-modal #quotePOUploadModal [opportunityQuote]="quoteToReview"></quote-poupload-modal>
