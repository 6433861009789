import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Options } from '@angular-slider/ngx-slider/options';
import { OpenInPlannerInfo } from '@pure1/data';
import { DEFAULT_MAX_RESERVE_IN_TIB } from '../../../subscription/manage-subscription-modal/license-reserved-amount/license-reserved-amount.component';
import moment from 'moment';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';
import { SimulationsService } from '../../simulation-summary/simulations.service';
import { SimulationStep } from '../../../services/simulation-step.service';
import { WINDOW } from '../../../app/injection-tokens';
import { ToastService, ToastType } from '../../../services/toast.service';
import { ForecastedLicense, ForecastedLicensesManager } from '../../services/forecasted-licenses-manager.service';
import { SimulationUiStatusService } from '../../services/simulation-ui-status.service';

export const BYTES_PER_TIB = 1024 * 1024 * 1024 * 1024;

@Component({
    selector: 'simulate-license-reserve-level-modal',
    templateUrl: './simulate-license-reserve-level-modal.component.html',
})
export class SimulateLicenseReserveLevelModalComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly activeModal: NgbActiveModal;
    @Input() readonly forecastedLicense: ForecastedLicense;
    @Input() readonly timeRange: moment.Duration;
    @Input() readonly proactiveRec: OpenInPlannerInfo;

    sliderOptions: Options;
    maxReservedAmountInTiB: number = DEFAULT_MAX_RESERVE_IN_TIB;
    reservedAmountInTiB: number = 0;
    usedAmountInTiB: number = 0;
    newAmountInTiB: number = 0;
    linearScaleEnd: number = 0;

    simulatedOnDemand: number | string;
    agreedOnTermsAndContracts: boolean = false;
    isSaving = false;

    private readonly destroy$ = new Subject<void>();

    readonly userInputNewAmount$ = new Subject<number>();
    readonly updateSimulatedOnDemand$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private simulationsService: SimulationsService,
        private forecastedLicensesManager: ForecastedLicensesManager,
        private simulationUiStatusService: SimulationUiStatusService,
        private toast: ToastService,
    ) {}

    ngOnInit(): void {
        this.updateSimulatedOnDemand$
            .pipe(
                debounceTime(250),
                switchMap(() => {
                    const licenseId = this.forecastedLicense?.id;
                    if (!licenseId) {
                        return of(null);
                    }
                    return this.forecastedLicensesManager.getOnDemandWithSimulationObservable$(
                        this.forecastedLicense,
                        true,
                    );
                }),
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: capacityFull => {
                    if (this.forecastedLicense?.reservedAmount !== 0 && capacityFull?.fullInDays != null) {
                        this.simulatedOnDemand = capacityFull.fullInDays;
                    } else {
                        this.simulatedOnDemand = '';
                    }
                },
                error: err => {
                    console.warn('Get Days to On-demand with simulation failed', err);
                    this.simulatedOnDemand = '';
                },
            });

        this.userInputNewAmount$
            .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe(value => {
                this.onNewAmountChange(value);
            });

        if (this.proactiveRec) {
            this.onNewAmountChange(this.proactiveRec.incident.getEvergreenOneDetails().recommendedReserveTiB);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.forecastedLicense && this.forecastedLicense) {
            this.agreedOnTermsAndContracts = false;
            this.reservedAmountInTiB = Math.floor(this.forecastedLicense.reservedAmount / BYTES_PER_TIB);
            this.usedAmountInTiB = Math.floor(this.forecastedLicense.licenseUsage.current / BYTES_PER_TIB);
            this.newAmountInTiB = this.forecastedLicense.simulatedLicense?.reservedAmount
                ? Math.floor(this.forecastedLicense.simulatedLicense?.reservedAmount / BYTES_PER_TIB)
                : this.reservedAmountInTiB;
            this.linearScaleEnd = 2 * this.reservedAmountInTiB;
            this.sliderOptions = this.createSliderOptions();
            this.simulatedOnDemand = this.forecastedLicense.simulatedLicense?.fullInDays ?? '';
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
        this.simulationsService.setLicenseSpeculativeSteps([]);
    }

    cancelModal(): void {
        this.activeModal.close();
    }

    save(): void {
        this.isSaving = true;
        this.simulationsService
            .applySpeculativeSteps()
            .pipe(take(1), takeUntil(this.destroy$))
            .subscribe(
                () => {
                    this.toast.add(ToastType.success, 'License simulation applied!');
                    this.isSaving = false;
                    this.cancelModal();
                },
                err => {
                    this.isSaving = false;
                    console.error('Failed to apply simulation', err);
                    const errMessage = err?.message || 'Unexpected error.';
                    this.toast.add(ToastType.error, `Failed to apply simulation: ${errMessage}`);
                },
            );
    }

    maxLength(): number {
        return this.maxReservedAmountInTiB.toString().length;
    }

    getReserveLevelExpansionPercentage(): string {
        if (this.reservedAmountInTiB === 0) {
            return '-';
        }

        return ((100 * (this.newAmountInTiB - this.reservedAmountInTiB)) / this.reservedAmountInTiB).toFixed(0) + '%';
    }

    getUsagePercentage(reservedAmount: number): string {
        if (reservedAmount === 0) {
            return '-';
        }

        return ((100 * this.usedAmountInTiB) / reservedAmount).toFixed(0) + '%';
    }

    supportRequestQuote(): boolean {
        return (
            this.forecastedLicense &&
            !this.simulationUiStatusService.getLicenseRequestQuoteButtonStatus(this.forecastedLicense).disabled
        );
    }

    onNewAmountChange(newAmountInput: number): void {
        if (!this.forecastedLicense) {
            return;
        }

        if (isNaN(newAmountInput)) {
            this.newAmountInTiB = this.reservedAmountInTiB;
        } else if (newAmountInput > this.maxReservedAmountInTiB) {
            this.newAmountInTiB = this.maxReservedAmountInTiB;
        } else if (newAmountInput < this.reservedAmountInTiB) {
            this.newAmountInTiB = this.reservedAmountInTiB;
        } else {
            this.newAmountInTiB = newAmountInput;
        }

        const expandLicenseReserveLevelStep = SimulationStep.createExpandLicenseReserveLevelStep(
            this.forecastedLicense.license,
            {
                license_reserve_level: this.newAmountInTiB * BYTES_PER_TIB,
            },
        );
        // use next cycle to avoid ExpressionChangedAfterItHasBeenCheckedError
        // most likely some of the parent components consuming chart data changed by setSpeculativeSteps
        this.window.setImmediate(
            // setting up speculative step will automatically fetch projection data.
            () => this.simulationsService.setLicenseSpeculativeSteps([expandLicenseReserveLevelStep]),
        );
        this.updateSimulatedOnDemand$.next(null);
    }

    getRequestQuoteBtnTooltip(): string {
        if (this.newAmountInTiB === this.reservedAmountInTiB) {
            return 'Please expand the reserve level to request a quote.';
        }

        if (!this.agreedOnTermsAndContracts) {
            return 'Please agree to the terms and contracts to request a quote.';
        }

        return '';
    }

    showSliderHelp(): boolean {
        return this.linearScaleEnd <= DEFAULT_MAX_RESERVE_IN_TIB;
    }

    private createSliderOptions(): Options {
        return {
            floor: 0,
            ceil: DEFAULT_MAX_RESERVE_IN_TIB,
            minLimit: this.reservedAmountInTiB,
            ticksArray: [this.usedAmountInTiB, this.reservedAmountInTiB, this.linearScaleEnd],
            showSelectionBarFromValue: this.reservedAmountInTiB,

            getTickColor: (value: number): string => {
                if (value === this.usedAmountInTiB) {
                    return '#F156A6';
                }
                if (value === this.reservedAmountInTiB) {
                    return '#0db9f0';
                }
                return '#222222';
            },
            getSelectionBarColor: (value: number): string => {
                return '#03A678';
            },
            getLegend: (value: number) => {
                if (value === this.usedAmountInTiB && this.usedAmountInTiB !== 0) {
                    return (
                        '<div class="used-amount-legend">' +
                        this.usedAmountInTiB +
                        'T <span class="used-amount-legend-text">Used</span></div>'
                    );
                }

                if (value === this.reservedAmountInTiB) {
                    return (
                        '<div class="reserved-amount-legend">' +
                        this.reservedAmountInTiB +
                        'T <span class="reserved-amount-legend-text">Reserved</span></div>'
                    );
                }

                if (this.linearScaleEnd <= DEFAULT_MAX_RESERVE_IN_TIB && value === this.linearScaleEnd) {
                    return '<div class="linear-scale-end-reserved-amount-legend">2 x Current Level</div>';
                }
                return '';
            },
            translate: (value: number): string => {
                return '';
            },
            /*
                For better UX, we use following algorithm to scale the slider bar
                we have the first 2/3 of the slider bar in linear scale with the rest 1/3 in quadratic scale
                the range of the linear scale bar is [0, 2 x reservedAmount]
                the range of the quadratic scale bar is [2 x reservedAmount, DEFAULT_MAX_RESERVE_IN_TIB]
             */
            customValueToPosition: (value: number, minVal: number, maxVal: number): number => {
                const linearRangeEnd = this.linearScaleEnd;

                // if reservedAmount * 2 > DEFAULT_MAX_RESERVE_IN_TIB, just do linear scale for all
                if (linearRangeEnd === 0 || linearRangeEnd >= DEFAULT_MAX_RESERVE_IN_TIB) {
                    return value / DEFAULT_MAX_RESERVE_IN_TIB;
                }

                if (value <= linearRangeEnd) {
                    // linear scale
                    return ((2 / 3) * value) / linearRangeEnd;
                } else {
                    // quadratic scale
                    value = Math.sqrt(value - linearRangeEnd);
                    return value / Math.sqrt(maxVal - linearRangeEnd) / 3 + 2 / 3;
                }
            },
            customPositionToValue: (percent: number, minVal: number, maxVal: number): number => {
                const linearRangeEnd = this.linearScaleEnd;

                if (linearRangeEnd === 0 || linearRangeEnd >= DEFAULT_MAX_RESERVE_IN_TIB) {
                    return percent * DEFAULT_MAX_RESERVE_IN_TIB;
                }

                if (percent <= 2 / 3) {
                    // linear scale
                    return (linearRangeEnd * percent * 3) / 2;
                } else {
                    // quadratic scale
                    return Math.pow((percent - 2 / 3) * (Math.sqrt(maxVal - linearRangeEnd) * 3), 2) + linearRangeEnd;
                }
            },
        };
    }
}
