import {
    ApplianceUpgradeStatus,
    ArrayStatus,
    CduAppliance,
    Check,
    CheckStatus,
    CloudPrecheck,
    EdgeConnectType,
    EdgeStatus,
    InstallCheck,
    InstallCheckOverrideDurability,
    InstallUpgradeWorkflowInstance,
    OnArrayPrecheckJob,
    Policy,
    PolicyVersionsModel,
    Precheck,
    Release,
    ReplicationType,
    SupportStateShortcut,
    UpgradeCheckStatus,
    UpgradePoliciesModel,
    UpgradeVersion,
    WorkflowInstanceStatus,
    ActiveClusterStatus,
    UpgradeWorkflowInstance,
    EdgeStatusLabel,
    PatchWorkflowInstance,
    WorkflowCreateRequestType,
    ApplianceSotwareName,
    ContractStatus,
    CloudPrecheckUiName,
    EdgeStatusMessageWithIcon,
} from './purity-upgrades.interface';
import moment from 'moment-timezone';
import { PartialDeep } from 'lodash';
import { UpgradePathRequiredInfo } from './services/releases.service';
import { Mitigation, MitigationStatus } from '@pure1/data';

const PURITY_UPGRADES_ANALYTICS_CATEGORY = 'Purity Upgrades';
const DEFAULT_RELEASE_NOTES_URL =
    'https://support.purestorage.com/FlashArray/FlashArray_Release/01_Purity_FA_Release_Notes';

export const UNSUPPORTED_REPLICATION_TYPES = [];

export const INSTALLATION_FINISHED_STATUSES: ApplianceUpgradeStatus[] = [
    ApplianceUpgradeStatus.INSTALLATION_COMPLETED_SUCCESS,
    ApplianceUpgradeStatus.INSTALLATION_COMPLETED_ABORT,
];

export const HEALTHCHECK_IN_PROGRESS_STATUSES: ApplianceUpgradeStatus[] = [
    ApplianceUpgradeStatus.HEALTH_CHECK_IN_PROGRESS,
    ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_IN_PROGRESS,
    ApplianceUpgradeStatus.ON_ARRAY_HEALTH_CHECK_IN_PROGRESS,
];

export const HEALTHCHECK_SUCCESS_STATUSES: ApplianceUpgradeStatus[] = [
    ApplianceUpgradeStatus.HEALTH_CHECK_COMPLETED_SUCCESS,
    ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_COMPLETED_SUCCESS,
    ApplianceUpgradeStatus.ON_ARRAY_HEALTH_CHECK_COMPLETED_SUCCESS,
    ApplianceUpgradeStatus.ON_ARRAY_HEALTH_CHECK_COMPLETED_WARNING,
    ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_COMPLETED_WARNING,
];

export const HEALTHCHECK_FAIL_STATUSES: ApplianceUpgradeStatus[] = [
    ApplianceUpgradeStatus.HEALTH_CHECK_COMPLETED_FAIL,
    ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_COMPLETED_FAIL,
    ApplianceUpgradeStatus.ON_ARRAY_HEALTH_CHECK_COMPLETED_FAIL,
];

export const CLOUD_HEALTHCHECK_STATUSES: ApplianceUpgradeStatus[] = [
    ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_IN_PROGRESS,
    ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_COMPLETED_SUCCESS,
    ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_COMPLETED_FAIL,
];

export const HEALTCHECK_STATUSES: ApplianceUpgradeStatus[] = [
    ...HEALTHCHECK_IN_PROGRESS_STATUSES,
    ...HEALTHCHECK_SUCCESS_STATUSES,
    ...HEALTHCHECK_FAIL_STATUSES,
];

export const INSTALLATION_COMPLETED_STATUSES: ApplianceUpgradeStatus[] = [
    ApplianceUpgradeStatus.INSTALLATION_COMPLETED_SUCCESS,
    ApplianceUpgradeStatus.INSTALLATION_COMPLETED_ABORT,
];

export const ACTIONABLE_STATUSES: ApplianceUpgradeStatus[] = [
    ApplianceUpgradeStatus.DOWNLOAD_READY,
    ApplianceUpgradeStatus.DOWNLOAD_COMPLETED_SUCCESS,
    ApplianceUpgradeStatus.DOWNLOAD_COMPLETED_FAIL,
    ApplianceUpgradeStatus.DOWNLOAD_COMPLETED_ABORT,
    ApplianceUpgradeStatus.INSTALLATION_COMPLETED_ABORT,
    ApplianceUpgradeStatus.INSTALLATION_COMPLETED_SUCCESS,
    ApplianceUpgradeStatus.INSTALLATION_FIRST_CONTROLLER_DONE,
    ApplianceUpgradeStatus.INSTALLATION_PRECHECK_WARNING,
    ApplianceUpgradeStatus.INSTALLATION_PAUSED,
    ApplianceUpgradeStatus.PATCH_CREATED,
    ...HEALTHCHECK_SUCCESS_STATUSES,
    ...HEALTHCHECK_FAIL_STATUSES,
];

export enum ACTION_REQUIRED_OPTIONS {
    RUN_HEALTH_CHECK = 'Run Health Check',
    DOWNLOAD_BUNDLE = 'Download Bundle',
    VIEW_RESULTS = 'View Results',
    REVIEW_TO_CONTINUE = 'Review to Continue',
    DOWNLOAD_AGAIN = 'Download Again',
    INSTALL_PURITY = 'Install Purity',
    ACTION_REQUIRED = 'Action Required',
    VIEW_DETAILS = 'View Details',
}

export interface ArrayCurrentStatus {
    icon?: string;
    status?: string;
    additionalInfo?: string;
    errorMessage?: string;
    actionRequired?: ACTION_REQUIRED_OPTIONS;
    timestamp: moment.Moment | null;
    applianceId: string;
    includeTargetVersion?: boolean;
    shouldHideToast?: boolean;
}

export const upgradePolicies: UpgradePoliciesModel[] = [
    { code: Policy.BALANCED, displayName: 'Balanced' },
    { code: Policy.ESSENTIAL, displayName: 'Essential' },
    { code: Policy.PROACTIVE, displayName: 'Proactive' },
];

export const ONARRAY_PRECHECK_DISABLED_VERSIONS = ['6.4.9', '6.4.10']; // https://jira.purestorage.com/browse/CLOUD-108501

export const SAFEMODE_AUTO_ON_BOUNDARY_VERSION = '6.4.10';

/**
 * Compare that can be used for sorting releases descending
 *
 * Returns positive if rel1 < rel2
 * Returns negative if rel1 > rel2
 * Returns 0 if release version and time is the same.
 *
 * @param rel1
 * @param rel2
 */
export function compareReleases(rel1: Release, rel2: Release): number {
    if (rel1.versionMajor !== rel2.versionMajor) {
        return rel2.versionMajor - rel1.versionMajor;
    }

    if (rel1.versionMinor !== rel2.versionMinor) {
        return rel2.versionMinor - rel1.versionMinor;
    }

    if (rel1.versionPatch !== rel2.versionPatch) {
        return rel2.versionPatch - rel1.versionPatch;
    }
    return rel2.releaseTime.diff(rel1.releaseTime);
}

/**
 * Returns either error message if edge configuration doesn't support CDU upgrade
 * or null if everything is in order
 * @param arrayStatus
 */
export function getArrayEdgeErrors(arrayStatus: ArrayStatus): string {
    if (arrayStatus.edgeConnectType === EdgeConnectType.FORBIDDEN) {
        return 'Access to array is forbidden';
    }

    if (!noUnsupportedReplicationsExist(arrayStatus)) {
        return `Cannot use CDU to upgrade this array because it has ${getUnsupportedReplicationNamesPresentOnArray(arrayStatus)} replication enabled.`;
    }

    return null;
}

export function splitToChunks<T>(array: T[], chunkSize: number): T[][] {
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        const end = i + chunkSize > array.length ? array.length : i + chunkSize;
        result.push(array.slice(i, end));
    }

    return result;
}

export function translateReplicationTypeToShortcut(replicationType: ReplicationType): string {
    switch (replicationType) {
        case ReplicationType.ACTIVE_DR:
            return 'ADR';
        case ReplicationType.ACTIVE_CLUSTER:
            return 'AC';
        case ReplicationType.ASYNC:
            return 'P';
        default:
            return '';
    }
}

export function getUnsupportedReplicationNamesPresentOnArray(arrayStatus: ArrayStatus): string {
    const unsupportedReplicationTypes = getDistinctReplicationTypes(arrayStatus)
        .filter(type => UNSUPPORTED_REPLICATION_TYPES.includes(type))
        .map(translateReplicationType);

    return unsupportedReplicationTypes.join(' and ');
}

export function getDistinctReplicationTypes(arrayStatus: ArrayStatus): ReplicationType[] {
    const distinctReplicationTypes = new Set<ReplicationType>();
    arrayStatus?.replications?.forEach(replication => distinctReplicationTypes.add(replication.type));
    return [...distinctReplicationTypes];
}

export function getUnsupportedReplicationNamesPresentOnAppliance(appliance: CduAppliance): string {
    const unsupportedReplicationTypes = getDistinctReplicationTypesOnAppliance(appliance)
        .filter(type => UNSUPPORTED_REPLICATION_TYPES.includes(type))
        .map(translateReplicationType);

    return unsupportedReplicationTypes.join(' and ');
}

export function getDistinctReplicationTypesOnAppliance(appliance: CduAppliance): ReplicationType[] {
    const distinctReplicationTypes = new Set<ReplicationType>();
    appliance?.replications?.forEach(replication => distinctReplicationTypes.add(replication.type));
    return [...distinctReplicationTypes];
}

export function translateReplicationType(replicationType: ReplicationType): string {
    switch (replicationType) {
        case ReplicationType.ACTIVE_DR:
            return 'ActiveDR';
        case ReplicationType.ACTIVE_CLUSTER:
            return 'ActiveCluster';
        default:
            return '';
    }
}

export function noUnsupportedReplicationsExist(arrayStatus: ArrayStatus): boolean {
    return !(
        arrayStatus?.replications?.find(replication => UNSUPPORTED_REPLICATION_TYPES.includes(replication.type)) ??
        false
    );
}

export function noUnsupportedReplicationsExistOnAppliance(appliance: CduAppliance): boolean {
    return !(
        appliance?.replications?.find(replication => UNSUPPORTED_REPLICATION_TYPES.includes(replication.type)) ?? false
    );
}
/**
 * Prechecks have no signalisation that they were not even executed due to an error
 * Because of that valid completed precheck is a precheck that is FINALIZED
 * and has at least one check
 * @param precheck
 */
export function isValidCompletedPrecheck(precheck: Precheck): boolean {
    if (precheck.status !== UpgradeCheckStatus.FINALIZED && precheck.status !== UpgradeCheckStatus.COMPLETE) {
        return false;
    }

    // Precheck jobs that actually run will have at least one check
    // Precheck jobs that failed due to an error will not have any
    if (precheck.checks.length === 0) {
        return false;
    }

    return true;
}

export function getEdgeServiceStatus(
    appliance: CduAppliance,
    release: Release,
    secondaryAppliance: CduAppliance | null = null,
): EdgeStatusMessageWithIcon {
    if (!release?.emsSupported) {
        return { message: 'Not eligible', icon: 'ban-circle' };
    }

    if (secondaryAppliance) {
        if (
            !isSSUEligibleActiveCluster(appliance, secondaryAppliance) &&
            appliance.lastWorkflowStatus !== WorkflowInstanceStatus.ACTIVE &&
            secondaryAppliance.lastWorkflowStatus !== WorkflowInstanceStatus.ACTIVE
        ) {
            return { message: 'Unsupported ActiveCluster configuration', icon: 'ban-circle' };
        }
    }

    if (appliance?.edgeStatus) {
        if (appliance.edgeStatus === EdgeStatus.CONNECTED) {
            if (!noUnsupportedReplicationsExistOnAppliance(appliance) && !secondaryAppliance) {
                return {
                    message: 'Not eligible - ' + getUnsupportedReplicationNamesPresentOnAppliance(appliance),
                    icon: 'ban-circle',
                };
            }
            if (appliance.activeClusterStatus === ActiveClusterStatus.PAIRED_ARRAY_NOT_VISIBLE) {
                return { message: 'Unsupported ActiveCluster configuration', icon: 'ban-circle' };
            }
            return {
                message: EdgeStatusLabel[appliance.edgeStatus],
                icon: getEdgeServiceStatusIcon(appliance.edgeStatus),
            };
        } else if (EdgeStatusLabel[appliance.edgeStatus]) {
            return {
                message: EdgeStatusLabel[appliance.edgeStatus],
                icon: getEdgeServiceStatusIcon(appliance.edgeStatus),
            };
        } else {
            console.warn('Unrecognized EdgeStatus', appliance.edgeStatus);
        }
    }

    return { message: 'Not Configured', icon: 'ban-circle' };
}

function getEdgeServiceStatusIcon(edgeStatus: EdgeStatus): string {
    if (edgeStatus === EdgeStatus.CONNECTED) {
        return 'edge-service-check';
    }
    if ([EdgeStatus.AGENT_NEEDS_UPDATE, EdgeStatus.AGENT_NOT_INSTALLED].includes(edgeStatus)) {
        return 'edge-service-status-minus';
    }
    return 'ban-circle';
}

export function getEdgeServiceStatusMessage(
    appliance: CduAppliance,
    release: Release,
    secondaryAppliance: CduAppliance | null = null,
): string {
    return getEdgeServiceStatus(appliance, release, secondaryAppliance).message;
}

export function isCheckOverriden(check: Check, instance: InstallUpgradeWorkflowInstance): boolean {
    if (check.status === CheckStatus.OVERRIDDEN) {
        return true;
    }

    if (!check.overridable || check.status === CheckStatus.PASSED) {
        return false;
    }

    // Only InstallChecks have check list id
    const { checkListId } = check as InstallCheck;
    if (!checkListId || !instance) {
        return false;
    }

    return instance.checkOverrides.some(override => {
        // Order checklists descending (without array mutation)
        const checkLists = instance.checkLists.slice().sort((a, b) => b.createTime.diff(a.createTime));
        const checkList = checkLists.find(x => x.id === checkListId);

        if (!checkList) {
            throw new Error(
                `Check (Id: ${check.id}) is not child of any checklist in WorkflowInstance (Id: ${instance.id}`,
            );
        }

        if (override.name !== check.name) {
            return false;
        }

        const checkListIndex = checkLists.indexOf(checkList);

        // If this is the last checklist then the override can belong to this check
        // Also if the checklist after this one is before this override then this check could be overriden
        if (checkListIndex !== 0 && override.createTime.isAfter(checkLists[checkListIndex - 1].createTime)) {
            return false;
        }

        if (override.durability === InstallCheckOverrideDurability.CHECK_STEP) {
            return checkList.checkStep === override.checkStep;
        }

        if (override.durability === InstallCheckOverrideDurability.STEP) {
            return checkList.checkStep === override.checkStep && override.hopVersion === checkList.hopVersion;
        }

        if (override.durability === InstallCheckOverrideDurability.HOP) {
            return override.hopVersion === checkList.hopVersion;
        }

        if (override.durability === InstallCheckOverrideDurability.INSTALLATION) {
            return true;
        }

        return false;
    });
}

export function getApplianceTargetVersion(appliance: CduAppliance): string {
    if (hadValidContractStatus(appliance)) {
        if (HEALTCHECK_STATUSES.includes(appliance?.applianceUpgradeStatus)) {
            return appliance.precheckTargetVersion;
        }

        return appliance?.targetVersion;
    }
    return null;
}

export function getAngularticsEventName(value: string): string {
    return `${PURITY_UPGRADES_ANALYTICS_CATEGORY} - ${value}`;
}

export function isApplianceUpToDate(
    upgradeVersions: UpgradeVersion[],
    releasesMap: { [key: string]: Release },
): boolean {
    return upgradeVersions.every(x => !releasesMap[x.number]);
}

export function getStatus(
    appliance: CduAppliance,
    cloudCheckAborted = false,
    secondaryAppliance: CduAppliance = null,
    atomSecurityPatch: Mitigation = null,
): ArrayCurrentStatus {
    if (!hadValidContractStatus(appliance)) {
        return {
            status: 'Support Contract Expired',
            timestamp: appliance.statusTime,
            applianceId: appliance.applianceId,
        };
    }

    // ATOM security patch statuses
    if (atomSecurityPatch) {
        // only overwrite the status if the patch is available or in progress
        if (atomSecurityPatch.optimizationStatus === MitigationStatus.AVAILABLE) {
            return {
                status: 'Security patch available',
                actionRequired: ACTION_REQUIRED_OPTIONS.ACTION_REQUIRED,
                timestamp: appliance.statusTime,
                applianceId: appliance.applianceId,
            };
        }
        if (atomSecurityPatch.optimizationStatus === MitigationStatus.PENDING) {
            return {
                status: 'Security patch opted-in',
                timestamp: appliance.statusTime,
                applianceId: appliance.applianceId,
            };
        }
    }

    if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.SSU_READY) {
        return {
            icon: 'info',
            status: `Ready for self-service upgrade`,
            actionRequired: ACTION_REQUIRED_OPTIONS.RUN_HEALTH_CHECK,
            timestamp: appliance.statusTime,
            applianceId: appliance.applianceId,
            includeTargetVersion: false,
            shouldHideToast: true,
        };
    } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.UP_TO_DATE) {
        return {
            icon: 'check',
            status: `Up-to-date`,
            timestamp: appliance.statusTime,
            applianceId: appliance.applianceId,
            includeTargetVersion: false,
            shouldHideToast: true,
        };
    }

    if (
        appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_COMPLETED_WARNING ||
        appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.ON_ARRAY_HEALTH_CHECK_COMPLETED_WARNING
    ) {
        return {
            icon: 'exclamation',
            status: `Health check warning`,
            timestamp: appliance.statusTime,
            applianceId: appliance.applianceId,
            actionRequired: ACTION_REQUIRED_OPTIONS.REVIEW_TO_CONTINUE,
        };
    }

    if (
        appliance.lastWorkflowStatus === WorkflowInstanceStatus.FINISHED &&
        INSTALLATION_COMPLETED_STATUSES.includes(appliance.applianceUpgradeStatus) &&
        appliance.statusTime?.isAfter(moment().add(-3, 'days'))
    ) {
        if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_COMPLETED_SUCCESS) {
            return {
                icon: 'check',
                status: `Upgrade completed`,
                timestamp: appliance.statusTime,
                applianceId: appliance.applianceId,
                actionRequired: ACTION_REQUIRED_OPTIONS.VIEW_RESULTS,
            };
        } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_COMPLETED_ABORT) {
            return {
                icon: 'error',
                status: `Installation canceled`,
                timestamp: appliance.statusTime,
                applianceId: appliance.applianceId,
                actionRequired: ACTION_REQUIRED_OPTIONS.VIEW_RESULTS,
            };
        } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_COMPLETED_ABORT_DUE_ERROR) {
            return {
                icon: 'exclamation',
                status: `Installation failed`,
                timestamp: appliance.statusTime,
                applianceId: appliance.applianceId,
            };
        }
    }

    if (
        appliance.lastWorkflowName !== 'PDU' &&
        appliance.lastWorkflowId !== null &&
        appliance.lastWorkflowStatus === WorkflowInstanceStatus.ACTIVE
    ) {
        let actionRequired: ACTION_REQUIRED_OPTIONS;
        let status: string;
        let icon: string;
        let errorMessage: string;
        let additionalInfo: string;

        if (ACTIONABLE_STATUSES.some(status => status === appliance.applianceUpgradeStatus)) {
            if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.DOWNLOAD_COMPLETED_SUCCESS) {
                icon = 'check';
                status = 'Download completed';
                actionRequired = ACTION_REQUIRED_OPTIONS.INSTALL_PURITY;
                additionalInfo =
                    "Please initiate the installation promptly to reduce the chance that you'll need to redownload the newer version of the bundle";
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.DOWNLOAD_COMPLETED_FAIL) {
                icon = 'exclamation';
                errorMessage = 'Download error';
                actionRequired = ACTION_REQUIRED_OPTIONS.DOWNLOAD_AGAIN;
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.DOWNLOAD_COMPLETED_ABORT) {
                icon = 'error';
                status = 'Download canceled';
                actionRequired = ACTION_REQUIRED_OPTIONS.DOWNLOAD_BUNDLE;
            } else if (HEALTHCHECK_SUCCESS_STATUSES.includes(appliance.applianceUpgradeStatus)) {
                icon = 'check';
                status = 'Health check completed';
                actionRequired = ACTION_REQUIRED_OPTIONS.DOWNLOAD_BUNDLE;
            } else if (HEALTHCHECK_FAIL_STATUSES.includes(appliance.applianceUpgradeStatus)) {
                icon = 'exclamation';
                errorMessage = 'Health check completed with errors';
                actionRequired = ACTION_REQUIRED_OPTIONS.REVIEW_TO_CONTINUE;
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_COMPLETED_SUCCESS) {
                icon = 'check';
                status = 'Upgrade completed';
                actionRequired = ACTION_REQUIRED_OPTIONS.VIEW_RESULTS;
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.PATCH_CREATED) {
                status = 'Authentication not completed';
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_PAUSED) {
                status = 'Installation paused';
                icon = 'paused';
                actionRequired = ACTION_REQUIRED_OPTIONS.REVIEW_TO_CONTINUE;
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_FIRST_CONTROLLER_DONE) {
                status = 'Installation on first controller completed';
                icon = 'paused';
                actionRequired = ACTION_REQUIRED_OPTIONS.REVIEW_TO_CONTINUE;
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_PRECHECK_WARNING) {
                status = 'Installation pre-checks warning';
                icon = 'paused';
                actionRequired = ACTION_REQUIRED_OPTIONS.REVIEW_TO_CONTINUE;
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.DOWNLOAD_READY) {
                icon = 'check';
                status = 'Health check completed';
                actionRequired = ACTION_REQUIRED_OPTIONS.DOWNLOAD_BUNDLE;
            } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_COMPLETED_ABORT) {
                status = 'Installation canceled';
                icon = 'error';
                actionRequired = ACTION_REQUIRED_OPTIONS.VIEW_RESULTS;
            } else {
                status = appliance.applianceUpgradeStatus
                    .split('_')
                    .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ');
            }
        } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.DOWNLOAD_IN_PROGRESS) {
            icon = 'spinner';
            status = 'Download in progress';
            actionRequired = ACTION_REQUIRED_OPTIONS.VIEW_DETAILS;
        } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_COMPLETED_ABORT_DUE_ERROR) {
            status = 'Installation failed';
            icon = 'exclamation';
        } else if (appliance.applianceUpgradeStatus?.includes('ABORT')) {
            status = 'Installation is being canceled';
            icon = 'spinner';
            actionRequired = ACTION_REQUIRED_OPTIONS.VIEW_DETAILS;
        } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.PATCH_IN_PROGRESS) {
            status = 'Patch installation in progress';
            icon = 'spinner';
            actionRequired = ACTION_REQUIRED_OPTIONS.VIEW_DETAILS;
        } else if (appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.INSTALLATION_ABORTING) {
            status = 'Installation is being canceled';
            icon = 'spinner';
        } else {
            status = 'Installation in progress';
            icon = 'spinner';
            actionRequired = ACTION_REQUIRED_OPTIONS.VIEW_DETAILS;
        }

        // Only show action required for ActiveCluster array if both have the same status
        if (secondaryAppliance && actionRequired) {
            const secondaryActionRequired = getStatus(secondaryAppliance)?.actionRequired;
            actionRequired = secondaryActionRequired === actionRequired ? actionRequired : null;
        }

        return {
            icon,
            status,
            additionalInfo,
            actionRequired,
            errorMessage,
            timestamp: appliance.statusTime,
            applianceId: appliance.applianceId,
        };
    }

    if (HEALTCHECK_STATUSES.includes(appliance.applianceUpgradeStatus)) {
        const running = HEALTHCHECK_IN_PROGRESS_STATUSES.includes(appliance.applianceUpgradeStatus);

        let icon: string;
        let status: string;
        let errorMessage: string;
        let actionRequired: ACTION_REQUIRED_OPTIONS;

        if (running) {
            icon = 'spinner';
            status = 'Health check in progress';
            actionRequired = ACTION_REQUIRED_OPTIONS.VIEW_DETAILS;
        } else if (!appliance.lastPrecheckSuccessful) {
            if (
                appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.CLOUD_HEALTH_CHECK_COMPLETED_FAIL &&
                appliance.edgeStatus === EdgeStatus.CONNECTED &&
                !ONARRAY_PRECHECK_DISABLED_VERSIONS.includes(appliance.currentVersion) &&
                appliance.activeClusterStatus === ActiveClusterStatus.NONE
            ) {
                if (appliance.lastPrecheckWorkflowStatus === WorkflowInstanceStatus.FINISHED) {
                    status = 'Health check canceled';
                    icon = 'error';
                    actionRequired = ACTION_REQUIRED_OPTIONS.RUN_HEALTH_CHECK;
                } else {
                    icon = 'spinner';
                    status = 'Health check in progress';
                    actionRequired = ACTION_REQUIRED_OPTIONS.REVIEW_TO_CONTINUE;
                }
            } else {
                icon = 'exclamation';
                errorMessage = 'Health check error';
                actionRequired = ACTION_REQUIRED_OPTIONS.VIEW_RESULTS;
            }
        } else if (appliance.lastPrecheckSuccessful) {
            icon = 'check';
            status = 'Health check completed';
            actionRequired = ACTION_REQUIRED_OPTIONS.DOWNLOAD_BUNDLE;
        } else {
            const failedChecksCount = appliance.lastPrecheckFailureCount;
            icon = 'exclamation';
            errorMessage = ` - ${failedChecksCount} ${failedChecksCount === 1 ? 'issue' : 'issues'}`;
            status = 'Health check completed with errors';
            actionRequired = ACTION_REQUIRED_OPTIONS.REVIEW_TO_CONTINUE;
        }

        if (secondaryAppliance && actionRequired) {
            const secondaryActionRequired = getStatus(secondaryAppliance)?.actionRequired;
            actionRequired = secondaryActionRequired === actionRequired ? actionRequired : null;
        }

        return {
            icon,
            status,
            errorMessage,
            timestamp: appliance.statusTime,
            actionRequired,
            applianceId: appliance.applianceId,
        };
    }

    return null;
}

export function isHealthCheckRunning(precheck: Precheck): boolean {
    return (
        [UpgradeCheckStatus.QUEUED, UpgradeCheckStatus.REGISTERED, UpgradeCheckStatus.REQUESTED].includes(
            precheck.status,
        ) && !(moment().diff(moment(precheck.lastUpdateTime), 'hour') > 1)
    );
}

export function getReleaseText(release: Release): string {
    if (!release) {
        return ' ';
    }
    return `${release.versionFull} (${release.lifecycleState}/${SupportStateShortcut[release.supportState]})`;
}

export function showSafeModeOptOut(
    safeModeBoundaryVersion: Release,
    currentVersion: Release,
    targetVersion: Release,
): boolean {
    // show the safemode auto-on modal if you're upgrading to a version that is bigger or equal than boundary version and from a version that is smaller than the boundary version
    if (!safeModeBoundaryVersion) {
        return false;
    }
    return Boolean(
        compareReleases(targetVersion, safeModeBoundaryVersion) < 1 &&
            compareReleases(currentVersion, safeModeBoundaryVersion) > 0,
    );
}

export function getAllApplianceVersions(
    versionPolicies: { [key: string]: PolicyVersionsModel },
    arrayId: string,
): string[] {
    if (versionPolicies[arrayId]) {
        return [
            ...versionPolicies[arrayId].inPolicyVersions.map(x => x.version),
            ...versionPolicies[arrayId].outOfPolicyVersions.map(x => x.version),
        ];
    }
    return [];
}

export function getFormattedReleasesText(release: Release): string {
    if (!release) {
        return 'Release not available';
    }
    return `${release?.versionFull} (${release?.lifecycleState}/${SupportStateShortcut[release?.supportState]})`;
}

export function getReleaseNotesUrl(release: Release): string {
    if (!release) {
        return DEFAULT_RELEASE_NOTES_URL;
    }

    if (!release.releaseNotesUrl) {
        return DEFAULT_RELEASE_NOTES_URL;
    }

    return release.releaseNotesUrl;
}

export function getLatestRelease(versions: string[], releasesMap: { [key: string]: Release }): Release {
    if (!versions || !releasesMap) {
        return null;
    }
    return (
        versions
            .map(x => releasesMap[x])
            .filter(x => x)
            .sort(compareReleases)[0] || null
    );
}

export function sortPrechecksByTime(
    first: CloudPrecheck | OnArrayPrecheckJob,
    second: CloudPrecheck | OnArrayPrecheckJob,
): number {
    let firstTime = first?.checkTime || first?.createTime;
    let secondTime = second?.checkTime || second?.createTime;
    if (!firstTime || !secondTime) {
        return 0;
    }
    if (typeof firstTime === 'string') {
        firstTime = moment(firstTime);
    }
    if (typeof secondTime === 'string') {
        secondTime = moment(secondTime);
    }
    return secondTime.diff(firstTime);
}

export function isSSUEligibleActiveCluster(appliance: CduAppliance, secondaryAppliance: CduAppliance): boolean {
    // checks if the appliances are in an active cluster pod that is SSU eligible
    if (appliance.currentVersion !== secondaryAppliance.currentVersion) {
        return false;
    }
    if (appliance.policy !== secondaryAppliance.policy) {
        return false;
    }
    if (
        appliance.activeClusterStatus !== ActiveClusterStatus.SYNCED ||
        secondaryAppliance.activeClusterStatus !== ActiveClusterStatus.SYNCED
    ) {
        return false;
    }
    if (!appliance.activeClusterTargetAplianceIds.includes(secondaryAppliance.applianceId)) {
        return false;
    }
    if (!secondaryAppliance.activeClusterTargetAplianceIds.includes(appliance.applianceId)) {
        return false;
    }
    return true;
}

export function isPDUScheduled(appliance: CduAppliance): boolean {
    return Boolean(appliance?.assistedUpgradeCaseId && appliance?.assistedUpgradeCaseNumber);
}

export function updateActiveClusterPairs(appliances: CduAppliance[]): { [key: string]: CduAppliance } {
    const activeClusterPairs = {};
    appliances.forEach(appliance => {
        if (!appliance.activeClusterTargetAplianceIds || appliance.activeClusterTargetAplianceIds.length !== 1) {
            return;
        }
        const activeClusterPairId = appliance.activeClusterTargetAplianceIds[0];
        const activeClusterPair = appliances.find(
            secondaryAppliance => secondaryAppliance.applianceId === activeClusterPairId,
        );
        if (activeClusterPair) {
            activeClusterPairs[appliance.applianceId] = activeClusterPair;
        }
    });
    return activeClusterPairs;
}

export function getApplianceAuditLogURL(workflowInstance: UpgradeWorkflowInstance | PatchWorkflowInstance): string {
    const filter = encodeURIComponent(
        JSON.stringify([
            {
                entity: 'arrays',
                key: 'array_name',
                value: workflowInstance.arrayHost,
            },
        ]),
    );

    const timeRange = encodeURIComponent(
        JSON.stringify([
            {
                key: 'startTime',
                value: workflowInstance.createTime.valueOf().toString(),
            },
            {
                key: 'endTime',
                value: workflowInstance.lastUpdateTime.valueOf().toString(),
            },
        ]),
    );

    return `/messages/auditlog/appliance?filter=${filter}&timeRange=${timeRange}`;
}

export function getUpgradeTimeEstimationText(
    workflowStateInstance: UpgradeWorkflowInstance,
    secondaryWorkflowStateInstance: UpgradeWorkflowInstance = null,
): string {
    const hops = workflowStateInstance.upgradePath.length - 1;
    let applianceLabel = 'appliance';
    const hopText = hops > 1 ? 'multi-hop' : 'single-hop';
    let estimatedHours = hops * 1;

    if (secondaryWorkflowStateInstance) {
        applianceLabel = 'ActiveCluster';
        estimatedHours = estimatedHours * 2;
    }

    return `The estimated time for your current ${hopText} ${applianceLabel} upgrade installation is ${estimatedHours} ${estimatedHours > 1 ? 'hours' : 'hour'}.`;
}

export function getArrayUniqueName(array: PartialDeep<CduAppliance>, hasDuplicateApplianceNames: boolean): string {
    return array.hostname + (hasDuplicateApplianceNames ? '.' + array.domain : '');
}

export function isApplianceRunningWorkflow(appliance: CduAppliance): boolean {
    return appliance.lastWorkflowStatus === WorkflowInstanceStatus.ACTIVE;
}

export function isApplianceRunningPatchWorkflow(appliance: CduAppliance): boolean {
    return (
        appliance.lastWorkflowStatus === WorkflowInstanceStatus.ACTIVE &&
        appliance.lastWorkflowName === WorkflowCreateRequestType.PATCH
    );
}

export function isApplianceRunningHealtCheck(appliance: CduAppliance): boolean {
    return HEALTHCHECK_IN_PROGRESS_STATUSES.includes(appliance.applianceUpgradeStatus);
}

export function hadApplianceUpgradeVersions(
    appliance: CduAppliance,
    versionPolicies: { [key: string]: PolicyVersionsModel },
    releases: Release[],
): boolean {
    if (versionPolicies[appliance.applianceId]) {
        const allPolicyVersions = getAllApplianceVersions(versionPolicies, appliance.applianceId);
        return (
            allPolicyVersions.length !== 0 &&
            allPolicyVersions.filter(v => releases.find(r => r.versionFull === v)).length !== 0
        );
    }
    return false;
}

export function hadApplianceDownloadBundle(appliance: CduAppliance): boolean {
    return appliance.applianceUpgradeStatus === ApplianceUpgradeStatus.DOWNLOAD_COMPLETED_SUCCESS;
}

export function hadValidContractStatus(appliance: CduAppliance): boolean {
    return appliance.contractStatus !== ContractStatus.EXPIRED;
}

export function isApplianceFlashArray(appliance: CduAppliance): boolean {
    return appliance.softwareName === ApplianceSotwareName.PurityFA;
}

export function isApplianceFlashBlade(appliance: CduAppliance): boolean {
    return appliance.softwareName === ApplianceSotwareName.PurityFB;
}

export function getUpgradePathRequiredInfoFromAppliance(appliance: CduAppliance): UpgradePathRequiredInfo {
    return {
        applianceId: appliance.applianceId,
        currentVersion: appliance.currentVersion,
        targetVersion: appliance.targetVersion,
        controllerModel: appliance.hardwareModel,
        softwareName: appliance.softwareName,
    };
}

export function applianceHadOpenScheduleUpgrade(appliance: CduAppliance): boolean {
    return appliance.assistedUpgradeCaseId !== null;
}

export function convertWorkflowInstanceToPrecheck(instance: UpgradeWorkflowInstance, applianceId: string): Precheck {
    return {
        succeeded: false,
        arrayId: applianceId,
        id: instance.cloudPrecheckId,
        lastUpdatedBy: instance.createdBy,
        lastUpdateTime: instance.lastUpdateTime,
        createdBy: instance.createdBy,
        createTime: instance.createTime,
        checkTime: null,
        groupId: null,
        status: UpgradeCheckStatus.REQUESTED,
        startVersion: instance.startVersion,
        targetVersion: instance.targetVersion,
        uiName: CloudPrecheckUiName.SSU,
        upgradePath: instance.upgradePath,
        checks: [],
    };
}

export function getApplianceById(appliances: CduAppliance[], applianceId: string): CduAppliance {
    return appliances.find(a => a.applianceId === applianceId);
}

export function isApplianceAfter(
    appliances: CduAppliance[],
    appliance1: CduAppliance,
    appliance2: CduAppliance,
): boolean {
    const applianceIds = appliances.map(a => a.applianceId);
    return applianceIds.indexOf(appliance1?.applianceId) > applianceIds.indexOf(appliance2?.applianceId);
}

export function getReplicationAppliance(
    appliances: CduAppliance[],
    activeClusterPairs: { [key: string]: CduAppliance },
    applianceId: string,
): CduAppliance {
    const appliance = getApplianceById(appliances, applianceId);
    if (!appliance) {
        return null;
    }

    return activeClusterPairs[appliance.applianceId] || null;
}

export function isReplicationAppliancePrimary(
    appliances: CduAppliance[],
    activeClusterPairs: { [key: string]: CduAppliance },
    applianceId: string,
): boolean {
    const appliance = getApplianceById(appliances, applianceId);
    if (!appliance) {
        return null;
    }

    return (
        isApplianceReplicated(appliances, activeClusterPairs, applianceId) &&
        isApplianceAfter(
            appliances,
            getReplicationAppliance(appliances, activeClusterPairs, appliance.applianceId),
            appliance,
        )
    );
}

export function isReplicationApplianceSecondary(
    appliances: CduAppliance[],
    activeClusterPairs: { [key: string]: CduAppliance },
    applianceId: string,
): boolean {
    const appliance = getApplianceById(appliances, applianceId);
    if (!appliance) {
        return null;
    }

    return (
        isApplianceReplicated(appliances, activeClusterPairs, applianceId) &&
        isApplianceAfter(
            appliances,
            appliance,
            getReplicationAppliance(appliances, activeClusterPairs, appliance.applianceId),
        )
    );
}

export function isApplianceReplicated(
    appliances: CduAppliance[],
    activeClusterPairs: { [key: string]: CduAppliance },
    applianceId: string,
): boolean {
    const appliance = getApplianceById(appliances, applianceId);
    if (!appliance) {
        return false;
    }

    const replicatedAppliance = getReplicationAppliance(appliances, activeClusterPairs, appliance.applianceId);

    if (replicatedAppliance) {
        const replicatedCheck = getReplicationAppliance(
            appliances,
            activeClusterPairs,
            replicatedAppliance.applianceId,
        );
        if (replicatedCheck) {
            if (replicatedCheck.applianceId === applianceId) {
                return true;
            }
        }
    }

    return false;
}

export function isReplicatedApplianceNextToOther(
    appliances: CduAppliance[],
    activeClusterPairs: { [key: string]: CduAppliance },
    applianceId: string,
): boolean {
    const appliance = getApplianceById(appliances, applianceId);
    const replicatedAppliance = getReplicationAppliance(appliances, activeClusterPairs, applianceId);
    if (replicatedAppliance) {
        const applianceIds = appliances.map(a => a.applianceId);
        return (
            Math.abs(
                applianceIds.indexOf(appliance.applianceId) - applianceIds.indexOf(replicatedAppliance.applianceId),
            ) === 1
        );
    }

    return false;
}
