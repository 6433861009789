<div hive-root>
    <hive-page-base-layout id="hive-base-layout">
        <!-- Nav -->
        <hive-app-navigation [selection]="activeTabId" [expansion]="expansion">
            <a
                hive-app-navigation-item
                *ngFor="let tab of tabs"
                [id]="tab | navMenuId"
                [label]="tab.title"
                [data]="tab"
                [icon]="tab.icon"
                [routerLink]="tab.path"
                attr.data-pw="item-{{ tab.title.toLowerCase() }}"
            >
                <a
                    hive-app-navigation-item
                    *ngFor="let subTab of tab.subTabs"
                    [id]="subTab | navMenuId"
                    [label]="subTab.title"
                    [data]="subTab"
                    [routerLink]="subTab.path"
                    (click)="clickTab(subTab)"
                    attr.data-pw="sub-item-{{ tab.title.toLowerCase() }}-{{ subTab.title.toLowerCase() }}"
                >
                    <!-- SIDENAV BADGES -->

                    <!-- Fleet - Appliances: HW EOL -->
                    <hive-badge
                        *ngIf="tab.title === 'FLEET' && subTab.title === 'Appliances' && showHwEOLBadge"
                        [label]="hwEOLCount"
                        hiveTooltip="You have {{ hwEOLCount }} {{
                            hwEOLCount | pluralize: 'appliance'
                        }} approaching their end-of-life."
                        emphasis="highlight"
                    ></hive-badge>

                    <!-- Fleet - Subscriptions: Expired contracts -->
                    <hive-badge
                        *ngIf="
                            tab.title === 'FLEET' && subTab.title === 'Subscriptions' && expiredSupportContractCount > 0
                        "
                        class="sidebar-badge subtab-link-badge"
                        label="{{ expiredSupportContractCount }}"
                        emphasis="highlight"
                        hiveTooltip="You have {{ expiredSupportContractCount }} {{
                            expiredSupportContractCount | pluralize: 'appliance'
                        }} with {{ expiredSupportContractCount > 1 ? 'expired contracts' : 'an expired contract' }}"
                    ></hive-badge>

                    <!-- Fleet - Appliances: Disconnected arrays -->
                    <hive-badge
                        *ngIf="
                            tab.title === 'FLEET' &&
                            subTab.title === 'Appliances' &&
                            !showHwEOLBadge &&
                            showArrayCountBadge
                        "
                        label="{{ arrayCounts.connected }} of {{ arrayCounts.disconnected + arrayCounts.connected }}"
                        hiveTooltip="You have {{ arrayCounts.disconnected }} {{
                            arrayCounts.disconnected | pluralize: 'array'
                        }} that have not been updated recently."
                    ></hive-badge>

                    <!-- Messages - Requests: Pending Requests -->
                    <hive-badge
                        *ngIf="tab.title === 'MESSAGES' && subTab.title === 'Requests' && mpaRequestPendingCount > 0"
                        class="sidebar-badge subtab-link-badge"
                        label="{{ mpaRequestPendingCount }} pending"
                        emphasis="highlight"
                    ></hive-badge>

                    <!-- Support - Cases: Number of open cases -->
                    <hive-badge
                        *ngIf="
                            tab.title === 'SUPPORT' &&
                            subTab.title === 'Cases' &&
                            supportCaseOrchestrator.numOfOpenCases > 0
                        "
                        class="sidebar-badge subtab-link-badge"
                        label="{{ supportCaseOrchestrator.numOfOpenCases }}"
                    ></hive-badge>
                </a>
            </a>
        </hive-app-navigation>

        <!-- Header -->
        <hive-app-header
            largeScreenLogoSrc="pure1-full-logo.svg"
            smallScreenLogoSrc="pure1-icon-logo.svg"
            [notificationsAmount]="notificationsStoreService.getUnreadNotificationsCounter() | async"
            (notificationOpenChange)="handleNotificationOpenChange($event)"
            (aiBotOpenChange)="handleAIBotOpenChange($event)"
            (logoClick)="handleClickLogo()"
        >
            <!-- Proactice recommendations -->
            <div
                *ngIf="hasProRecFeature && proRecOpaCheck"
                ngProjectAs="hive-button[icon]"
                [ngStyle]="{ position: 'relative' }"
            >
                <hive-button
                    hiveMenuButton
                    [menuRef]="prorecDummyMenu"
                    [caret]="false"
                    icon="prorec_notif.svg"
                    emphasis="tertiary"
                    (click)="handleClickProRecIcon()"
                ></hive-button>

                <hive-badge
                    *ngIf="recommendationCount > 0"
                    [label]="recommendationCount"
                    emphasis="info"
                    [ngStyle]="{ position: 'absolute', top: '-4px', right: '-4px', 'pointer-events': 'none' }"
                ></hive-badge>
            </div>

            <hive-menu #prorecDummyMenu="hiveMenu">
                <!-- We only have a menu because Hive forces us to. We don't want to actually use it. -->
                <hive-menu-item label=""></hive-menu-item>
            </hive-menu>

            <!-- Notifications -->
            <ng-template hiveTemplate="notifications">
                <nc-notification-popup
                    *ngIf="isNotificationPopupOpen"
                    [showAlerts]="true"
                    (closePopup)="closeNotificationPopup()"
                ></nc-notification-popup>
            </ng-template>

            <!-- Help dropdown -->
            <hive-menu appHeaderHelpMenu>
                <hive-menu-item
                    *ngIf="pageHelpUrl != null"
                    label="Help with this page"
                    external
                    (click)="openMenuLinkNewTab(pageHelpUrl)"
                ></hive-menu-item>
                <hive-separator *ngIf="pageHelpUrl != null"></hive-separator>

                <hive-menu-item
                    *ngIf="'PURE1:read:support_knowledge' | isAllowed"
                    label="Knowledge Base"
                    external
                    (click)="openMenuLinkNewTab('/external/kb')"
                ></hive-menu-item>
                <hive-menu-item
                    *ngIf="'PURE1:read:support_knowledge' | isAllowed"
                    label="Documentation"
                    external
                    (click)="openMenuLinkNewTab('https://support.purestorage.com')"
                ></hive-menu-item>
                <hive-menu-item
                    *ngIf="'PURE1:read:support_community' | isAllowed"
                    label="Community"
                    external
                    (click)="openMenuLinkNewTab('/external/community')"
                ></hive-menu-item>
                <hive-menu-item
                    label="Legal Terms"
                    external
                    (click)="openMenuLinkNewTab('https://www.purestorage.com/legal/productenduserinfo.html')"
                ></hive-menu-item>
            </hive-menu>

            <!-- App switcher -->
            <ng-template hiveTemplate="appSwitch" *ngIf="(isFFIdentityCenterEnabled$ | async) === true">
                <platform-app-switcher-contents></platform-app-switcher-contents>
            </ng-template>

            <!-- User avatar (new) -->
            <user-profile-button
                *ngIf="(isFFIdentityCenterEnabled$ | async) === true"
                ngProjectAs="hive-avatar-menu"
            ></user-profile-button>

            <!-- User avatar (old) -->
            <hive-avatar-menu
                *ngIf="(isFFIdentityCenterEnabled$ | async) === false"
                [expanded]="false"
                [userName]="currentUser?.name"
                [userRole]="currentUser?.role"
                [userRoleSubtext]="currentUser?.role"
                (click)="legacyClickUserButton()"
            >
                <hive-avatar [letter]="currentUser?.name?.charAt(0)" size="small"></hive-avatar>
            </hive-avatar-menu>

            <!-- AI bot link -->
            <ng-template hiveTemplate="aiBot" *ngIf="(isFFCopilotEnabled$ | async) === true"></ng-template>
        </hive-app-header>

        <!-- Main content -->
        <div id="page-content-wrapper">
            <!-- Activity Panel -->
            <page-side-panel [stateName]="stateName" *ngIf="showSidePanel()"></page-side-panel>

            <!-- Main page content -->
            <div id="tab-content" class="container-fluid">
                <div class="page-title" *ngIf="pageTitle?.length > 0">{{ pageTitle }}</div>

                <router-outlet></router-outlet>
            </div>

            <!-- Toast notifications -->
            <pure-toast *ngIf="!fullScreen"></pure-toast>

            <!-- Various -->
            <export-overlay
                *ngIf="!fullScreen"
                (overlayVisibleChange)="onActiveExportsChanged($event)"
            ></export-overlay>
        </div>

        <!-- Feedback Button -->
        <!-- NOTE: The `pageTitle !== undefined` is for copilot -->
        <feedback-button
            *ngIf="!fullScreen && pageTitle !== 'Planning' && pageTitle !== 'Users' && pageTitle !== undefined"
            [ngClass]="pageTitle !== 'Map View' ? 'feedback-btn-general' : 'feedback-btn-mapview'"
        ></feedback-button>

        <!-- NPS feedback form -->
        <feedback-form *ngIf="!fullScreen"></feedback-form>
    </hive-page-base-layout>

    <!-- At top-level since it is triggered by the app header -->
    <copilot-onboarding-modal></copilot-onboarding-modal>
</div>

<!-- All of css stuff, need to find it a better home -->
<!-- Used by cssUrlStyleManager -->

<!-- SVG filter definitions. Used by cssUrlStyleManager. -->
<svg style="position: absolute; left: 0; top: 0; width: 0; height: 0; z-index: -100; pointer-events: none">
    <defs>
        <!--
            General
        -->
        <!-- Drop-shadow for svgs. Intended to roughly match (or look good with) text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5) -->
        <filter id="svg-hover-dropshadow" x="-40%" y="-40%" width="200%" height="200%">
            <!-- Offset the image 2px -->
            <feOffset in="SourceAlpha" result="saOffset" dx="2" dy="2"></feOffset>
            <!-- Blur it -->
            <feGaussianBlur in="saOffset" result="saOffsetBlur" stdDeviation="2"></feGaussianBlur>
            <!-- Adjust color channels, leaving only a black w/ alpha -->
            <feColorMatrix
                in="saOffsetBlur"
                result="saOffsetBlurColor"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
            ></feColorMatrix>
            <feMerge>
                <feMergeNode in="saOffsetBlurColor"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>

        <!--
            FA Health - Zepto
        -->
        <pattern id="pattern-stripe" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <rect width="4" height="8" fill="#828282"></rect>
        </pattern>

        <!--
            FB Health
        -->
        <linearGradient id="fb-health-healthy" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#8dc63f"></stop>
            <stop offset="50%" stop-color="#8dc63f"></stop>
            <stop offset="50%" stop-color="#81b53a"></stop>
            <stop offset="100%" stop-color="#8dc63f"></stop>
        </linearGradient>
        <linearGradient id="fb-health-failed" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#d84c41"></stop>
            <stop offset="50%" stop-color="#d84c41"></stop>
            <stop offset="50%" stop-color="#c14c3b"></stop>
            <stop offset="100%" stop-color="#d84c41"></stop>
        </linearGradient>
        <linearGradient id="fb-health-unhealthy" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#e8c721"></stop>
            <stop offset="50%" stop-color="#e8c721"></stop>
            <stop offset="50%" stop-color="#dcbd00"></stop>
            <stop offset="100%" stop-color="#e8c721"></stop>
        </linearGradient>
        <linearGradient id="fb-health-unused" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#8f8f8f"></stop>
            <stop offset="50%" stop-color="#8f8f8f"></stop>
            <stop offset="50%" stop-color="#848484"></stop>
            <stop offset="100%" stop-color="#8f8f8f"></stop>
        </linearGradient>
        <linearGradient id="fb-health-default" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#d9d9d9"></stop>
            <stop offset="50%" stop-color="#d9d9d9"></stop>
            <stop offset="50%" stop-color="#cacaca"></stop>
            <stop offset="100%" stop-color="#d9d9d9"></stop>
        </linearGradient>

        <!--
            VMware Metrics Topology
        -->
        <filter id="vm-topology-dropshadow" x="-20%" y="-20%" width="140%" height="140%">
            <!-- Offset the image 2px -->
            <feOffset in="SourceAlpha" result="saOffset" dx="2" dy="2"></feOffset>
            <!-- Blur it -->
            <feGaussianBlur in="saOffset" result="saOffsetBlur" stdDeviation="2"></feGaussianBlur>
            <!-- Adjust color channels, leaving only a black w/ alpha -->
            <feColorMatrix
                in="saOffsetBlur"
                result="saOffsetBlurColor"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            ></feColorMatrix>
            <feMerge>
                <feMergeNode in="saOffsetBlurColor"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>

        <filter id="vm-topology-aggregate-entity" x="-20%" y="-20%" width="140%" height="140%">
            <!-- Create two offset clones from the source graphic -->
            <feOffset in="SourceGraphic" result="box1" dx="3" dy="3" />
            <feOffset in="SourceGraphic" result="box2" dx="6" dy="6" />

            <!-- This part is same idea as fdropshadow, but putting the shadow on the bottom box clone -->
            <feGaussianBlur in="box2" result="box2Blur" stdDeviation="2"></feGaussianBlur>
            <feColorMatrix
                in="box2Blur"
                result="box2BlurColor"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            ></feColorMatrix>

            <!-- Merge the layers from bottom to top -->
            <feMerge>
                <feMergeNode in="box2BlurColor" />
                <feMergeNode in="box2" />
                <feMergeNode in="box1" />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
        </filter>

        <!-- Partial selection gradient fills -->
        <linearGradient id="vm-topology-partial-selected-vdisk" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#e5e1ff" />
        </linearGradient>
        <linearGradient id="vm-topology-partial-selected-vdisk-hover" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#a395ff" />
        </linearGradient>

        <linearGradient id="vm-topology-partial-selected-vm" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#c9f7f8" />
        </linearGradient>
        <linearGradient id="vm-topology-partial-selected-vm-hover" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#85edef" />
        </linearGradient>

        <linearGradient id="vm-topology-partial-selected-host" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#f7cffe" />
        </linearGradient>
        <linearGradient id="vm-topology-partial-selected-host-hover" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#eb84fc" />
        </linearGradient>

        <linearGradient id="vm-topology-partial-selected-datastore" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#d0ffda" />
        </linearGradient>
        <linearGradient id="vm-topology-partial-selected-datastore-hover" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#84ff9e" />
        </linearGradient>

        <linearGradient id="vm-topology-partial-selected-volume" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#d4dffe" />
        </linearGradient>
        <linearGradient id="vm-topology-partial-selected-volume-hover" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#89a7fc" />
        </linearGradient>

        <linearGradient id="vm-topology-partial-selected-array" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#f6e2d7" />
        </linearGradient>
        <linearGradient id="vm-topology-partial-selected-array-hover" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="5%" stop-color="white" />
            <stop offset="95%" stop-color="#e8b599" />
        </linearGradient>

        <!--
            Snapshot Catalog
        -->
        <filter id="circle-selected-dropshadow" x="-20%" y="-20%" width="200%" height="200%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
            <feOffset dx="2" dy="2" result="offsetblur" />
            <feFlood flood-color="#8d8d8d" flood-opacity="1" />
            <feComposite in2="offsetblur" operator="in" />
            <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
        </filter>

        <linearGradient id="snapshots-halfhalf" x1="50%" y1="0%" x2="50%" y2="100%" spreadMethod="pad">
            <!-- from the top to 49% of the circle will be color #b5a2dc -->
            <stop offset="49%" stop-color="#b5a2dc" />
            <stop offset="49%" stop-color="#b5a2dc" />
            <!-- a grayish line at the 50% of the circle -->
            <stop offset="50%" stop-color="#cbbee7" />
            <stop offset="50%" stop-color="#cbbee7" />
            <!-- the bottom half of the circle will be color #755baa -->
            <stop offset="51%" stop-color="#755baa" />
            <stop offset="51%" stop-color="#755baa" />
        </linearGradient>
    </defs>
</svg>
