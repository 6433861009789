import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Injector,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';
import { CurrentUser, FeatureFlagDxpService } from '@pure1/data';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { FeatureNames } from '../../model/FeatureNames';
import { UserModalComponent } from './user-modal.component';

@Injectable({ providedIn: 'root' })
export class UserModalService implements OnInit, OnDestroy {
    private modalRef: ComponentRef<UserModalComponent>;
    private backdrop: HTMLDivElement;
    private closeFn: () => void;

    isOpen$ = new Subject<boolean>();
    canSeeUserManagement: boolean;
    private isCopilotEnabled = false;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private applicationRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private authzServiceResolver: AuthorizationServiceResolver,
        private injector: Injector,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {
        this.featureFlagDxpService.getFeatureFlag(FeatureNames.COPILOT).subscribe(feature => {
            this.isCopilotEnabled = feature?.enabled === true;
        });
    }

    ngOnInit(): void {
        this.authzServiceResolver
            .getDefaultService()
            .pipe(
                switchMap(service => service.hasPermission('PURE1:view:users')),
                takeUntil(this.destroy$),
            )
            .subscribe(canSeeUserManagement => {
                this.canSeeUserManagement = canSeeUserManagement;
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    open(currentUser: CurrentUser): void {
        if (this.modalRef) {
            return;
        }

        this.backdrop = document.createElement('div') as HTMLDivElement;
        this.backdrop.id = 'user-modal-backdrop';
        document.body.appendChild(this.backdrop);
        this.closeFn = () => this.close();
        this.backdrop.addEventListener('click', this.closeFn);

        const modalFactory = this.componentFactoryResolver.resolveComponentFactory(UserModalComponent);
        this.modalRef = modalFactory.create(this.injector);
        this.applicationRef.attachView(this.modalRef.hostView);
        document.body.appendChild(this.modalRef.location.nativeElement);
        this.modalRef.instance.userModalStateService.registerModalClose(this.closeFn);

        this.modalRef.instance.currentUser = currentUser;

        this.isOpen$.next(true);
    }

    /**
     * Open the user modal and then open the account security tab.
     * Used by the link in step-up-banner.
     * This is a workaround to actually click on the page. Somewhat better alternatives might be:
     * 1) Pass userModalStateService into stateless-submenu-bar, or
     *      -> Effectively tying stateless-submenu-bar to only the user-modal use case.
     * 2) Pass userModalStateService into um-edit-user and regular-user-view, and use an @Input
     *    to maintain consistent state with stateless-submenu-bar.
     *      -> More setup work needed to use it in other future components.
     * Both options seem a lot of work to just support a non-critical banner feature, so I don't
     * feel too bad putting in this hack for now.
     */
    openStepUpSetup(currentUser: CurrentUser): void {
        this.open(currentUser);
        // Extra step for admins to open um-edit-user.
        // Regular users already see regular-user-view.
        if (this.canSeeUserManagement) {
            this.modalRef.instance.userModalStateService.editUser(currentUser);
        }
        this.applicationRef.tick();
        (document.querySelector('stateless-submenu-bar li:nth-of-type(2) .nav-link') as HTMLElement).click();
    }

    isOpen(): boolean {
        return this.modalRef != null;
    }

    close(): void {
        if (this.modalRef) {
            this.modalRef.destroy();
            this.modalRef = null;
        }
        if (this.backdrop) {
            this.backdrop.removeEventListener('click', this.closeFn);
            this.backdrop.remove();
            this.backdrop = null;
        }

        this.isOpen$.next(false);
    }
}
