import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SubscriptionSharedModule } from '../subscription/subscription.shared.module';
import { UIModule } from '../ui/ui.module';
import { AssetExportModalComponent } from './asset-export-modal/asset-export-modal.component';
import { AssetManagementTableComponent } from './asset-management-table/asset-management-table.component';
import { AssetManagementViewComponent } from './asset-management-view/asset-management-view.component';
import { GfbModule } from '../gfb/gfb.module';
import { ExportModule } from '../export/export.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssetManagementTableColumnsComponent } from './asset-management-view/asset-management-table-columns.component';
import { TagsSharedModule } from '../tags/tags.shared.module';
import { ApplianceGenealogyViewComponent } from './appliance-genealogy-view/appliance-genealogy-view.component';
import { AssetTabComponent } from './asset-tab/asset-tab.component';
import { ApplianceGenealogyTotalsComponent } from './appliance-genealogy-view/appliance-genealogy-totals/appliance-genealogy-totals.component';
import { AssetManagementSummaryCardComponent } from './asset-management-summary-card/asset-management-summary-card.component';
import { GenealogyChartComponent } from './genealogy-chart/genealogy-chart.component';
import { GenealogyDisplayOptionsComponent } from './genealogy-display-options/genealogy-display-options.component';
import { GenealogyEventDisplayNamePipe } from './genealogy-event-display-name.pipe';
import { ApplianceGenealogyExportModalComponent } from './appliance-genealogy-view/appliance-genealogy-export-modal/appliance-genealogy-export-modal.component';
import { SubscriptionGenealogyExportModalComponent } from './subscription-genealogy-view/subscription-genealogy-export-modal/subscription-genealogy-export-modal.component';
import { AssetManagementSummaryComponent } from './asset-management-summary/asset-management-summary.component';
import { SubscriptionGenealogyViewComponent } from './subscription-genealogy-view/subscription-genealogy-view.component';
import { ApplianceGenealogyChartComponent } from './appliance-genealogy-view/appliance-genealogy-chart/appliance-genealogy-chart.component';
import { GenealogyEventIconPipe } from './subscription-genealogy-view/genealogy-event-icon.pipe';
import { SubscriptionGenealogyChartComponent } from './subscription-genealogy-view/subscription-genealogy-chart/subscription-genealogy-chart.component';
import { GenealogySortDropdownComponent } from './genealogy-sort-dropdown/genealogy-sort-dropdown.component';
import { SubscriptionGenealogyTotalsComponent } from './subscription-genealogy-view/subscription-genealogy-totals/subscription-genealogy-totals.component';
import { AssetManagementUrlParamsPipe } from './asset-management-view/asset-management-url-params.pipe';
import { AssetManagementRouterStatePipe } from './asset-management-view/asset-management-router-state.pipe';
import { QuoteManagementViewComponent } from './quote-management-view/quote-management-view.component';
import { QuoteManagementSummaryTotalsComponent } from './quote-management-view/quote-management-summary-totals/quote-management-summary-totals.component';
import { QuoteManagementTableComponent } from './quote-management-view/quote-management-table/quote-management-table.component';
import {
    ButtonComponent,
    DetailsPanelComponent,
    DropdownModule,
    MenuModule,
    PageDraggableSplitterComponent,
    PaginationComponent,
    TableModule,
    TooltipModule,
    MultiselectModule,
    SvgDirective,
    ContentDialogComponent,
} from '@pure/hive';
import { QuoteManagementRangeFiltersComponent } from './quote-management-view/quote-management-range-filters/quote-management-range-filters.component';
import { QuoteManagementModalComponent } from './quote-management-view/quote-management-modal/quote-management-modal.component';
import { InvoiceManagementViewComponent } from './invoice-management-view/invoice-management-view.component';
import { InvoiceManagementSummaryTotalsComponent } from './invoice-management-view/invoice-management-summary-totals/invoice-management-summary-totals.component';
import { InvoiceManagementRangeFiltersComponent } from './invoice-management-view/invoice-management-range-filters/invoice-management-range-filters.component';
import { InvoiceManagementTableComponent } from './invoice-management-view/invoice-management-table/invoice-management-table.component';
import { InvoiceAddressPipe } from './invoice-management-view/invoice-management-table/invoice-address.pipe';
import { InvoiceDetailsComponent } from './invoice-management-view/invoice-management-table/invoice-details/invoice-details.component';
import { DisputeInvoiceModalComponent } from './invoice-management-view/invoice-management-table/dispute-invoice-modal/dispute-invoice-modal.component';
import { InvoiceExportModalComponent } from './invoice-management-view/invoice-export-modal/invoice-export-modal.component';
import { AssetManagementRoutingModule } from './asset-managment-routing.module';
import { ContactSectionModule } from '../support/contact-section/contact-section.module';
import { QuoteExportModalComponent } from './quote-management-view/quote-export-modal/quote-export-modal.component';
import { QuoteRequestChangesModalComponent } from './quote-management-view/quote-request-changes-modal/quote-request-changes-modal.component';
import { QuoteAcceptModalComponent } from './quote-management-view/quote-accept-modal/quote-accept-modal.component';
import { FilterQuoteActionsPipe } from './quote-management-view/quote-management-table/filter-quote-actions.pipe';
import { QuotePOUploadModalComponent } from './quote-management-view/quote-poupload-modal/quote-poupload-modal.component';
import { QuotePOUploadDialogComponent } from './quote-management-view/quote-poupload-modal/quote-poupload-dialog/quote-poupload-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        ContactSectionModule,
        SubscriptionSharedModule,
        TagsSharedModule,
        UIModule,
        GfbModule,
        ExportModule,
        NgSelectModule,
        NgbModule,
        FormsModule,
        TableModule,
        PaginationComponent,
        DropdownModule,
        MenuModule,
        ButtonComponent,
        TooltipModule,
        PageDraggableSplitterComponent,
        DetailsPanelComponent,
        ReactiveFormsModule,
        MultiselectModule,
        AssetManagementRoutingModule,
        RouterModule,
        SvgDirective,
        ContentDialogComponent,
    ],
    declarations: [
        ApplianceGenealogyViewComponent,
        AssetManagementTableComponent,
        AssetManagementTableColumnsComponent,
        AssetManagementViewComponent,
        AssetExportModalComponent,
        AssetTabComponent,
        ApplianceGenealogyTotalsComponent,
        ApplianceGenealogyExportModalComponent,
        SubscriptionGenealogyExportModalComponent,
        AssetManagementSummaryCardComponent,
        GenealogyChartComponent,
        GenealogyDisplayOptionsComponent,
        GenealogyEventDisplayNamePipe,
        AssetManagementSummaryComponent,
        SubscriptionGenealogyViewComponent,
        ApplianceGenealogyChartComponent,
        GenealogyEventIconPipe,
        SubscriptionGenealogyChartComponent,
        SubscriptionGenealogyTotalsComponent,
        GenealogySortDropdownComponent,
        AssetManagementUrlParamsPipe,
        AssetManagementRouterStatePipe,
        QuoteExportModalComponent,
        FilterQuoteActionsPipe,
        QuoteManagementViewComponent,
        QuoteManagementSummaryTotalsComponent,
        QuoteManagementTableComponent,
        QuoteManagementRangeFiltersComponent,
        QuoteManagementModalComponent,
        InvoiceManagementViewComponent,
        InvoiceManagementSummaryTotalsComponent,
        InvoiceManagementRangeFiltersComponent,
        InvoiceManagementTableComponent,
        InvoiceAddressPipe,
        InvoiceDetailsComponent,
        DisputeInvoiceModalComponent,
        InvoiceExportModalComponent,
        QuoteAcceptModalComponent,
        QuoteRequestChangesModalComponent,
        QuotePOUploadModalComponent,
        QuotePOUploadDialogComponent,
    ],
})
export class AssetManagementModule {}
