import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ContentDialogComponent } from '@pure/hive';
import { OpportunityQuote, OpportunityQuoteService, QuoteStatus } from '@pure1/data';

@Component({
    selector: 'quote-accept-modal',
    templateUrl: './quote-accept-modal.component.html',
})
export class QuoteAcceptModalComponent {
    @Input() readonly opportunityQuote: OpportunityQuote;
    @Input() readonly acceptAllowed: boolean;

    @Output() readonly acceptComplete = new EventEmitter<OpportunityQuote>();

    @ViewChild('acceptDialog', { static: true }) readonly acceptDialog: ContentDialogComponent;
    @ViewChild('errorDialog', { static: true }) readonly errorDialog: ContentDialogComponent;

    isLoading = false;

    constructor(private opportunityQuoteService: OpportunityQuoteService) {}

    show(): void {
        this.acceptDialog.show();
    }

    onAccept(): void {
        if (this.isLoading || !this.acceptAllowed) {
            return;
        }

        this.isLoading = true;
        this.opportunityQuoteService.accept(this.opportunityQuote).subscribe({
            next: () => {
                this.isLoading = false;
                this.acceptDialog.hide();
                this.acceptComplete.emit({
                    ...this.opportunityQuote,
                    status: QuoteStatus.ACCEPTED,
                });
            },
            error: () => {
                this.isLoading = false;
                this.acceptDialog.hide();
                this.errorDialog.show();
            },
        });
    }
}
