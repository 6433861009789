import { Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import {
    ArrayContractStatus,
    CachedCurrentUserService,
    CurrentUser,
    FeatureFlagDxpService,
    CopilotOnboardingService,
    IncidentService,
    SubscriptionType,
    UnifiedArray,
    UnifiedArrayService,
} from '@pure1/data';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { WINDOW } from '../../app/injection-tokens';
import { SupportCaseOrchestratorService } from '../../support/services/support-case-orchestrator.service';
import { TouService } from '../../tou/tou.service';
import { MpaRequestsBadgeService } from '../../messages/mpa-requests/services/mpa-requests-badge.service';
import { IMainTab, ITab, TabsService } from '../../services/tabs/tabs.service';
import { smartTimer } from '@pstg/smart-timer';
import { recursiveListItems } from '../../utils/collection';
import moment from 'moment';
import { flatMap } from 'lodash';
import { FeatureNames } from '../../model/FeatureNames';
import { AppHeaderComponent, AvatarMenuComponent, MenuComponent } from '@pure/hive';
import { UserModalService } from '../../user-management/user-modal/user-modal.service';
import { NavMenuIdPipe } from '../pipes/nav-menu-id.pipe';
import { NotificationsStoreService } from '@pure/notification-center';
import { AuthorizationService } from '@pure/authz-authorizer';
import { MENU } from '../../app/menu';

const FIRST_LOAD_DELAY = 100;
const PROACTIVE_RECOMMENDATIONS_REFRESH_INTERVAL_MS = moment.duration(300, 'seconds').asMilliseconds();

interface IConnectedCount {
    connected: number;
    disconnected: number;
}

@Component({
    selector: 'page-wrapper',
    templateUrl: 'page-wrapper.component.html',
    host: {
        id: 'wrapper',
    },
    providers: [NavMenuIdPipe],
})
export class PageWrapperComponent implements OnInit, OnDestroy {
    @ViewChild(AvatarMenuComponent) avatarMenuComponent: AvatarMenuComponent;
    @ViewChild(AppHeaderComponent) appHeaderComponent: AppHeaderComponent;
    @ViewChild('prorecDummyMenu') prorecDummyMenuElem: MenuComponent;

    pageTitle = 'Pure1';
    stateName = '';
    pageHelpUrl = '';

    tabs: IMainTab[] = [];
    expansion = [];
    arrayCounts: IConnectedCount;
    hwEOLCount: number;
    showHwEOLBadge = false;
    showArrayCountBadge = false;
    expiredSupportContractCount: number;
    mpaRequestPendingCount: number;
    activeTabId: string;
    currentUser: CurrentUser = null;

    isNotificationPopupOpen = false;
    isNotificationPopupOpenTimeoutId: number;
    isFFIdentityCenterEnabled$: Observable<boolean>;
    isFFCopilotEnabled$: Observable<boolean>;

    proRecOpaCheck = false;
    hasProRecFeature = false;
    recommendationCount: number;

    /** The "fullscreen" is defined on the router, and is used by the custom idp config validation (eg: https://localhost:8443/administration/customidp/testresults) */
    @HostBinding('class.full-screen') fullScreen = false;
    @HostBinding('class.export-overlay-enabled') hasActiveExports = false;

    private unsubscribeTransitionsOnSuccess: Function;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private touService: TouService,
        public supportCaseOrchestrator: SupportCaseOrchestratorService,
        private cachedCurrentUserService: CachedCurrentUserService,
        @Inject(WINDOW) private window: Window,
        private featureFlagDxpService: FeatureFlagDxpService,
        private router: Router,
        private route: ActivatedRoute,
        private tabsService: TabsService,
        private unifiedArrayService: UnifiedArrayService,
        private mpaRequestsBadgeService: MpaRequestsBadgeService,
        public notificationsStoreService: NotificationsStoreService,
        private userModalService: UserModalService,
        private navMenuIdPipe: NavMenuIdPipe,
        private authorizationService: AuthorizationService,
        private incidentService: IncidentService,
        private copilotOnboardingModalService: CopilotOnboardingService,
    ) {}

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(currentUser => {
                this.currentUser = currentUser;
            });

        // Slightly delay a lot of init calls to give a chance to the main content to render before counts, reports, …
        this.window.setTimeout(() => {
            this.touService.run().subscribe();
        }, FIRST_LOAD_DELAY);

        // Feature flags
        this.isFFIdentityCenterEnabled$ = this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.IDENTITY_CENTER)
            .pipe(map(policy => policy?.enabled === true));

        this.isFFCopilotEnabled$ = this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.COPILOT)
            .pipe(map(policy => policy?.enabled === true));

        // Load the tabs
        const pipe = new NavMenuIdPipe();
        this.tabsService
            .build$(MENU)
            .pipe(takeUntil(this.destroy$))
            .subscribe(tabs => {
                this.tabs = [...tabs];
                this.updateActiveTabs();
                this.expansion = tabs.map(tab => pipe.transform(tab));
            });

        // To support browser navigation and such, we don't rely on the sidenav's output event
        // to tell us what tab is active. Instead, hook into the router events to update the active tab id on route change.
        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe(() => this.updateActiveTabs());

        // HW EOL
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.HW_EOL)
            .pipe(
                switchMap(feature => {
                    const hwEOLEnabled = feature?.enabled;

                    // Regularly update connected arrays and expired support contract counts
                    const requestFields: getArraysFields[] = [
                        'appliance_id',
                        'is_current',
                        'last_updated',
                        'contract_status',
                        'evergreen_tier',
                    ]; // Only request the subset of fields that we need
                    if (hwEOLEnabled) {
                        requestFields.push('has_end_of_life_hardware');
                    }

                    return smartTimer(0, moment.duration(30, 'seconds').asMilliseconds()).pipe(
                        exhaustMap(() =>
                            recursiveListItems(
                                this.unifiedArrayService,
                                { fields: requestFields },
                                'appliance_id',
                                array => array.appliance_id,
                            ).pipe(
                                catchError(err => {
                                    console.warn('Sidenav: failed to update arrays for counts', err);
                                    return of(null);
                                }),
                            ),
                        ),
                        takeUntil(this.destroy$),
                    );
                }),
            )
            .subscribe(result => {
                this.updateSummaryBadges(result);
            });

        // Proactive recommendations
        combineLatest([
            this.featureFlagDxpService.getFeatureFlag(FeatureNames.PROACTIVE_RECOMMENDATIONS),
            this.authorizationService.isAllowed('PURE1:read:proactive_recommendations'),
        ])
            .pipe(
                tap(([_, isAllowed]) => {
                    this.proRecOpaCheck = isAllowed;
                }),
                map(([feature, _]) => feature),
                filter(feature => feature?.enabled === true),
                switchMap(() => {
                    this.hasProRecFeature = true;
                    // If user has access to ProRec, query IncidentService
                    if (!this.proRecOpaCheck) {
                        return of(void 0);
                    }
                    return this.getOpenIncidentCount();
                }),
                takeUntil(this.destroy$),
            )
            .subscribe(data => {
                if (data != null) {
                    this.recommendationCount = data;
                }
            });

        // Update mpa count
        this.mpaRequestsBadgeService.loadFreshMpaRequests();
        this.mpaRequestsBadgeService.mpaPendingRequests$.pipe(takeUntil(this.destroy$)).subscribe(requests => {
            this.mpaRequestPendingCount = requests.length;
        });

        // The NavigationSkipped event is here due to CLOUD-124234. This may be a bug in the interaction with the Angular Router and UIRouter.
        // Having the NavigationSkipped here doesn't actually hurt anything since we always updated based on the current state, it just
        // means we update more than we might need to otherwise. It may no longer be needed once we fully remove UIRouter.
        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter(e => e instanceof NavigationEnd || e instanceof NavigationSkipped),
            )
            .subscribe((e: NavigationEnd | NavigationSkipped) => this.onRouteChangeEnd(e));

        // Kick off initial callback since router.events won't trigger on the first page load.
        // Without this, the page title / helpUrl won't get set correctly on page load.
        this.onRouteChangeEnd(null);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        if (this.unsubscribeTransitionsOnSuccess) {
            this.unsubscribeTransitionsOnSuccess();
        }
    }

    onActiveExportsChanged(active: boolean): void {
        this.hasActiveExports = active;
    }

    handleNotificationOpenChange(isOpen: boolean): void {
        // We want to keep the NotificationPopupComponent out of the DOM if its not visible
        // since that component also has internal timers that are pointless if the popup is not visible.
        if (isOpen) {
            this.isNotificationPopupOpen = true;
            // Ensure we don't have an old timeout still going
            this.window.clearTimeout(this.isNotificationPopupOpenTimeoutId);
        } else {
            // We need to remove the component on a timeout to ensure the animation has time to finish before removing from the DOM
            this.isNotificationPopupOpenTimeoutId = this.window.setTimeout(() => {
                this.isNotificationPopupOpen = false;
            }, 500);
        }
    }

    handleAIBotOpenChange(isOpen: boolean): void {
        // We treat the AI button as a link only. So when it is clicked, immediately hide
        // the popover panel, and redirect.
        if (isOpen) {
            this.window.setImmediate(() => {
                // A setImmediate() is needed to prevent a (seemingly harmless) warning: "NG0911: View has already been destroyed."
                this.appHeaderComponent.hideAiBotPopUp();
            });
        }

        // Don't do anything if we're already on the AI page
        if (this.router.url.split('?')[0]?.includes('/copilot')) {
            return;
        }

        const keywordSuggestion = this.copilotOnboardingModalService.getSuggestionKeywordFromUrl(
            this.router.url.split('?')[0],
        );

        // If user hasn't accessed AI through the modal dialog button yet, show the modal dialog first, so they access it that way
        if (this.copilotOnboardingModalService.shouldShowOnboardingModal()) {
            this.copilotOnboardingModalService.showOnboardingModal();
        } else {
            this.router.navigate(['/copilot'], { queryParams: { suggestion: keywordSuggestion } });
        }
    }

    showSidePanel(): boolean {
        if (this.pageTitle === 'Orders') {
            if (this.currentUser?.isPartner) {
                return true;
            }
        }
        return false;
    }

    openMenuLinkNewTab(url: string): void {
        this.window.open(url, '_blank');
    }

    clickTab(tab: ITab): void {
        // routerLink directive will handle internal links
        if (tab.href) {
            this.openMenuLinkNewTab(tab.href);
        }
    }

    legacyClickUserButton(): void {
        // The pre-IdC way to open user modal
        if (this.currentUser) {
            this.userModalService.open(this.currentUser);

            // Since we don't use the Hive menu, immediately hide it
            this.avatarMenuComponent.hideMenu();
        }
    }

    closeNotificationPopup(): void {
        this.appHeaderComponent.hideNotificationsPopUp();
    }

    handleClickLogo(): void {
        this.router.navigate(['/']);
    }

    handleClickProRecIcon(): void {
        // We don't actually want the menu popover, we just have to have it for Hive. So hide it immediately.
        this.prorecDummyMenuElem.hideOverlay();

        this.router.navigate(['/services/servicecatalog/recommendationsview']);
    }

    /**
     * This handles Angular router navigation
     */
    private onRouteChangeEnd(e: NavigationEnd | NavigationSkipped): void {
        let currentRoute = this.route.root;
        const { snapshot } = currentRoute;
        let data = snapshot.data;
        this.pageTitle = snapshot.title;
        while (currentRoute.children[0] != null) {
            currentRoute = currentRoute.children[0];
            if (currentRoute.snapshot) {
                if (currentRoute.snapshot.title) {
                    this.pageTitle = currentRoute.snapshot.title;
                }
                data = {
                    ...data,
                    ...currentRoute.snapshot.data,
                };
            }
        }

        if (data.pageHelpUrlHidingFeatureFlag) {
            this.featureFlagDxpService.getFeatureFlag(data.pageHelpUrlHidingFeatureFlag).subscribe(feature => {
                if (!feature || feature.enabled !== true) {
                    this.pageHelpUrl = data.pageHelpUrl;
                }
            });
        } else {
            this.pageHelpUrl = data.pageHelpUrl;
        }

        this.fullScreen = data.fullScreen === true;
    }

    private updateSummaryBadges(arrays: UnifiedArray[]): void {
        if (arrays == null) {
            return; // If null due to error / invalid data, don't change anything
        }

        // Update counts
        this.expiredSupportContractCount = arrays.filter(
            array =>
                (array.subscription_type === SubscriptionType.FOUNDATION ||
                    array.subscription_type === SubscriptionType.FOREVER) &&
                array.contract_status === ArrayContractStatus.EXPIRED,
        ).length;
        this.hwEOLCount = arrays.filter(array => array.has_end_of_life_hardware).length;

        const connectedArrays = arrays.filter(array => array.isCurrent);
        this.arrayCounts = {
            connected: connectedArrays.length,
            disconnected: arrays.length - connectedArrays.length,
        };

        // For Appliances, we have two badges, but only space to show one at a time. If a user has both the HW EOL and Array Count
        // badges needing to be shown, only show one of them, but cycle through which one gets shown, with the EOL shown first.
        let newShowHwEOLBadge = this.hwEOLCount > 0;
        let newShowArrayCountBadge = this.arrayCounts.disconnected > 0;

        if (newShowHwEOLBadge && newShowArrayCountBadge) {
            if (this.showHwEOLBadge) {
                newShowHwEOLBadge = false;
            } else {
                newShowArrayCountBadge = false;
            }
        }

        this.showHwEOLBadge = newShowHwEOLBadge;
        this.showArrayCountBadge = newShowArrayCountBadge;
    }

    private updateActiveTabs(): void {
        const isActiveParams: IsActiveMatchOptions = {
            matrixParams: 'ignored',
            paths: 'subset',
            queryParams: 'ignored',
            fragment: 'ignored',
        };

        const activeTab = flatMap(this.tabs.map(tab => tab.subTabs))
            .concat(this.tabs)
            .filter(tab => tab?.path != null)
            .find(tab => {
                if (
                    tab.path === '/services/servicecatalog/main' &&
                    this.router.isActive('/services/servicecatalog/details', isActiveParams)
                ) {
                    // Highlight the main tab when on the details page
                    return true;
                } else {
                    return this.router.isActive(tab.path, isActiveParams);
                }
            });

        this.activeTabId = this.navMenuIdPipe.transform(activeTab);
    }

    private getOpenIncidentCount(): Observable<number> {
        return smartTimer(0, PROACTIVE_RECOMMENDATIONS_REFRESH_INTERVAL_MS).pipe(
            exhaustMap(() =>
                this.incidentService.list().pipe(
                    take(1),
                    catchError(err => {
                        console.error('Failed to update incident count', err);
                        return of(null);
                    }),
                ),
            ),
            map(data => data?.total),
        );
    }
}
