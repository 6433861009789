<ng-select
    [items]="eventOptions"
    [groupBy]="getAllGroup"
    placeholder="No events selected"
    [multiple]="true"
    [searchable]="false"
    [selectableGroup]="true"
    [selectableGroupAsModel]="false"
    [closeOnSelect]="false"
    [clearable]="false"
    [ngModel]="events"
    (change)="onChange($event)"
>
    <ng-template ng-optgroup-tmp>
        <div [ngClass]="{ 'select-all-selected': isAllSelected }">All</div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <hive-checkbox [id]="item" [ngModel]="item$.selected" class="option-text">
            {{ item | genealogyEventDisplayName }}
        </hive-checkbox>
    </ng-template>
    <ng-template ng-multi-label-tmp>
        Show Events: <span class="events-label">{{ label }}</span>
    </ng-template>
</ng-select>
