import { Resource } from '../interfaces/resource';
import { AssessmentAnomalyVolume, AssessmentHoneycombTile } from './assessment-array';
import { Theme } from '../../ui/styles/theme';
import { SecurityCountLevel } from '../../assessment/security-assessment/security-assessment-appliance-count/security-assessment-appliance-count.component';
import { ApplianceType } from './sustainability-array';

export const SECURITY_ASSESSMENT_GFB_ENTITY = 'security assessment';

export class SecurityAssessmentAppliance implements Resource, AssessmentHoneycombTile {
    name: string;
    assessmentDataTimestamp: number;
    id: string;
    organizationId: string;
    type: ApplianceType;
    model: string;
    purityVersion: string;
    notPhoningHome: boolean;
    excludedFromAssessment: boolean;
    assessmentLevel: SecurityAssessmentLevel;
    totalScore: number;
    totalMaxScore: number;
    categories: SecurityAssessmentApplianceCategory[];
    insights: SecurityAssessmentInsight[];
    metricsMap: { [key in SecurityAssessmentMetric]: SecurityMetricValue };

    constructor(response: SecurityAssessmentAppliance) {
        this.name = response.name;
        this.assessmentDataTimestamp = response.assessmentDataTimestamp;
        this.id = response.id;
        this.organizationId = response.organizationId;
        this.type = response.type;
        this.model = response.model;
        this.purityVersion = response.purityVersion;
        this.notPhoningHome = response.notPhoningHome;
        this.excludedFromAssessment = response.excludedFromAssessment;
        this.assessmentLevel = response.assessmentLevel;
        this.totalScore = response.totalScore;
        this.totalMaxScore = response.totalMaxScore;
        this.categories = response.categories?.map(category => new SecurityAssessmentApplianceCategory(category));
        this.insights = response.insights?.map(insight => new SecurityAssessmentInsight(insight));
        this.metricsMap = response.metricsMap;
    }

    getAssessmentLevel(): string {
        if (this.excludedFromAssessment) {
            return 'EXCLUDED';
        }
        if (this.notPhoningHome) {
            return 'NOT_PHONING_HOME';
        }
        return this.assessmentLevel;
    }

    getCategory(category: SecurityAssessmentCategory): SecurityAssessmentApplianceCategory {
        return this.categories.find(c => c.type === category);
    }
}

export class SecurityMetricValue {
    value: boolean;
    isCompliant: boolean;
    isScored: boolean;

    constructor(response: SecurityMetricValue) {
        this.value = response.value;
        this.isCompliant = response.isCompliant;
        this.isScored = response.isScored;
    }
}

export class SecurityAssessmentApplianceCategory {
    type: SecurityAssessmentCategory;
    metadata: CategoryMetadata;
    categoryScore: number;
    categoryMaxScore: number;

    constructor(response: SecurityAssessmentApplianceCategory) {
        this.type = response.type;
        this.metadata = SecurityAssessmentCategoryMapping[response.type];
        this.categoryScore = response.categoryScore;
        this.categoryMaxScore = response.categoryMaxScore;
    }
}

export class SecurityAssessmentInsight {
    metric: SecurityAssessmentMetric;
    severity: SecurityAssessmentInsightSeverity;
    type: SecurityAssessmentInsightType;
    categoryType: SecurityAssessmentCategory;

    constructor(response: SecurityAssessmentInsight) {
        this.metric = response.metric;
        this.severity = response.severity;
        this.type = response.type;
        // special case, when category type is null its NPH
        this.categoryType =
            response.categoryType && response.type !== SecurityAssessmentInsightType.APPLIANCE_NOT_PHONING_HOME
                ? response.categoryType
                : SecurityAssessmentCategory.NOT_PHONING_HOME;
    }
}

export class SecurityAssessmentFleet implements Resource {
    id: string;
    name: string;
    assessmentGenerationTimestamp: number;
    totalNumberOfScoredAppliances: number;
    numberOfFlashArrays: number;
    numberOfFlashBlades: number;
    notPhoningHomeNumberOfAppliances: number;
    excludedNumberOfAppliances: number;
    totalScore: number;
    totalMaxScore: number;
    categoryScoreSummary: SecurityFleetCategoryScoreSummary[];
    categories: SecurityAssessmentFleetCategory[];
    // Untouched categories list
    tableCategories: SecurityAssessmentFleetCategory[];

    constructor(response: SecurityAssessmentFleet) {
        this.assessmentGenerationTimestamp = response.assessmentGenerationTimestamp;
        this.totalNumberOfScoredAppliances = response.totalNumberOfScoredAppliances;
        this.numberOfFlashArrays = response.numberOfFlashArrays;
        this.numberOfFlashBlades = response.numberOfFlashBlades;
        this.notPhoningHomeNumberOfAppliances = response.notPhoningHomeNumberOfAppliances;
        this.excludedNumberOfAppliances = response.excludedNumberOfAppliances;
        this.totalScore = response.totalScore;
        this.totalMaxScore = response.totalMaxScore;
        this.categoryScoreSummary = response.categoryScoreSummary.map(
            summary => new SecurityFleetCategoryScoreSummary(summary),
        );
        this.categories = response.categories.map(category => new SecurityAssessmentFleetCategory(category));
        this.tableCategories = response.categories.map(category => new SecurityAssessmentFleetCategory(category));

        // get CURRENT_ANOMALIES out of informative to show in different card
        const info = this.getCategory(SecurityAssessmentCategory.NOT_SCORED);
        this.categories.push(
            new SecurityAssessmentFleetCategory({
                type: SecurityAssessmentCategory.INFORMATIVE,
                metrics: [info.metrics.find(m => m.type === SecurityAssessmentMetric.CURRENT_ANOMALIES)],
            } as SecurityAssessmentFleetCategory),
        );
        info.metrics = info.metrics.filter(
            m =>
                ![
                    SecurityAssessmentMetric.CURRENT_ANOMALIES,
                    SecurityAssessmentMetric.DATA_ENCRYPTION_ENABLED,
                ].includes(m.type),
        );
    }

    getCategory(category: SecurityAssessmentCategory): SecurityAssessmentFleetCategory {
        return this.categories.find(c => c.type === category);
    }

    getCategorySummary(category: SecurityAssessmentCategory): SecurityFleetCategoryScoreSummary {
        return this.categoryScoreSummary.find(c => c.type === category);
    }
}

export class SecurityFleetCategoryScoreSummary {
    type: SecurityAssessmentCategory;
    categoryScore: number;
    categoryMaxScore: number;

    constructor(response: SecurityFleetCategoryScoreSummary) {
        this.type = response.type;
        this.categoryScore = response.categoryScore;
        this.categoryMaxScore = response.categoryMaxScore;
    }
}

export class SecurityAssessmentFleetCategory {
    type: SecurityAssessmentCategory;
    metadata: CategoryMetadata;
    metrics: SecurityAssessmentFleetMetric[];

    constructor(response: SecurityAssessmentFleetCategory) {
        this.type = response.type;
        this.metadata = SecurityAssessmentCategoryMapping[response.type];
        this.metrics = response.metrics?.map(metric => new SecurityAssessmentFleetMetric(metric));
    }
}

export class SecurityAssessmentFleetMetric {
    type: SecurityAssessmentMetric;
    distinctAppliancesCount: number;
    supportedApplianceTypes: ApplianceType[];
    includedInSecurityStandards: SecurityStandard[];

    metadata: MetricMetadata;

    constructor(response: SecurityAssessmentFleetMetric) {
        this.type = response.type;
        this.distinctAppliancesCount = response.distinctAppliancesCount;
        this.metadata = SecurityAssessmentMetricMapping[response.type];
        this.supportedApplianceTypes = response.supportedApplianceTypes;
        this.includedInSecurityStandards = response.includedInSecurityStandards;
    }
}

export class SecurityAssessmentFleetTimeSeries implements Resource {
    id: string;
    name: string;
    type: SecurityTimeSeries;
    distinctAppliancesCount: number;
    assessmentGenerationTimestamp: number;
    supportedApplianceTypes: ApplianceType[];
    includedInSecurityStandards: SecurityStandard[];
    dataPoints: SecurityAssessmentTimeSeriesDatapoint[];
    chartData: SecurityBarChartData;

    metadata: TimeSeriesMetadata;

    constructor(response: SecurityAssessmentFleetTimeSeries) {
        this.id = this.name = this.type = response.type;
        this.assessmentGenerationTimestamp = response.assessmentGenerationTimestamp;
        this.distinctAppliancesCount = response.distinctAppliancesCount;
        this.dataPoints = response.dataPoints.map(dataPoint => new SecurityAssessmentTimeSeriesDatapoint(dataPoint));
        this.supportedApplianceTypes = response.supportedApplianceTypes;
        this.includedInSecurityStandards = response.includedInSecurityStandards;
        this.chartData = this.getChartData();
        this.metadata = {
            title: SecurityAssessmentMetricMapping[ChartTypeMetricMapping[this.type]].title,
            metricType: ChartTypeMetricMapping[this.type],
        };
    }

    isEnoughData(): boolean {
        return (
            this.chartData.data.length &&
            this.chartData.data.some(dataPoint => dataPoint?.data.some(value => value > 0))
        );
    }

    private getChartData(): SecurityBarChartData {
        const intervals: string[] = this.dataPoints.map(dataPoint => {
            const start = new Date(dataPoint.datapointStartTimeMillis);
            const startMonth = start.toLocaleString('default', { month: 'short' });
            return `${startMonth} ${start.getDate()}`;
        });

        const mapByType = new Map();
        this.dataPoints.forEach(dataPoint => {
            dataPoint.typedValues.forEach(value => {
                // Ignore RESOLVED type for CRITICAL_ALERTS values as it is sum of CLOSED and SEVERITY_DROPPED
                if (value.type !== DatapointType.RESOLVED) {
                    if (!mapByType.has(value.type)) {
                        mapByType.set(value.type, []);
                    }
                    mapByType.get(value.type).push(value.value);
                }
            });
        });

        const data = Array.from(mapByType.entries()).map(entry => ({
            seriesType: entry[0] === DatapointType.TOTAL_OPEN ? 'spline' : 'column',
            id: entry[0],
            name: DataPointTypeMapping[entry[0]],
            metricType: entry[0],
            data: entry[1],
        }));

        return {
            type: this.type,
            intervals,
            data,
        };
    }
}

export interface SecurityBarChartData {
    type: SecurityTimeSeries;
    intervals: string[];
    data: {
        id: DatapointType;
        seriesType: string;
        name: string;
        metricType: DatapointType;
        data: number[];
    }[];
}

export class SecurityAssessmentTimeSeriesDatapoint {
    datapointStartTimeMillis: number;
    datapointMiddleTimeMillis: number;
    datapointEndTimeMillis: number;
    datapointTimeSizeMillis: number;
    typedValues: SecurityAssessmentTimeSeriesDatapointValue[];

    constructor(response: SecurityAssessmentTimeSeriesDatapoint) {
        this.datapointStartTimeMillis = response.datapointStartTimeMillis;
        this.datapointMiddleTimeMillis = response.datapointMiddleTimeMillis;
        this.datapointEndTimeMillis = response.datapointEndTimeMillis;
        this.datapointTimeSizeMillis = response.datapointTimeSizeMillis;
        this.typedValues = response.typedValues.map(value => new SecurityAssessmentTimeSeriesDatapointValue(value));
    }
}

export class SecurityAssessmentTimeSeriesDatapointValue {
    type: DatapointType;
    value: number;

    constructor(response: SecurityAssessmentTimeSeriesDatapointValue) {
        this.type = response.type;
        this.value = response.value;
    }
}

export class SecurityAssessmentHistoricalData implements Resource {
    id: string;
    name: string;
    model: string;
    purityVersion: string;
    applianceType: ApplianceType;

    constructor(response: SecurityAssessmentHistoricalData) {
        this.id = response.id;
        this.name = response.name;
        this.model = response.model;
        this.purityVersion = response.purityVersion;
        this.applianceType = response.applianceType;
    }
}

export class SecurityAssessmentHistoricAnomaliesData extends SecurityAssessmentHistoricalData implements Resource {
    drrAnomalyCount: number;
    latencyReadAnomalyCount: number;
    latencyWriteAnomalyCount: number;
    volumesObjectAnomalyCount: number;
    snapshotObjectAnomalyCount: number;

    constructor(response: SecurityAssessmentHistoricAnomaliesData) {
        super(response);
        this.drrAnomalyCount = response.drrAnomalyCount;
        this.latencyReadAnomalyCount = response.latencyReadAnomalyCount;
        this.latencyWriteAnomalyCount = response.latencyWriteAnomalyCount;
        this.volumesObjectAnomalyCount = response.volumesObjectAnomalyCount;
        this.snapshotObjectAnomalyCount = response.snapshotObjectAnomalyCount;
    }
}

export class SecurityAssessmentHistoricAdminOpsData extends SecurityAssessmentHistoricalData implements Resource {
    createUserCount: number;
    deleteUserCount: number;
    updatePermissionsCount: number;

    constructor(response: SecurityAssessmentHistoricAdminOpsData) {
        super(response);
        this.createUserCount = response.createUserCount;
        this.deleteUserCount = response.deleteUserCount;
        this.updatePermissionsCount = response.updatePermissionsCount;
    }
}

export class SecurityAssessmentHistoricRemoteAssistData extends SecurityAssessmentHistoricalData implements Resource {
    openedCount: number;

    constructor(response: SecurityAssessmentHistoricRemoteAssistData) {
        super(response);
        this.openedCount = response.openedCount;
    }
}

export class SecurityAssessmentHistoricCriticalAlertsData extends SecurityAssessmentHistoricalData implements Resource {
    currentlyOpenCriticalAlerts: number;
    criticalAlertsOpened: number;
    criticalAlertsClosed: number;
    criticalAlertsSeverityDropped: number;
    criticalAlertsResolved: number;

    constructor(response: SecurityAssessmentHistoricCriticalAlertsData) {
        super(response);
        this.currentlyOpenCriticalAlerts = response.currentlyOpenCriticalAlerts;
        this.criticalAlertsOpened = response.criticalAlertsOpened;
        this.criticalAlertsClosed = response.criticalAlertsClosed;
        this.criticalAlertsSeverityDropped = response.criticalAlertsSeverityDropped;
        this.criticalAlertsResolved = response.criticalAlertsResolved;
    }
}

export class SecurityAssessmentCurrentAnomaliesArray implements Resource {
    assessmentDataTimestamp: number;
    id: string;
    name: string;
    model: string;
    purityVersion: string;
    orgId: number;
    otherAnomalies: DatapointType[];
    volumes: AssessmentAnomalyVolume[];

    constructor(response: SecurityAssessmentCurrentAnomaliesArray) {
        this.assessmentDataTimestamp = response.assessmentDataTimestamp;
        this.id = response.id;
        this.name = response.name;
        this.model = response.model;
        this.purityVersion = response.purityVersion;
        this.orgId = response.orgId;
        this.otherAnomalies = response.otherAnomalies;
        this.volumes = response.volumes.map(volume => new AssessmentAnomalyVolume(volume));
    }
}

export enum SecurityTimeSeries {
    ANOMALIES = 'ANOMALIES',
    CRITICAL_ALERTS = 'CRITICAL_ALERTS',
    ADMIN_OPERATIONS = 'ADMIN_OPERATIONS',
    REMOTE_ASSIST_SESSIONS = 'REMOTE_ASSIST_SESSIONS',
    REMOTE_ASSIST_SESSIONS_COUNT = 'REMOTE_ASSIST_SESSIONS_COUNT',
    CURRENT_ANOMALIES = 'CURRENT_ANOMALIES',
}

export enum SecurityStandard {
    PCI_DSS = 'PCI_DSS',
}

export enum DatapointType {
    DRR = 'DRR',
    READ_LATENCY = 'READ_LATENCY',
    WRITE_LATENCY = 'WRITE_LATENCY',
    OBJECT_COUNT_VOLUMES = 'OBJECT_COUNT_VOLUMES',
    OBJECT_COUNT_SNAPSHOTS = 'OBJECT_COUNT_SNAPSHOTS',
    CREATE_USERS = 'CREATE_USERS',
    DELETE_USERS = 'DELETE_USERS',
    CHANGE_PERMISSIONS = 'CHANGE_PERMISSIONS',
    OPENED = 'OPENED',
    CLOSED = 'CLOSED',
    RESOLVED = 'RESOLVED',
    SEVERITY_DROPPED = 'SEVERITY_DROPPED',
    TOTAL_OPEN = 'TOTAL_OPEN',
    REMOTE_ASSIST = 'REMOTE_ASSIST',
    REMOTE_ASSIST_SESSION_COUNT = 'REMOTE_ASSIST_SESSION_COUNT',
    DATAPOINT_TYPE_SINGLE = 'DATAPOINT_TYPE_SINGLE',
}

export const DataPointTypeMapping = {
    [DatapointType.DRR]: 'DRR',
    [DatapointType.READ_LATENCY]: 'Total Latency Read',
    [DatapointType.WRITE_LATENCY]: 'Total Latency Write',
    [DatapointType.OBJECT_COUNT_VOLUMES]: 'Volume Count',
    [DatapointType.OBJECT_COUNT_SNAPSHOTS]: 'Volume Snapshot Count',
    [DatapointType.CREATE_USERS]: 'Create Users',
    [DatapointType.DELETE_USERS]: 'Delete Users',
    [DatapointType.CHANGE_PERMISSIONS]: 'Change Permissions',
    [DatapointType.OPENED]: 'Opened',
    [DatapointType.CLOSED]: 'Closed',
    [DatapointType.RESOLVED]: 'Resolved',
    [DatapointType.SEVERITY_DROPPED]: 'Severity Dropped',
    [DatapointType.TOTAL_OPEN]: 'Total Open',
    [DatapointType.REMOTE_ASSIST]: 'Appliances with Opened Remote Assist Sessions',
    [DatapointType.REMOTE_ASSIST_SESSION_COUNT]: 'Opened Remote Assist Sessions',
    [DatapointType.DATAPOINT_TYPE_SINGLE]: 'Single',
};

export enum SecurityAssessmentMetric {
    DATA_ENCRYPTION_DISABLED = 'DATA_ENCRYPTION_DISABLED',
    PURITY_END_OF_LIFE_VERSION_IN_USE = 'PURITY_END_OF_LIFE_VERSION_IN_USE',
    PURITY_OPTIMIZATIONS_PENDING = 'PURITY_OPTIMIZATIONS_PENDING',
    REMOTE_ACCESS_CONTROL_ENABLED = 'REMOTE_ACCESS_CONTROL_ENABLED',
    NTP_SERVER_ENABLED_AND_RUNNING = 'NTP_SERVER_ENABLED_AND_RUNNING',
    RAPID_DATA_LOCKING_ENABLED = 'RAPID_DATA_LOCKING_ENABLED',
    PUBLIC_OR_OPEN_BUCKET_ACCESS = 'PUBLIC_OR_OPEN_BUCKET_ACCESS',
    DEFAULT_PASSWORD_IN_USE = 'DEFAULT_PASSWORD_IN_USE',
    LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS = 'LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS',
    CURRENT_CRITICAL_ALERTS = 'CURRENT_CRITICAL_ALERTS',
    DATA_ENCRYPTION_ENABLED = 'DATA_ENCRYPTION_ENABLED',
    DATA_PROTECTION_FEATURES = 'DATA_PROTECTION_FEATURES',
    CURRENT_ANOMALIES = 'CURRENT_ANOMALIES',
    NOT_PHONING_HOME = 'NOT_PHONING_HOME',

    REMOTE_ASSIST_SESSIONS = 'REMOTE_ASSIST_SESSIONS',
    ADMIN_OPERATIONS = 'ADMIN_OPERATIONS',
    CRITICAL_ALERTS = 'CRITICAL_ALERTS',
    ANOMALIES = 'ANOMALIES',
}

export enum SecurityAssessmentInsightType {
    PUBLIC_ACCESS_RISK = 'PUBLIC_ACCESS_RISK',
    REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS = 'REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS',
    REMOTE_ASSIST_LONGER_THAN_ONE_DAY = 'REMOTE_ASSIST_LONGER_THAN_ONE_DAY',
    END_OF_LIFE_RISK = 'END_OF_LIFE_RISK',
    DEFAULT_PASSWORD_RISK = 'DEFAULT_PASSWORD_RISK',
    REVIEW_DATA_PROTECTION = 'REVIEW_DATA_PROTECTION',
    CURRENT_DRR_ANOMALIES_DETECTED = 'CURRENT_DRR_ANOMALIES_DETECTED',
    CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED = 'CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED',
    CURRENT_LATENCY_READ_ANOMALIES_DETECTED = 'CURRENT_LATENCY_READ_ANOMALIES_DETECTED',
    CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED = 'CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED',
    CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED = 'CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED',
    REMOTE_ACCESS_CONTROL_NOT_ENABLED = 'REMOTE_ACCESS_CONTROL_NOT_ENABLED',
    NTP_SERVER_NOT_ENABLED = 'NTP_SERVER_NOT_ENABLED',
    PURITY_OPTIMIZATIONS_AVAILABLE = 'PURITY_OPTIMIZATIONS_AVAILABLE',
    APPLIANCE_NOT_PHONING_HOME = 'APPLIANCE_NOT_PHONING_HOME',
    CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED = 'CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED',
    SOFTWARE_DATA_ENCRYPTION_DISABLED = 'SOFTWARE_DATA_ENCRYPTION_DISABLED',
    RAPID_DATA_LOCKING_NOT_ENABLED = 'RAPID_DATA_LOCKING_NOT_ENABLED',
}

export enum SecurityAssessmentInsightSeverity {
    Low = 'LOW',
    Medium = 'MEDIUM',
    High = 'HIGH',
}

export enum SecurityAssessmentLevel {
    NeedsImprovement = 'NEEDS_IMPROVEMENT',
    // to be removed after BE change
    Good = 'GOOD',
    Optimizable = 'OPTIMIZABLE',
    Excellent = 'EXCELLENT',
}

export enum SecurityAssessmentLevelFilter {
    NEEDS_IMPROVEMENT = 'NEEDS_IMPROVEMENT',
    GOOD = 'GOOD',
    EXCELLENT = 'EXCELLENT',
    EXCLUDED = 'EXCLUDED',
    NOT_PHONING_HOME = 'NOT_PHONING_HOME',
}

export enum SecurityAssessmentCategory {
    COMPLIANCE_BASIC = 'COMPLIANCE_BASIC',
    COMPLIANCE_ADVANCED = 'COMPLIANCE_ADVANCED',
    COMPLIANCE_ALL = 'COMPLIANCE_ALL',
    OPERATIONAL_BASIC = 'OPERATIONAL_BASIC',
    OPERATIONAL_ALL = 'OPERATIONAL_ALL',
    NOT_SCORED = 'NOT_SCORED',
    // Artificial category for FE use
    INFORMATIVE = 'INFORMATIVE',
    NOT_PHONING_HOME = 'NOT_PHONING_HOME',
}

export const SecurityAssessmentCategoryOrder = {
    [SecurityAssessmentCategory.COMPLIANCE_ALL]: 1,
    [SecurityAssessmentCategory.COMPLIANCE_BASIC]: 2,
    [SecurityAssessmentCategory.COMPLIANCE_ADVANCED]: 3,
    [SecurityAssessmentCategory.OPERATIONAL_ALL]: 4,
    [SecurityAssessmentCategory.OPERATIONAL_BASIC]: 5,
    [SecurityAssessmentCategory.NOT_SCORED]: 6,
    [SecurityAssessmentCategory.INFORMATIVE]: 7,
    [SecurityAssessmentCategory.NOT_PHONING_HOME]: 8,
};

export type CategoryMetadata = {
    title: string;
    subtitle?: string;
    subcategory?: string;
    shouldHideTitle?: boolean;
    level: SecurityCountLevel;
    icon?: string;
};

type MetricMetadata = {
    title: string;
    tableHeaderTitle: string;
    tooltip: string | string[];
    link?: boolean;
    timeSeriesType?: SecurityTimeSeries;
};

type TimeSeriesMetadata = {
    title: string;
    metricType: SecurityAssessmentMetric;
};

export const SecurityAssessmentCategoryMapping: { [key in SecurityAssessmentCategory]: CategoryMetadata } = {
    [SecurityAssessmentCategory.COMPLIANCE_BASIC]: {
        title: 'Compliance',
        subcategory: 'Basic',
        level: 'danger',
        icon: 'light/shield-keyhole.svg',
    },
    [SecurityAssessmentCategory.COMPLIANCE_ADVANCED]: {
        title: 'Compliance',
        shouldHideTitle: true,
        subcategory: 'Advanced',
        level: 'success',
        icon: 'light/shield-keyhole.svg',
    },
    [SecurityAssessmentCategory.COMPLIANCE_ALL]: {
        title: 'Compliance',
        level: 'danger',
        icon: 'light/shield-keyhole.svg',
    },
    [SecurityAssessmentCategory.OPERATIONAL_BASIC]: {
        title: 'Operational',
        level: 'danger',
        icon: 'light/gear.svg',
    },
    [SecurityAssessmentCategory.OPERATIONAL_ALL]: {
        title: 'Operational',
        level: 'danger',
        icon: 'light/gear.svg',
    },
    [SecurityAssessmentCategory.NOT_SCORED]: {
        title: 'Informative',
        subtitle: '(Not Scored)',
        level: 'info',
        icon: 'light/circle-info.svg',
    },
    [SecurityAssessmentCategory.INFORMATIVE]: {
        title: '',
        level: 'info',
    },
    [SecurityAssessmentCategory.NOT_PHONING_HOME]: {
        title: 'Not Phoning Home',
        level: 'info',
        icon: 'light/hexagon-exclamation.svg',
    },
};

export const SecurityAssessmentMetricMapping: { [key in SecurityAssessmentMetric]: MetricMetadata } = {
    [SecurityAssessmentMetric.DATA_ENCRYPTION_DISABLED]: {
        title: 'Data at Rest Encryption - Disabled',
        tableHeaderTitle: 'Data at Rest Encryption',
        tooltip:
            'Data at Rest encryption is enabled by default on Pure Storage appliances, except where restrictions apply based on government regulations.',
    },
    [SecurityAssessmentMetric.PURITY_END_OF_LIFE_VERSION_IN_USE]: {
        title: 'End-of-Life Purity version in use',
        tableHeaderTitle: 'End-of-Life Purity version not used',
        tooltip: 'End-of-Life Purity versions are a security risk as well as a supportability risk.',
    },
    [SecurityAssessmentMetric.PURITY_OPTIMIZATIONS_PENDING]: {
        title: 'Purity Optimizations Available',
        tableHeaderTitle: 'Any Available Optimizations applied',
        tooltip:
            'Purity Optimizations are software patches recommended for good appliance health and improved security.',
    },
    [SecurityAssessmentMetric.REMOTE_ACCESS_CONTROL_ENABLED]: {
        title: 'Access Control - Remote (LDAP, SAML, AD)',
        tableHeaderTitle: 'Access Control - Remote',
        tooltip:
            'Using a centralized user identity management platform for authentication and access control is a recommended best practice to ensure traceability and observability.',
    },
    [SecurityAssessmentMetric.NTP_SERVER_ENABLED_AND_RUNNING]: {
        title: 'NTP Server - Configured and Synchronized',
        tableHeaderTitle: 'NTP Server',
        tooltip:
            'Using the Network Time Protocol (NTP) for system clock synchronization is a recommended best practice for logging, troubleshooting, and security reasons.',
    },
    [SecurityAssessmentMetric.RAPID_DATA_LOCKING_ENABLED]: {
        title: 'Rapid Data Locking (RDL) Enabled',
        tableHeaderTitle: 'RDL Enabled',
        tooltip:
            'Rapid Data Locking provides an additional layer of data security by requiring a user-controllable cryptographic key to unlock and decrypt the flash modules when the appliance is powered on. ' +
            'Without access to this key, the flash modules are locked and data on the appliance cannot be read or written.',
    },
    [SecurityAssessmentMetric.PUBLIC_OR_OPEN_BUCKET_ACCESS]: {
        title: 'Public or Open Access to Buckets',
        tableHeaderTitle: 'No Public Buckets',
        tooltip: 'Public Access for Buckets may present a security risk.',
    },
    [SecurityAssessmentMetric.DEFAULT_PASSWORD_IN_USE]: {
        title: 'Default Password in Use',
        tableHeaderTitle: 'Default Password changed',
        tooltip: 'The default user (pureuser) is currently configured to use a well-known factory default password.',
    },
    [SecurityAssessmentMetric.LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS]: {
        title: 'Remote Assist Activity detected (> 8 hrs)',
        tableHeaderTitle: 'No Long lasting Remote Assist Activity',
        tooltip: 'Remote Assist sessions left open for longer than 8 hours should be reviewed.',
    },
    [SecurityAssessmentMetric.CURRENT_CRITICAL_ALERTS]: {
        title: 'Open Critical Alerts',
        tableHeaderTitle: 'Any Critical Alerts resolved',
        tooltip: 'Open Critical Alerts may signify a problem with your appliance.',
    },
    [SecurityAssessmentMetric.DATA_ENCRYPTION_ENABLED]: {
        title: 'Data at Rest Encryption - Enabled',
        tableHeaderTitle: 'Data at Rest Encryption Enabled',
        tooltip:
            'Data at Rest encryption is enabled by default on Pure Storage appliances, except where restrictions apply based on government regulations.',
    },
    [SecurityAssessmentMetric.DATA_PROTECTION_FEATURES]: {
        title: 'Optimizable Data Protection Policies',
        tableHeaderTitle: 'Optimizable Data Protection Policies',
        link: true,
        tooltip:
            'The data protection features and policies on this appliance are not configured according to the best practices recommended by Pure Storage. Review and improve your data protection policies.',
    },
    [SecurityAssessmentMetric.CURRENT_ANOMALIES]: {
        title: 'Current Anomalies',
        tableHeaderTitle: 'Current Anomalies',
        tooltip: 'Anomalous changes of data characteristics may indicate malicious activity.',
        link: true,
        timeSeriesType: SecurityTimeSeries.CURRENT_ANOMALIES,
    },
    [SecurityAssessmentMetric.NOT_PHONING_HOME]: {
        title: 'Not Phoning Home',
        tableHeaderTitle: 'Not Phoning Home',
        tooltip: '',
    },
    [SecurityAssessmentMetric.ANOMALIES]: {
        title: 'Anomalies by Start Date',
        tableHeaderTitle: '',
        tooltip: 'Anomalous changes of data characteristics may indicate malicious activity.',
        link: true,
        timeSeriesType: SecurityTimeSeries.ANOMALIES,
    },
    [SecurityAssessmentMetric.ADMIN_OPERATIONS]: {
        title: 'Administrator Operations - User Management',
        tableHeaderTitle: '',
        tooltip:
            'Admin operations involves creating, modifying, and deleting user accounts. Review the historical activity to ensure that there were no unexpected or unintended operations.',
        link: true,
        timeSeriesType: SecurityTimeSeries.ADMIN_OPERATIONS,
    },
    [SecurityAssessmentMetric.CRITICAL_ALERTS]: {
        title: 'Critical Alerts History',
        tableHeaderTitle: '',
        tooltip: 'Open Critical Alerts may signify a problem on your appliance. Review the historical activity.',
        link: true,
        timeSeriesType: SecurityTimeSeries.CRITICAL_ALERTS,
    },
    [SecurityAssessmentMetric.REMOTE_ASSIST_SESSIONS]: {
        title: 'Remote Assist Recently Active',
        tableHeaderTitle: '',
        tooltip:
            'Remote Assist allows Pure Storage technical support engineers to provide remote access for diagnostics and problem resolution. Review the historical activity to ensure that there were no unexpected or unintended sessions.',
        link: true,
        timeSeriesType: SecurityTimeSeries.REMOTE_ASSIST_SESSIONS,
    },
};

export const ChartTypeMetricMapping = {
    [SecurityTimeSeries.ANOMALIES]: SecurityAssessmentMetric.ANOMALIES,
    [SecurityTimeSeries.ADMIN_OPERATIONS]: SecurityAssessmentMetric.ADMIN_OPERATIONS,
    [SecurityTimeSeries.CRITICAL_ALERTS]: SecurityAssessmentMetric.CRITICAL_ALERTS,
    [SecurityTimeSeries.REMOTE_ASSIST_SESSIONS]: SecurityAssessmentMetric.REMOTE_ASSIST_SESSIONS,
    [SecurityTimeSeries.REMOTE_ASSIST_SESSIONS_COUNT]: SecurityAssessmentMetric.REMOTE_ASSIST_SESSIONS,
};

export const SECURITY_METRICS_CHART_COLORS = {
    [SecurityTimeSeries.CRITICAL_ALERTS]: {
        [DatapointType.OPENED]: Theme.security.orange,
        [DatapointType.CLOSED]: Theme.security.lightGreen,
        [DatapointType.RESOLVED]: Theme.security.lightGreen,
        [DatapointType.SEVERITY_DROPPED]: Theme.security.green,
        [DatapointType.TOTAL_OPEN]: Theme.security.blue,
    },
    [SecurityTimeSeries.ANOMALIES]: {
        [DatapointType.DRR]: Theme.security.darkViolet,
        [DatapointType.READ_LATENCY]: Theme.security.darkRose,
        [DatapointType.WRITE_LATENCY]: Theme.security.aquamarine,
        [DatapointType.OBJECT_COUNT_VOLUMES]: Theme.security.darkOrange,
        [DatapointType.OBJECT_COUNT_SNAPSHOTS]: Theme.security.lightOrange,
    },
    [SecurityTimeSeries.ADMIN_OPERATIONS]: {
        [DatapointType.CREATE_USERS]: Theme.security.darkViolet,
        [DatapointType.DELETE_USERS]: Theme.security.rose,
        [DatapointType.CHANGE_PERMISSIONS]: Theme.security.darkRose,
    },
    [SecurityTimeSeries.REMOTE_ASSIST_SESSIONS]: {
        [DatapointType.REMOTE_ASSIST]: Theme.security.violet,
    },
    [SecurityTimeSeries.REMOTE_ASSIST_SESSIONS_COUNT]: {
        [DatapointType.REMOTE_ASSIST_SESSION_COUNT]: Theme.security.darkViolet,
    },
};

export const InsightMapping: { [key in SecurityAssessmentInsightType]: any } = {
    [SecurityAssessmentInsightType.APPLIANCE_NOT_PHONING_HOME]: {
        fleetTitle:
            'Security assessment and anomaly detection is not available for the following appliances due to no PhoneHome telemetry. Enable PhoneHome telemetry for the following appliances.',
        applianceTitle: 'Appliance is Not Phoning Home',
        icon: 'light/hexagon-exclamation.svg',
        actionTitle: 'Enable Phone Home Telemetry',
    },
    [SecurityAssessmentInsightType.PURITY_OPTIMIZATIONS_AVAILABLE]: {
        fleetTitle:
            'Purity Optimizations are software patches recommended for good appliance health and improved security. The following appliances are awaiting updates.',
        applianceTitle: 'Purity Optimizations are available',
        icon: 'light/download.svg',
        actionTitle: 'Optimize Purity',
    },
    [SecurityAssessmentInsightType.CURRENT_DRR_ANOMALIES_DETECTED]: {
        fleetTitle:
            'Data Reduction Ratio (DRR) anomalies are detected on the following appliances. Anomalous changes of data characteristics may indicate malicious activity. Review the anomalies and take any necessary remedial actions.',
        applianceTitle: 'Current DRR anomalies are detected',
        icon: 'light/square-bolt.svg',
        actionTitle: 'Review DRR anomalies',
    },
    [SecurityAssessmentInsightType.CURRENT_LATENCY_READ_ANOMALIES_DETECTED]: {
        fleetTitle:
            'Total Latency Read (SAN + On Array Latency) anomalies are detected on the following appliances. Anomalous changes of data characteristics may indicate malicious activity. Review the anomalies and take any necessary remedial actions.',
        applianceTitle: 'Current Total Latency Read anomalies are detected',
        icon: 'light/square-bolt.svg',
        actionTitle: 'Review Total Latency Read anomalies',
    },
    [SecurityAssessmentInsightType.CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED]: {
        fleetTitle:
            'Total Latency Write (SAN + On Array Latency) anomalies are detected on the following appliances. Anomalous changes of data characteristics may indicate malicious activity. Review the anomalies and take any necessary remedial actions.',
        applianceTitle: 'Current Total Latency Write anomalies are detected',
        icon: 'light/square-bolt.svg',
        actionTitle: 'Review Total Latency Write anomalies',
    },
    [SecurityAssessmentInsightType.CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED]: {
        fleetTitle:
            'Volume Snapshot Count anomalies are detected on the following appliances. Anomalous changes of data characteristics may indicate malicious activity. Review the anomalies and take any necessary remedial actions.',
        applianceTitle: 'Current Volume Snapshot Count anomalies are detected',
        icon: 'light/square-bolt.svg',
        actionTitle: 'Review Volume Snapshot Count anomalies',
    },
    [SecurityAssessmentInsightType.CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED]: {
        fleetTitle:
            'Volume Count anomalies are detected on the following appliances. Anomalous changes of data characteristics may indicate malicious activity. Review the anomalies and take any necessary remedial actions.',
        applianceTitle: 'Current Volume Count anomalies are detected',
        icon: 'light/square-bolt.svg',
        actionTitle: 'Review Volume Count anomalies',
    },
    [SecurityAssessmentInsightType.CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED]: {
        fleetTitle:
            'Open Critical Alerts may signify a problem on your appliance. Review the Open Critical Alerts detected on the following appliances.',
        applianceTitle: 'Open Critical Alerts are detected',
        icon: 'light/triangle-exclamation.svg',
        actionTitle: 'Review Open Critical Alerts',
    },
    [SecurityAssessmentInsightType.END_OF_LIFE_RISK]: {
        fleetTitle:
            'End-of-Life Purity versions are a security risk as well as a supportability risk. The following appliances need to be upgraded.',
        applianceTitle: 'End-of-Life Purity version in Use',
        icon: 'light/download.svg',
        actionTitle: 'Upgrade Purity version',
    },
    [SecurityAssessmentInsightType.NTP_SERVER_NOT_ENABLED]: {
        fleetTitle:
            'Using the Network Time Protocol (NTP) for system clock synchronization is a recommended best practice for logging, troubleshooting, and security reasons.The following appliances do not have NTP synchronization properly configured.',
        applianceTitle: 'NTP Server is not configured or configuration requires attention',
        icon: 'light/server.svg',
        actionTitle: 'Properly configure NTP Server',
    },
    [SecurityAssessmentInsightType.REMOTE_ACCESS_CONTROL_NOT_ENABLED]: {
        fleetTitle:
            'Using a centralized user identity management platform for authentication and access control is a recommended best practice to ensure traceability and observability. ' +
            'The following appliances do not have any remote access control mechanism enabled.',
        applianceTitle: 'Remote Access Control is Not Enabled',
        icon: 'light/fingerprint.svg',
        actionTitle: 'Enable Remote Access Control',
    },
    [SecurityAssessmentInsightType.DEFAULT_PASSWORD_RISK]: {
        fleetTitle: `The default user (pureuser) is currently configured to use a well-known factory default password. Change the password for pureuser to protect against unauthorized access to this appliance.`,
        applianceTitle: 'Default Password in Use',
        icon: 'light/lock.svg',
        actionTitle: 'Reset Default Password',
    },
    [SecurityAssessmentInsightType.REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS]: {
        fleetTitle:
            'Remote Assist sessions left open for longer than 8 hours should be reviewed. Review the Remote Assist session history for the following appliances.',
        applianceTitle: 'Remote Assist Sessions Opened for Longer than 8 Hours',
        icon: 'light/handshake-angle.svg',
        actionTitle: 'Review Remote Assist Sessions',
    },
    [SecurityAssessmentInsightType.REMOTE_ASSIST_LONGER_THAN_ONE_DAY]: {
        fleetTitle:
            'Remote Assist sessions left open for longer than 24 hours must be reviewed. Review the Remote Assist session history for the following appliances.',
        applianceTitle: 'Remote Assist Sessions Opened for Longer than 24 Hours',
        icon: 'light/handshake-angle.svg',
        actionTitle: 'Review Remote Assist Sessions',
    },
    [SecurityAssessmentInsightType.REVIEW_DATA_PROTECTION]: {
        fleetTitle:
            'The data protection features and policies on this appliance are not configured according to the best practices recommended by Pure Storage. Review and improve your data protection policies.',
        applianceTitle: 'Data Protection Configuration could be improved',
        icon: 'light/shield-check.svg',
        actionTitle: 'Review Data Protection',
    },
    [SecurityAssessmentInsightType.PUBLIC_ACCESS_RISK]: {
        fleetTitle:
            'Public Access for Buckets may present a security risk. Review the configuration for the identified buckets on these appliance and confirm that public access is configured as intended.',
        applianceTitle: 'Public Access for Buckets',
        icon: 'light/bucket.svg',
        actionTitle: 'Review Public Access',
    },
    [SecurityAssessmentInsightType.SOFTWARE_DATA_ENCRYPTION_DISABLED]: {
        fleetTitle:
            'Pure Storage appliances have data at rest encryption enabled or disabled by default, according to regional regulations. This setting can not be changed manually. ' +
            'If you think the current configuration is incorrect, you should contact support for further assistance.',
        applianceTitle: 'Software Data Encryption is Disabled',
        icon: 'light/binary-circle-check.svg',
        actionTitle: 'Enable Software Encryption',
    },
    [SecurityAssessmentInsightType.RAPID_DATA_LOCKING_NOT_ENABLED]: {
        fleetTitle:
            'Rapid Data Locking provides an additional layer of data security by requiring a user-controllable cryptographic key to unlock and decrypt the flash modules when the appliance is powered on. ' +
            'Without access to this key, the flash modules are locked and data on the appliance cannot be read or written. ' +
            'Consider enabling Rapid Data Locking if your regulatory compliance or operating environment requires external key management.',
        applianceTitle: 'Rapid Data Locking is Disabled',
        icon: 'light/key.svg',
        actionTitle: 'Enable RDL',
    },
};
