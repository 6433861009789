<hive-content-dialog
    #acceptDialog
    heading="Accept {{ opportunityQuote?.name | defaultString }}"
    [showCloseButton]="true"
    [closeOnBackdropClick]="true"
    size="small"
>
    <div *ngIf="isLoading" class="loading-spinner">
        <pureui-spinner class="p-loading" [pureuiShow]="isLoading"></pureui-spinner>
    </div>
    <p> By accepting, you agree that this version of the quote will be presented to the customer. </p>

    <hive-button slot="actions-right" emphasis="secondary" label="Cancel" (click)="acceptDialog.hide()"></hive-button>
    <hive-button
        slot="actions-right"
        [disabled]="isLoading || !acceptAllowed"
        label="Accept"
        (click)="onAccept()"
    ></hive-button>
</hive-content-dialog>

<hive-content-dialog
    #errorDialog
    heading="Oops! Something went wrong."
    [showCloseButton]="true"
    [closeOnBackdropClick]="true"
    size="small"
>
    <div class="quote-error-dialog-body">
        <svg class="pstg-action-icon" hive-svg="warning-icon.svg" [height]="20"></svg>
        Please try again. If the issue persists, contact Pure1 Technical Services.
    </div>
    <hive-button slot="actions-right" emphasis="secondary" label="Close" (click)="errorDialog.hide()"></hive-button>
</hive-content-dialog>
