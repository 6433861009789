import { Subject, fromEvent, merge } from 'rxjs';
import { takeUntil, startWith, filter, throttleTime } from 'rxjs/operators';
import {
    AfterContentInit,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    Renderer2,
    SimpleChanges,
    DoCheck,
    Inject,
    NgZone,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { TabName, Tab } from './tab-name.directive';
import { WINDOW } from '../../app/injection-tokens';

interface DocumentWithFonts {
    fonts?: {
        ready?: Promise<any>;
    };
}

// Note: if you want to dynamically enable/disable a tab you can use display: none on the tab
@Directive({
    selector: '[pureTabGroup]',
})
export class TabGroup<T> implements OnChanges, AfterContentInit, DoCheck, OnDestroy {
    @Input() readonly selectedTab: T;
    @Input() readonly dynamic: boolean = false;
    @Input() readonly vertical: boolean = false; // If we want it to slide up and down instead
    @Output() readonly selectedTabChange = new EventEmitter<T>();
    @ContentChildren(TabName, { descendants: true }) readonly tabs: QueryList<TabName<T>>;

    private hoveredTabEl: HTMLElement;
    private selectedTabEl: HTMLElement;
    private destroy$ = new Subject<boolean>();
    private callout: HTMLDivElement;
    private currentCalloutStart: number;
    private currentCalloutLength: number;
    private deregisterHook: Function;

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
        private router: Router,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: DocumentWithFonts,
        @Inject(WINDOW) private window: Window,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedTab) {
            this.chooseSelectedTab();
            this.moveCallout();
        }
    }

    ngAfterContentInit(): void {
        // Create the callout
        this.callout = this.renderer.createElement('div');
        this.renderer.addClass(this.callout, 'pure-tab-callout');
        this.renderer.appendChild(this.element.nativeElement, this.callout);

        // Initialize the callout at the proper location
        const initialize = () => {
            if (this.selectedTab) {
                this.chooseSelectedTab();
                this.moveCallout();
            }
        };

        // tabsChanges is the same observable as tabs.changes, but it also emits the initial value, if there is one.
        const tabsChanges$ = this.tabs?.length > 0 ? this.tabs.changes.pipe(startWith(this.tabs)) : this.tabs.changes;
        tabsChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            /* document.fonts.ready is a promise that resolves when the web fonts (@font-face)
             * are loaded. We need to render the callout only once the fonts have loaded otherwise
             * the dimentsions and plancement of the callout are wrong.
             * We cannot first render the callout at an approximate place, and then move it to its
             * proper location because the animation would typically attract too much attention.
             * document.fonts.ready is quite a recent addition to browser APIs so we just ignore it
             * for older browsers. This corner case is kind of minor that a polyfill seems like too much
             * overhead. */
            if (this.document.fonts?.ready) {
                this.document.fonts.ready.then(
                    () => initialize(),
                    () => initialize(),
                );
            } else {
                initialize();
            }

            merge(...this.tabs.map(tab => tab.select$))
                .pipe(takeUntil(this.destroy$))
                .subscribe(v => this.select(v));
            merge(...this.tabs.map(tab => tab.enter$))
                .pipe(takeUntil(this.destroy$))
                .subscribe(v => this.enter(v));
            merge(...this.tabs.map(tab => tab.leave$))
                .pipe(takeUntil(this.destroy$))
                .subscribe(v => this.leave(v));
        });

        // Update the callout position whenever there is a state transition, on the next tick. This fixes issues
        // with the callout position if elements being focused are shown/hidden based on state.
        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe(e => {
                this.ngZone.runOutsideAngular(() => {
                    this.window.setImmediate(() => {
                        this.moveCallout();
                    });
                });
            });

        this.ngZone.runOutsideAngular(() => {
            fromEvent(this.window, 'resize')
                .pipe(throttleTime(50, undefined, { leading: true, trailing: true }), takeUntil(this.destroy$))
                .subscribe(() => {
                    this.moveCallout();
                });
        });
    }

    ngDoCheck(): void {
        if (this.dynamic) {
            // the callout should be moved if the tab placement has changed
            const el = this.hoveredTabEl || this.selectedTabEl;
            if (el) {
                const offsetStart = this.vertical ? el.offsetTop : el.offsetLeft;
                const offsetLength = this.vertical ? el.offsetHeight : el.offsetWidth;
                if (this.currentCalloutStart !== offsetStart || this.currentCalloutLength !== offsetLength) {
                    this.moveCallout();
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.deregisterHook?.();
    }

    private select(tab: Tab<T>): void {
        if (!tab.el?.classList?.contains('disabled')) {
            this.selectedTabChange.next(tab.name);
            this.selectedTabEl = tab.el;
            this.moveCallout();
        }
    }

    private enter(tab: Tab<T>): void {
        if (!tab.el?.classList?.contains('disabled')) {
            this.hoveredTabEl = tab.el;
            this.moveCallout();
        }
    }

    private leave(_tab: Tab<T>): void {
        this.hoveredTabEl = null;
        this.moveCallout();
    }

    private chooseSelectedTab(): void {
        if (!this.tabs) {
            // if we need to choose SelectedTab before the tabs input has been initialized, don't do it
            return;
        }
        const selectedTabs = this.tabs.filter(tab => tab.name === this.selectedTab);
        if (selectedTabs.length === 1) {
            this.selectedTabEl = selectedTabs[0].element.nativeElement;
        } else {
            const nearestMatches = this.tabs.filter(tab => {
                // we're calling toString because T might not be string (though it probably is in practice)
                const tabName = tab.name.toString();
                const selectedTabName = this.selectedTab.toString();
                return selectedTabName.startsWith(tabName);
            });
            if (nearestMatches.length > 0) {
                this.selectedTabEl = nearestMatches[0].element.nativeElement;
            }
        }
    }

    private moveCallout(): void {
        const el = this.hoveredTabEl || this.selectedTabEl;
        if (el) {
            if (this.vertical) {
                this.currentCalloutStart = el.offsetTop;
                this.currentCalloutLength = el.offsetHeight;
                this.renderer.setStyle(this.callout, 'top', `${el.offsetTop}px`);
                this.renderer.setStyle(this.callout, 'height', `${el.offsetHeight}px`);
            } else {
                this.currentCalloutStart = el.offsetLeft;
                this.currentCalloutLength = el.offsetWidth;
                // We get the offset of the parent group's category container as well for categorized-submenu-bar
                // The tab offset PLUS the parent ul's parent container offset
                if (el.classList.contains('categorized-nav-item')) {
                    // First parent is the category <ul>, grandparent is the category container, with an offset within the <nav> itself
                    const totalLeftOffset =
                        el.offsetLeft + ((el.offsetParent as HTMLElement)?.offsetParent as HTMLElement)?.offsetLeft;
                    this.renderer.setStyle(this.callout, 'left', `${totalLeftOffset}px`);
                } else {
                    this.renderer.setStyle(this.callout, 'left', `${el.offsetLeft}px`);
                }
                this.renderer.setStyle(this.callout, 'width', `${el.offsetWidth}px`);
            }
        }
    }
}
