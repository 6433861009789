import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OpportunityQuote, OpportunityQuoteService, QuoteStatus } from '../../../data';
import { ContentDialogComponent } from '@pure/hive';
import { NO_PO_MESSAGE } from './quote-poupload-dialog/quote-poupload-dialog.component';

@Component({
    selector: 'quote-poupload-modal',
    templateUrl: './quote-poupload-modal.component.html',
})
export class QuotePOUploadModalComponent implements OnChanges {
    @Input() readonly opportunityQuote: OpportunityQuote;

    @Output() readonly uploadComplete = new EventEmitter<void>();

    @ViewChild('uploadDialog', { static: true }) readonly uploadDialog: ContentDialogComponent;
    @ViewChild('errorDialog', { static: true }) readonly errorDialog: ContentDialogComponent;
    @ViewChild('successDialog', { static: true }) readonly successDialog: ContentDialogComponent;

    readonly MAX_FILE_SIZE_IN_BYTES = 500 * 1024 * 1024; // 500MiB
    readonly ERROR_PREFIX = 'Error: ';
    readonly FILE_TOO_LARGE = 'File too large';
    readonly FILE_UPLOADED_STATUS = 'File uploaded';
    readonly FILE_FAILED_TO_UPLOAD = 'Failed to upload';
    readonly QuoteStatus = QuoteStatus;

    constructor(private opportunityQuoteService: OpportunityQuoteService) {}

    isLoading = false;
    fileToUpload: File;
    disabledMessage = NO_PO_MESSAGE;
    errorMessage = '';
    modalTitle = '';
    modalDescription = '';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.opportunityQuote && this.opportunityQuote) {
            switch (this.opportunityQuote.status) {
                case QuoteStatus.ACCEPTED:
                    this.modalTitle = `You've accepted ${this.opportunityQuote.name}`;
                    this.modalDescription =
                        'This quote is accepted. Next upload a purchase order to complete the service.';
                    break;
                case QuoteStatus.PO_REJECTED:
                    this.modalTitle = `PO for ${this.opportunityQuote.name} is not valid`;
                    this.modalDescription = 'The PO for this quote was rejected. Please review and upload a new PO';
                    break;
                case QuoteStatus.PO_UPLOADED:
                    this.modalTitle = `You've uploaded a PO for ${this.opportunityQuote.name}`;
                    this.modalDescription = 'Review your uploaded PO';
                    break;
                default:
                    this.modalTitle = 'Upload Purchase Order';
                    this.modalDescription = 'Next upload a purchase order to complete the service.';
            }
        }
    }

    show(): void {
        this.uploadDialog.show();
    }

    onFileChange(fileToUpload: File): void {
        if (fileToUpload == null) {
            this.disabledMessage = NO_PO_MESSAGE;
        } else if (this.isLoading) {
            this.disabledMessage = 'Uploading in progress…';
        } else if (fileToUpload.size >= this.MAX_FILE_SIZE_IN_BYTES) {
            this.disabledMessage = 'Error: File too large';
        } else if (fileToUpload.type !== 'application/pdf') {
            this.disabledMessage = 'Error: File must be a PDF';
        } else {
            this.disabledMessage = '';
        }

        this.fileToUpload = fileToUpload;
    }

    resetInputs(): void {
        this.fileToUpload = null;
        this.disabledMessage = NO_PO_MESSAGE;
    }

    submitAttachment(): void {
        if (!this.fileToUpload) {
            return;
        }

        this.isLoading = true;

        // Upload Files
        const file = this.fileToUpload;
        if (file.size >= this.MAX_FILE_SIZE_IN_BYTES) {
            return;
        }

        this.opportunityQuoteService.uploadPurchaseOrder(this.opportunityQuote, file).subscribe({
            next: () => {
                this.isLoading = false;
                this.uploadComplete.emit();
                this.uploadDialog.hide();
                this.successDialog.show();
                this.resetInputs();
            },
            error: () => {
                this.errorMessage = this.FILE_FAILED_TO_UPLOAD;
                this.isLoading = false;
                this.uploadComplete.emit();
                this.uploadDialog.hide();
                this.errorDialog.show();
                this.resetInputs();
            },
        });
    }
}
