<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(populatedNotification)"
>
</feature-banner>

<!-- The try-button class is styled in feature-banner -->
<ng-template #userResearchButtonTemplate>
    <a
        class="btn btn-primary try-button"
        href="https://calendly.com/d/cpj2-tv3-3h5*"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - User Research sign up"
        [angularticsLabel]="notification.title"
    >
        Sign up
    </a>
</ng-template>
