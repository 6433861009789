import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { OpportunityQuote } from '../models/opportunity-quote';
import { SortParams } from '../interfaces/list-params';
import { forkJoin, map, Observable, switchMap, throwError } from 'rxjs';
import { IRestResponse } from '../interfaces/collection';

const QUOTE_MANAGMENT_ENDPOINT = '/rest/v1/quote-management';
const BULK_DOWNLOAD_ENDPOINT = QUOTE_MANAGMENT_ENDPOINT + '/bulk-download';

export interface QuoteDownloadLink {
    quote_id: string;
    download_link: string;
}

interface QuoteBlob {
    id: string;
    blob: Blob;
}

const MAX_FILE_SIZE_IN_BYTES = 500 * 1024 * 1024; // 500MiB

@Injectable({ providedIn: 'root' })
export class OpportunityQuoteService extends GenericService<OpportunityQuote> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: OpportunityQuote,
            endpoint: QUOTE_MANAGMENT_ENDPOINT,
            defaultParams: {
                pageStart: 0,
                pageSize: 25,
                sort: <SortParams>{
                    key: 'name',
                    order: 'asc',
                },
            },
        });
    }

    accept(opportunityQuote: OpportunityQuote): Observable<void> {
        return this.http.post<void>(`${QUOTE_MANAGMENT_ENDPOINT}/accept`, {
            quoteId: opportunityQuote.id,
        });
    }

    requestChanges(opportunityQuote: OpportunityQuote, changes: string): Observable<void> {
        return this.http.post<void>(`${QUOTE_MANAGMENT_ENDPOINT}/request-modification`, {
            quoteId: opportunityQuote.id,
            comment: changes,
        });
    }

    getDownloadLink(quotes: OpportunityQuote[]): Observable<QuoteDownloadLink[]> {
        return this.http
            .get<IRestResponse<QuoteDownloadLink>>(BULK_DOWNLOAD_ENDPOINT, {
                observe: 'response',
                params: {
                    ids: quotes.map(quote => quote.id).join(','),
                },
            })
            .pipe(map(response => response.body?.items || []));
    }

    getDownloadLinksAsBlobs(quotes: OpportunityQuote[]): Observable<QuoteBlob[]> {
        return this.http
            .get<IRestResponse<QuoteDownloadLink>>(BULK_DOWNLOAD_ENDPOINT, {
                observe: 'response',
                params: {
                    ids: quotes.map(quote => quote.id).join(','),
                },
            })
            .pipe(
                switchMap(response =>
                    forkJoin(
                        (response.body?.items || [])
                            .filter(link => link?.download_link)
                            .map(link => {
                                return this.http.get(link.download_link, { responseType: 'blob' }).pipe(
                                    map(blob => {
                                        return {
                                            id: link.quote_id,
                                            blob,
                                        } as QuoteBlob;
                                    }),
                                );
                            }),
                    ),
                ),
            );
    }

    uploadPurchaseOrder(quote: OpportunityQuote, file: File): Observable<Object> {
        if (file.size >= MAX_FILE_SIZE_IN_BYTES) {
            return throwError(() => 'File too large');
        }

        const payload = new FormData();
        payload.append('file', file, file.name);
        const json = JSON.stringify({
            quote_id: quote.id,
            sales_flow_id: quote.salesFlowId,
        });
        payload.append('request', new Blob([json], { type: 'application/json' }));

        return this.http.post(`${QUOTE_MANAGMENT_ENDPOINT}/upload-po`, payload);
    }
}
