import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { SupportContractArrayInfo } from './service-catalog-quote';
import { SfdcPurchaseOrder } from './sfdc-purchase-order';

export enum QuoteStatus {
    ACTION_REQUIRED = 'Action Required', // Rename from Open
    OPEN = 'Open',
    ACCEPTED = 'Accepted',
    RENEWED = 'Renewed',
    EXPIRED = 'Expired',
    CHANGE_REQUESTED = 'Change Requested',
    PO_APPROVED = 'PO Approved',
    PO_REJECTED = 'PO Rejected',
    PO_UPLOADED = 'PO Uploaded',
    ORDER_BOOKED = 'Order Booked',
}

export class OpportunityQuote implements Resource {
    readonly id: string;
    readonly name: string;
    readonly salesFlowId: string;
    readonly opportunityId: string;
    readonly applianceNames: string[];
    readonly orgName: string;
    readonly status: QuoteStatus;
    readonly createdDate: moment.Moment;
    readonly expirationDate: moment.Moment;
    readonly createdBy: string;
    readonly netPrice: number;
    readonly termInMonth: number;
    readonly hasPDF: boolean;
    readonly serialNumbers: string[];
    readonly currency: string;
    readonly renewalDate: moment.Moment;
    readonly arrayInfos: SupportContractArrayInfo[];
    readonly partnerComment: string;
    readonly customerAccountName: string;
    readonly purchaseOrders: SfdcPurchaseOrder[];

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.salesFlowId = json.sales_flow_id;
        this.opportunityId = json.opportunity_id;
        this.orgName = json.org_name;
        this.status = json.status;
        this.createdDate = json.created_date ? moment.utc(json.created_date) : null;
        this.expirationDate = json.expiration_date ? moment.utc(json.expiration_date) : null;
        this.createdBy = json.created_by;
        this.netPrice = json.net_price;
        this.termInMonth = json.term_in_month;
        this.hasPDF = json.has_pdf;
        this.currency = json.currency || 'USD';
        this.partnerComment = json.partner_comment;
        this.customerAccountName = json.customer_account_name;

        this.arrayInfos = (json.support_contract_array_infos || []).map(info => new SupportContractArrayInfo(info));
        this.applianceNames = this.arrayInfos.map(info => info.array_name);
        this.serialNumbers = this.arrayInfos.map(info => info.chassis_serial_number) || [];
        this.renewalDate = this.arrayInfos[0]?.expiration_date;
        this.purchaseOrders = (json.purchase_orders || []).map(po => new SfdcPurchaseOrder(po));
    }
}
