import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Injectable({ providedIn: 'root' })
export class CopilotOnboardingService {
    modalVisible$ = new BehaviorSubject<boolean>(false);

    private DO_NOT_SHOW_COPILOT_ONBOARDING_MODAL = 'do-not-show-copilot-onboarding-modal';

    constructor(private storageService: StorageService) {}

    ackOnboardingModal(): void {
        this.hideOnboardingModal();
        this.storageService.set(this.DO_NOT_SHOW_COPILOT_ONBOARDING_MODAL, true);
    }

    shouldShowOnboardingModal(): boolean {
        return !this.storageService.get(this.DO_NOT_SHOW_COPILOT_ONBOARDING_MODAL);
    }

    showOnboardingModal(): void {
        this.modalVisible$.next(true);
    }

    hideOnboardingModal(): void {
        this.modalVisible$.next(false);
    }

    getSuggestionKeywordFromUrl(url: string): string {
        if (url.includes('security')) {
            return 'security';
        }
        if (url.includes('appliances')) {
            return 'performance';
        }
        if (url.includes('performance')) {
            return 'performance';
        }
        if (url.includes('orders')) {
            return 'subscription';
        }
        if (url.includes('subscriptions')) {
            return 'subscription';
        }
        return null;
    }
}
