import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ButtonComponent, ContentDialogComponent, SvgDirective } from '@pure/hive';
import { CopilotOnboardingService } from '@pure1/data';
import { Router } from '@angular/router';
import { ampli } from 'core/src/ampli';

@Component({
    templateUrl: './copilot-onboarding-modal.component.html',
    selector: 'copilot-onboarding-modal',
    standalone: true,
    imports: [ContentDialogComponent, SvgDirective, ButtonComponent],
})
export class CopilotOnboardingModalComponent implements AfterViewInit {
    @ViewChild('copilotOnboardingModalDialog') copilotOnboardingModalDialog: ContentDialogComponent;

    constructor(
        private copilotOnboardingService: CopilotOnboardingService,
        private router: Router,
    ) {}

    ngAfterViewInit(): void {
        this.copilotOnboardingService.modalVisible$.subscribe(modalVisible => {
            if (modalVisible) {
                this.copilotOnboardingModalDialog?.show();
            } else {
                this.copilotOnboardingModalDialog?.hide();
            }
        });
    }

    protected dismissModalDialog(): void {
        ampli.copilotOnboardingModalButtonClicked({ onboardingButtonClicked: 'maybe later' });
        this.copilotOnboardingService.hideOnboardingModal();
    }

    protected ackOnboardingModal(): void {
        ampli.copilotOnboardingModalButtonClicked({ onboardingButtonClicked: 'try now' });
        this.copilotOnboardingService.ackOnboardingModal();
        this.router.navigate(['/copilot']);
    }
}
