import { Injectable } from '@angular/core';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import { FeatureFlagDxpService } from '@pure1/data';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FeatureNames, PureArray } from '../../model/model';
import { severity, SEVERITY_LEAST_SEVERE } from '../../utils/severity';

export type SortRuleFunc<T> = (item: T) => string | number;

export type AssetSortRuleId =
    | 'alertSeverity'
    | 'bandwidth'
    | 'capacity'
    | 'contract_expiration'
    | 'dataReduction'
    | 'dataStores'
    | 'deduped'
    | 'hostname'
    | 'iops'
    | 'mirroredWriteLatency'
    | 'model'
    | 'otherLatency'
    | 'percFull'
    | 'protocol'
    | 'provisioned'
    | 'readBandwidth'
    | 'readLatency'
    | 'used'
    | 'version'
    | 'writeBandwidth'
    | 'writeLatency'
    | 'optGroupAppliance'
    | 'optGroupPerformance'
    | 'optGroupCapacity'
    | 'safeModeStatus'
    | 'eol'
    | 'instantCapacity';

export interface SortRule<T> {
    id: AssetSortRuleId;

    /** User-friendly display name */
    label: string;

    /**
     * Functions to get the values to sort by. If there are multiple functions,
     * sorting should be applied in the order they are provided (eg SORT BY 1,2,3).
     */
    valueFuncs: SortRuleFunc<T>[];
    isOptGroup?: boolean;
}

export interface SortRules<T> {
    rules: SortRule<T>[];

    /**
     * A final sort to always apply after the end of the default sorting to provide a deterministic
     * order for when there are duplicate values in the sort function.
     * This should be something logical to the user, like the item's name.
     */
    finalSort: SortRuleFunc<T>;
}

@Injectable({ providedIn: 'root' })
export class AssetSortRules {
    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    static getFirstSelectableRuleForArraySort(sortRules: SortRules<PureArray>): SortRule<PureArray> {
        if (!sortRules?.rules || sortRules.rules.length < 1) {
            return null;
        }

        return sortRules.rules.find(rule => !rule.isOptGroup);
    }

    getArraySort(getAlertsMap: () => Map<string, IAlert[]>): SortRules<PureArray> {
        /** Gets the alerts for an array. Will always return non-null. */
        const getAlerts = (array: PureArray): IAlert[] => {
            const alertsMap = getAlertsMap();
            return alertsMap?.get(array.arrayId) || [];
        };

        return {
            rules: [
                {
                    id: 'optGroupAppliance',
                    label: 'Appliance',
                    valueFuncs: [],
                    isOptGroup: true,
                },
                {
                    id: 'hostname',
                    label: 'Name',
                    valueFuncs: [array => array.hostname.toLowerCase()],
                },
                {
                    id: 'model',
                    label: 'Model',
                    valueFuncs: [array => array.model.toLowerCase()],
                },
                {
                    id: 'version',
                    label: 'Version',
                    valueFuncs: [
                        array => this.versionValue(array.version),
                        array => this.versionAppended(array.version),
                    ],
                },
                {
                    id: 'protocol',
                    label: 'Protocol',
                    valueFuncs: [array => array.protocolString],
                },
                {
                    id: 'alertSeverity',
                    label: 'Alert Severity',
                    valueFuncs: [
                        array => this.getHighestSeverity(getAlerts(array)) * -1, // 0 = most severe, so reverse the order by default
                        array => this.getLatestAlertOfHighestSeverity(getAlerts(array)), // For arrays of same severity, order by most recent alert date first
                    ],
                },
                {
                    id: 'contract_expiration',
                    label: 'Evergreen Subscription Expiration',
                    valueFuncs: [
                        array => (array.contract_expiration ? array.contract_expiration.valueOf() : 32503680000000),
                    ], // is midnight 1/1/3000
                },
                {
                    id: 'safeModeStatus',
                    label: 'SafeMode Status',
                    valueFuncs: [array => array.safe_mode_status_weight],
                },
                {
                    id: 'optGroupPerformance',
                    label: 'Performance',
                    valueFuncs: [],
                    isOptGroup: true,
                },
                {
                    id: 'bandwidth',
                    label: 'Bandwidth',
                    valueFuncs: [array => (array.performanceData ? array.performanceData.bandwidth : 0)],
                },
                {
                    id: 'iops',
                    label: 'IOPS',
                    valueFuncs: [array => (array.performanceData ? array.performanceData.iops : 0)],
                },
                {
                    id: 'readLatency',
                    label: 'R Latency',
                    valueFuncs: [array => (array.performanceData ? array.performanceData.readLatency : 0)],
                },
                {
                    id: 'writeLatency',
                    label: 'W Latency',
                    valueFuncs: [array => (array.performanceData ? array.performanceData.writeLatency : 0)],
                },
                {
                    id: 'mirroredWriteLatency',
                    label: 'MW Latency',
                    valueFuncs: [
                        array =>
                            array.performanceData
                                ? typeof array.performanceData.mirroredWriteLatency !== 'number'
                                    ? -2
                                    : array.performanceData.mirroredWriteLatency
                                : -1,
                    ],
                },
                {
                    id: 'otherLatency',
                    label: 'O Latency',
                    valueFuncs: [
                        array =>
                            array.performanceData
                                ? typeof array.performanceData.otherLatency !== 'number'
                                    ? -2
                                    : array.performanceData.otherLatency
                                : -1,
                    ],
                },
                {
                    id: 'optGroupCapacity',
                    label: 'Capacity',
                    valueFuncs: [],
                    isOptGroup: true,
                },
                {
                    id: 'percFull',
                    label: '% Full',
                    valueFuncs: [array => (array.capacityData ? array.capacityData.percFull : 0)],
                },
                {
                    id: 'dataReduction',
                    label: 'Data Reduction',
                    valueFuncs: [array => (array.capacityData ? array.capacityData.dataReduction : 0)],
                },
                {
                    id: 'provisioned',
                    label: 'Provisioned',
                    valueFuncs: [array => (array.capacityData ? array.capacityData.provisioned : 0)],
                },
                {
                    id: 'capacity',
                    label: 'Total Usable',
                    valueFuncs: [array => (array.capacityData ? array.capacityData.capacity : 0)],
                },
            ],

            finalSort: array => array.hostname.toLowerCase(),
        };
    }

    getArraySortWithFlags(getAlertsMap: () => Map<string, IAlert[]>): Observable<SortRules<PureArray>> {
        const rules: SortRules<PureArray> = this.getArraySort(getAlertsMap);

        const featureFilterConfiguration = {
            [FeatureNames.HW_EOL]: {
                add_rule: (rules: SortRule<PureArray>[], rule: SortRule<PureArray>) => rules.splice(1, 0, rule),
                rule: {
                    id: 'eol',
                    label: 'End of Life Status',
                    valueFuncs: [(array: PureArray) => (array.has_end_of_life_hardware ? 0 : 1)],
                },
            },
            [FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE]: {
                add_rule: (rules: SortRule<PureArray>[], rule: SortRule<PureArray>) => rules.push(rule),
                rule: {
                    id: 'instantCapacity',
                    label: 'Instant Capacity',
                    valueFuncs: [
                        (array: PureArray) =>
                            array.capacityData?.capacityExpandable
                                ? array.capacityData
                                    ? array.capacityData.instantCapacity
                                    : 0
                                : 0,
                    ],
                },
            },
        };

        return forkJoin({
            [FeatureNames.HW_EOL]: this.featureFlagDxpService.getFeatureFlag(FeatureNames.HW_EOL),
            [FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE]: this.featureFlagDxpService.getFeatureFlag(
                FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE,
            ),
        })
            .pipe(take(1))
            .pipe(
                map(response => {
                    const features: Map<FeatureNames, FeatureFlagStatus> = new Map<FeatureNames, FeatureFlagStatus>(
                        Object.entries(response) as [FeatureNames, FeatureFlagStatus][],
                    );

                    for (const featureName of features.keys()) {
                        const feature: FeatureFlagStatus = features.get(featureName);
                        if (feature?.enabled) {
                            featureFilterConfiguration[featureName].add_rule(
                                rules.rules,
                                featureFilterConfiguration[featureName].rule,
                            );
                        }
                    }

                    return rules;
                }),
            );
    }

    /**
     * Gets the value of the highest severity alert on an array.
     * Returns the lowest severity value (SEVERITY_LEASE_SEVERE) if no alerts.
     */
    private getHighestSeverity(alerts: IAlert[]): number {
        return (alerts || [])
            .map(alert => severity(alert.currentSeverity).value)
            .reduce((a, b) => Math.min(a, b), SEVERITY_LEAST_SEVERE);
    }

    /**
     * Gets the most recent date of an alert that is of the highest severity in the set.
     */
    private getLatestAlertOfHighestSeverity(alerts: IAlert[]): number {
        const highestSeverity = this.getHighestSeverity(alerts);
        return alerts
            .filter(alert => severity(alert.currentSeverity).value === highestSeverity)
            .map(alert => (alert.updated ? alert.updated.valueOf() : 0))
            .reduce((a, b) => b - a, 0); // Reverse order
    }

    // 1.3.6.alpha.8 will be valued at 1003006
    private versionValue(version = '99.99.99'): number {
        return version
            .split('.') // split the string with .
            .slice(0, 3) // Keep only the first 3 numbers as string
            .map(s => Number(s)) // convert them to numbers
            .reduce((prev, cur) => prev * 1000 + cur); // Accumulate
    }

    // 1.3.6.alpha.8 return 'alpha.8'
    private versionAppended(version = '99.99.99'): string {
        const parts = version.split('.', 4);
        return parts.length === 4 ? parts[3] : '';
    }
}
