import { Pipe, PipeTransform } from '@angular/core';
import { IMainTab, ITab } from '../../services/tabs/tabs.service';

/**
 * Gets the unique, DOM-friendly element id to use for main menu tabs
 */
@Pipe({ name: 'navMenuId', pure: true })
export class NavMenuIdPipe implements PipeTransform {
    transform(tab: ITab | IMainTab): string {
        if (!tab) {
            return undefined;
        }

        let name = tab.path || tab.title; // All tabs probably have paths, but in case they don't, use the title
        name = name.replace(/[^a-z0-9]/gi, ''); // Remove special characters

        const prefix = (<IMainTab>tab).subTabs ? 'tab-' : 'subtab-'; // Ensure the id differs between maintab/subtab just in case they have same href

        return prefix + name;
    }
}
