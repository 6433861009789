import { Component, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';

import { BaseBanner } from '../base-banner';
import { UserNotification } from '../../model/user-notification';

@Component({
    selector: 'user-research-banner',
    templateUrl: 'user-research-banner.component.html',
})
export class UserResearchBannerComponent extends BaseBanner {
    @Output() readonly dismissNotification = new EventEmitter<UserNotification>();

    populatedNotification: UserNotification = new UserNotification({});

    @ViewChild('userResearchButtonTemplate', { static: true })
    readonly userResearchButtonTemplate: TemplateRef<any>;

    ngOnInit(): void {
        const templates = { buttonTemplate: this.userResearchButtonTemplate };
        this.populatedNotification = { ...this.notification, ...templates };
    }
}
