<section class="quote-table-container">
    <hive-table
        dataKey="id"
        [scrollable]="true"
        scrollHeight="calc(100% - 50px)"
        [value]="data"
        [selection]="selectedQuotes"
        selectionMode="multiple"
        [isLoading]="isLoading"
        (selectionChange)="onSelectionChange($event.data)"
        (sortChange)="onSortChanged($event)"
    >
        <div class="table-top-actions">
            <div class="actions-left-section">
                <pure-entity-selection-dropdown
                    entityName="Selections"
                    [isSelectable]="false"
                    [entities]="selectedQuotes"
                    (entitiesChange)="onSelectionChange($event)"
                >
                </pure-entity-selection-dropdown>
                <button
                    class="bulk-download-button action-button"
                    [disabled]="selectedQuotes.length === 0"
                    (click)="onBulkDownloadClick()"
                    [ngbTooltip]="getDownloadTooltipText(selectedQuotes.length === 0)"
                    placement="top"
                    container="body"
                >
                    <svg hive-svg="bulk-download.svg" [height]="18"></svg>
                </button>
                <export-button
                    class="export-btn"
                    [showNewIcon]="true"
                    [showText]="false"
                    (updateCounts)="updateExportOptionCounts($event.optionsMap)"
                    [exportOptions]="listViewExportOptions"
                    (onClickExport)="onClickExport($event.option)"
                >
                </export-button>
            </div>
            <div class="actions-right-section">
                <section class="time-filter">
                    <quote-management-range-filters
                        [selectedRangeFilters]="selectedRangeFilters"
                        (selectedRangeFiltersChange)="onSelectTimeRangeFilters($event)"
                    >
                    </quote-management-range-filters>
                </section>
                <div class="vertical-bar"></div>
                <hive-pagination
                    [itemsPerPage]="itemsPerPage"
                    [offset]="offset"
                    [totalItemCount]="totalItemCount"
                    [pageSizeDropdownVisible]="true"
                    [itemsPerPageOptions]="pageSizes"
                    (itemsPerPageChange)="onItemsPerPageChange($event)"
                    (pageChange)="onPageChange($event)"
                >
                </hive-pagination>
                <div class="vertical-bar"></div>
                <hive-column-toggle></hive-column-toggle>
            </div>
        </div>
        <ng-template hiveTemplate="header">
            <tr>
                <th
                    hive-template-header
                    [resizable]="true"
                    colWidth="40px"
                    id="checkboxCol"
                    visibilityMode="column-toggle-ignored"
                    rowspan="2"
                >
                    <div class="header-checkbox-container">
                        <hive-table-header-checkbox></hive-table-header-checkbox>
                    </div>
                </th>
                <th
                    hive-header
                    [resizable]="true"
                    [colWidth]="QUOTE_NUMBER_WIDTH"
                    id="name"
                    sortKey="name"
                    primaryText="Quote Number"
                    visibilityMode="column-toggle-disabled"
                >
                </th>
                <th hive-header [resizable]="true" [colWeight]="4" id="appliance_names" primaryText="Appliance Names">
                </th>
                <th
                    hive-header
                    [resizable]="true"
                    [colWeight]="3"
                    id="customer_account_name"
                    sortKey="customer_account_name"
                    primaryText="Customer Name"
                >
                </th>
                <th
                    hive-header
                    [resizable]="true"
                    colWidth="140px"
                    id="status"
                    sortKey="status"
                    primaryText="Quote Status"
                >
                </th>
                <th hive-header [resizable]="true" [colWidth]="DATE_WIDTH" id="renewalDate" primaryText="Renewal Date">
                </th>
                <th
                    hive-header
                    [resizable]="true"
                    [colWidth]="SERIAL_NUMBER_WIDTH"
                    id="serialNumber"
                    primaryText="Serial Numbers"
                >
                </th>
                <th
                    hive-header
                    [resizable]="true"
                    [colWidth]="DATE_WIDTH"
                    id="creationDate"
                    sortKey="created_date"
                    primaryText="Quote Creation Date"
                >
                </th>
                <!-- <th
                    hive-header
                    [resizable]="true"
                    [colWidth]="DATE_WIDTH"
                    id="expirationDate"
                    sortKey="expiration_date"
                    primaryText="Quote Expiration Date"
                >
                </th> -->
                <th
                    hive-header
                    [resizable]="true"
                    [colWidth]="PREPARED_BY_WIDTH"
                    id="preparedBy"
                    sortKey="created_by"
                    primaryText="Prepared By"
                >
                </th>
                <th
                    hive-header
                    [resizable]="true"
                    [colWidth]="CONTRACT_TERM_WIDTH"
                    id="contractTerms"
                    sortKey="term_in_month"
                    primaryText="Contract Terms (Months)"
                >
                </th>
                <th
                    hive-header
                    resizable
                    [colWidth]="TOTAL_AMOUNT_WIDTH"
                    id="netPrice"
                    sortKey="net_price"
                    [containerHorizontalAlignment]="'right'"
                    primaryText="Total Amount"
                >
                </th>
                <th
                    hive-header
                    [resizable]="true"
                    [colWeight]="1"
                    hiveFrozenColumn
                    alignFrozen="right"
                    visibilityMode="column-toggle-ignored"
                    id="actions"
                >
                </th>
            </tr>
        </ng-template>
        <ng-template hiveTemplate="body" let-quote>
            <tr [hiveTableRow]="quote" selectable>
                <td hive-template-cell>
                    <div class="checkbox">
                        <hive-table-checkbox></hive-table-checkbox>
                    </div>
                </td>
                <td hive-template-cell>
                    <div class="inline-actions">
                        <ng-container *ngIf="quote.hasPDF; else noLink">
                            <a (click)="onQuoteAction(quoteClick, quote, $event)">
                                {{ quote.name }}
                            </a>
                        </ng-container>
                        <ng-template #noLink>
                            {{ quote.name }}
                        </ng-template>
                        <hive-button
                            [ngClass]="{ disabled: ([ACCEPT_ACTION] | filterQuoteActions: quote).length === 0 }"
                            emphasis="tertiary"
                            [disabled]="([ACCEPT_ACTION] | filterQuoteActions: quote).length === 0"
                            icon="fa fa-check"
                            hiveTooltip
                            hiveTooltipDescription="Accept Quote"
                            (click)="onQuoteAction(quoteAccept, quote, $event)"
                        >
                        </hive-button>
                        <hive-button
                            emphasis="tertiary"
                            [ngClass]="{
                                disabled: ([REQUEST_CHANGE_ACTION] | filterQuoteActions: quote).length === 0
                            }"
                            [disabled]="([REQUEST_CHANGE_ACTION] | filterQuoteActions: quote).length === 0"
                            icon="fa fa-pencil"
                            hiveTooltip
                            hiveTooltipDescription="Request Change"
                            (click)="onQuoteAction(quoteRequestChanges, quote, $event)"
                        >
                        </hive-button>
                        <hive-button
                            emphasis="tertiary"
                            [ngClass]="{
                                disabled: ([PO_UPLOAD_ACTION] | filterQuoteActions: quote).length === 0
                            }"
                            [disabled]="([PO_UPLOAD_ACTION] | filterQuoteActions: quote).length === 0"
                            icon="arrow-up-cloud-no-fill.svg"
                            hiveTooltip
                            hiveTooltipDescription="Upload Purchase Order"
                            (click)="onQuoteAction(quotePOUpload, quote, $event)"
                        >
                        </hive-button>
                    </div>
                </td>
                <td
                    hive-cell
                    class="text-tooltip"
                    hiveTooltip
                    [hiveTooltipDescription]="quote.applianceNames | list | defaultString"
                    [primaryText]="quote.applianceNames | list | defaultString"
                >
                </td>
                <td
                    hive-cell
                    [primaryTextTooltip]="quote.customerAccountName"
                    [primaryText]="quote.customerAccountName | defaultString"
                >
                </td>
                <td class="status-cell" hive-template-cell>
                    <div class="status-wrapper" [hiveTooltip]="getStatusTooltipText(quote.status)">
                        {{ quote.status | defaultString }}
                        <i
                            *ngIf="quote.status === QuoteStatus.ORDER_BOOKED"
                            class="fa fa-arrow-circle-o-right"
                            routerLink="/services/servicecatalog/orders"
                            [queryParams]="getQueryParamsForOrders(quote)"
                        ></i>
                    </div>
                </td>
                <td hive-cell [primaryText]="quote.renewalDate | date: 'yyyy-MM-dd' : 'UTC'"> </td>
                <td
                    hive-cell
                    [primaryTextTooltip]="quote.serialNumbers | list"
                    [primaryText]="quote.serialNumbers | list | defaultString"
                >
                </td>
                <td hive-cell [primaryText]="quote.createdDate | date: 'yyyy-MM-dd' : 'UTC'"> </td>
                <!-- <td hive-cell [primaryText]="quote.expirationDate | date: 'yyyy-MM-dd' : 'UTC'"> </td> -->
                <td
                    hive-cell
                    hiveTooltip
                    [hiveTooltipDescription]="quote.createdBy | defaultString"
                    [primaryText]="quote.createdBy | defaultString"
                >
                </td>
                <td hive-cell [primaryText]="quote.termInMonth | defaultString"> </td>
                <td
                    hive-cell
                    hiveTooltip
                    [hiveTooltipDescription]="quote.netPrice | currency: quote.currency"
                    [primaryText]="quote.netPrice | currency: quote.currency"
                    [containerHorizontalAlignment]="'right'"
                >
                </td>
                <td hive-template-cell hiveFrozenColumn alignFrozen="right">
                    <hive-menu position="left">
                        <hive-button
                            hiveMenuButton
                            icon="menu-slim.svg"
                            emphasis="tertiary"
                            [caret]="false"
                            (click)="$event.stopPropagation()"
                        >
                        </hive-button>
                        <hive-menu-item
                            *ngFor="let quoteAction of quoteActions | filterQuoteActions: quote"
                            [label]="quoteAction.label"
                            (click)="quoteAction.action(quote)"
                        >
                        </hive-menu-item>
                    </hive-menu>
                </td>
            </tr>
        </ng-template>
    </hive-table>
</section>

<ng-template #quoteExportModal let-modal>
    <quote-export-modal
        [modal]="modal"
        [quoteFilter]="quoteFilter"
        [totalFiltered]="totalItemCount"
        [totalUnfiltered]="totalUnfiltered"
        [selectedOption]="selectedExportOption"
    >
    </quote-export-modal>
</ng-template>
