import { Resource } from '../interfaces/resource';
import { ProgramType } from './subscription';

export class SustainabilityArray implements Resource {
    id: string;
    applianceId: string;
    apartmentId: string;
    dataTimestamp: number;
    name: string;
    purityVersion?: string;
    model?: string;
    notPhoningHome: boolean;
    notEnoughData: boolean;
    numberOfShelves?: number;
    numberOfRackUnits?: number;
    numberOfChassis?: number;
    numberOfBlades?: number;
    capacityUtilisationPercentageAverage?: number;
    dataReductionRatioAverage?: number;
    loadPercentageAverage?: number;
    effectiveUsedTiBAverage?: number;
    power: SustainabilityMetric;
    heat: SustainabilityMetric;
    wattsOverTotalEffectiveUsedTiB: SustainabilityMetric;
    wattsOverTotalUsableTiB: SustainabilityMetric;
    coordinates: Coordinates;
    address?: string;
    dataCenterId?: string;
    applianceType: ApplianceType;
    wattHourAnnualEnergyConsumption?: number;
    wattHourCompetitiveAnnualEnergyConsumption?: number;
    annualEmissionsKg?: number;
    insights: SustainabilityInsight[];
    assessmentLevel: SustainabilityArrayAssessmentLevel;
    unsupportedPurityVersion: boolean;
    /**
     * [wattsOverEffectiveTiB] is power over EUC (effective used capacity) for E//One or E//Flex arrays (paas arrays) or
     * [wattsOverEffectiveTiB] is power over effective used space for Capex arrays (on premise arrays).
     */
    wattsOverEffectiveTiB: SustainabilityMetric;
    /**
     * [effectiveTiBAverage] is EUC (effective used capacity) for E//One or E//Flex arrays (paas arrays) or
     * [effectiveTiBAverage] is effective used space for Capex arrays (on premise arrays).
     */
    effectiveTiBAverage?: number;
    /**
     * Effective used space and effective used capacity should be closed.
     * [divergenceOfEffectiveTib] expresses significant divergence between effective used space and effective used capacity.
     * The divergence has an impact on [wattsOverEffectiveTiB] and [effectiveTiBAverage].
     */
    divergenceOfEffectiveTib: boolean;
    usableSpaceTibAverage: number;
    license?: SustainabilityLicense;
    numberOfSeedlings?: number;
    assessmentLatest: boolean;
    assessmentTimestamp: number;

    constructor(response: any) {
        this.applianceId = response.appliance_id;
        this.apartmentId = response.apartment_id;
        this.dataTimestamp = response.data_timestamp;
        this.name = response.name ?? '';
        this.purityVersion = response.purity_version;
        this.model = response.model;
        this.notPhoningHome = response.not_phoning_home;
        this.notEnoughData = response.not_enough_data;
        this.numberOfShelves = response.number_of_shelves;
        this.numberOfRackUnits = response.number_of_rack_units;
        this.numberOfChassis = response.number_of_chassis;
        this.numberOfBlades = response.number_of_blades;
        this.capacityUtilisationPercentageAverage = response.capacity_utilisation_percentage_average;
        this.dataReductionRatioAverage = response.data_reduction_ratio_average;
        this.loadPercentageAverage = response.load_percentage_average;
        this.effectiveUsedTiBAverage = response.effective_used_tib_average;
        this.power = new SustainabilityMetric(response.power);
        this.heat = new SustainabilityMetric(response.heat);
        this.wattsOverTotalEffectiveUsedTiB = new SustainabilityMetric(response.watts_over_total_effective_used_tib);
        this.wattsOverTotalUsableTiB = new SustainabilityMetric(response.watts_over_total_usable_tib);
        this.coordinates = new Coordinates(response.coordinates);
        this.address = response.address;
        this.dataCenterId = response.data_center_id;
        this.applianceType = response.appliance_type;
        this.wattHourAnnualEnergyConsumption = response.watt_hour_annual_energy_consumption;
        this.wattHourCompetitiveAnnualEnergyConsumption = response.watt_hour_competitive_annual_energy_consumption;
        this.annualEmissionsKg = response.annual_emissions_kg;
        this.insights = response.insights.map(insight => new SustainabilityInsight(insight));
        this.assessmentLevel = response.assessment_level;
        this.unsupportedPurityVersion = response.unsupported_purity_version;
        this.wattsOverEffectiveTiB = new SustainabilityMetric(response.watts_over_effective_tib);
        this.effectiveTiBAverage = response.effective_tib_average;
        this.divergenceOfEffectiveTib = response.divergence_of_effective_tib;
        this.usableSpaceTibAverage = response.usable_space_tib_average;
        this.license = response.license && new SustainabilityLicense(response.license);
        this.numberOfSeedlings = response.number_of_seedlings;
        this.assessmentLatest = response.assessment_latest;
        this.assessmentTimestamp = response.assessment_timestamp;

        //Just for satisfying the Resource interface
        this.id = response.appliance_id;
    }

    static doesProvideData(array: SustainabilityArray): boolean {
        return !(array.notPhoningHome || array.unsupportedPurityVersion || array.notEnoughData);
    }
}

export class SustainabilityMetric {
    nominal?: number;
    peak?: number;
    actualAverage?: number;
    boundedAverage?: number;
    isOverThreshold?: boolean;
    trend?: HistoricalTrend;
    unreliableValue?: boolean;

    constructor(response: any) {
        this.nominal = response.nominal;
        this.peak = response.peak;
        this.actualAverage = response.actual_average;
        this.boundedAverage = response.bounded_average;
        this.isOverThreshold = response.is_over_threshold;
        this.trend = response.trend;
        this.unreliableValue = response.unreliable_value;
    }
}

export class Coordinates {
    latitude?: number;
    longitude?: number;

    constructor(response: any) {
        this.latitude = response.latitude;
        this.longitude = response.longitude;
    }
}

export enum ApplianceType {
    FA = 'FA',
    FB = 'FB',
}

export enum HistoricalTrend {
    INCREASE = 'INCREASE',
    DECREASE = 'DECREASE',
    STABLE = 'STABLE',
}

export enum SustainabilityInsightType {
    EOL_MODEL = 'EOL_MODEL',
    SAS_SHELVES_REPLACEMENT_REQUIRED = 'SAS_SHELVES_REPLACEMENT_REQUIRED',
    ACTUAL_POWER_ALMOST_AT_PEAK = 'ACTUAL_POWER_ALMOST_AT_PEAK',
    ACTUAL_POWER_OVER_THE_PEAK = 'ACTUAL_POWER_OVER_THE_PEAK',
    NOT_ENOUGH_WORKLOADS = 'NOT_ENOUGH_WORKLOADS',
}

export enum SustainabilityInsightSeverity {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export enum SustainabilityArrayAssessmentLevel {
    GOOD = 'GOOD',
    RECOMMENDATION = 'RECOMMENDATION',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    NOT_PHONING_HOME = 'NOT_PHONING_HOME',
    UNSUPPORTED_PURITY_VERSION = 'UNSUPPORTED_PURITY_VERSION',
    NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
}

export class SustainabilityInsight {
    type: SustainabilityInsightType;
    severity: SustainabilityInsightSeverity;
    data: Record<string, unknown>;

    constructor({ type, severity, data }: any) {
        this.type = type;
        this.severity = severity;
        this.data = data;
    }
}

export class SustainabilityLicense {
    licenseId?: string;
    licenseName?: string;
    subscription: SustainabilitySubscription;

    constructor(response: any) {
        this.licenseId = response.license_id;
        this.licenseName = response.license_name;
        this.subscription = response.subscription ? new SustainabilitySubscription(response.subscription) : null;
    }
}

export class SustainabilitySubscription {
    subscriptionId?: string;
    subscriptionName?: string;
    subscriptionType?: SustainabilitySubscriptionType;

    constructor(response: any) {
        this.subscriptionId = response.subscription_id;
        this.subscriptionName = response.subscription_name;
        this.subscriptionType = response.subscription_type;
    }
}

type SustainabilitySubscriptionType =
    | ProgramType.EVERGREEN_ONE
    | ProgramType.EVERGREEN_FLEX
    | ProgramType.EVERGREEN_FOREVER;

export class SustainabilityFleetData implements Resource {
    id: string;
    name: string;
    totalNumberOfAppliances?: number;
    wattsOverTotalUsableTiB: TotalFleetValue<number>;
    totalActualPower: TotalFleetValue<number>;
    wattHourAnnualEnergyConsumption: TotalFleetValue<number>;
    wattHourCompetitiveAnnualEnergyConsumption: TotalFleetValue<number>;
    annualEmissionsKg: TotalFleetValue<number>;
    numberOfSeedlings: TotalFleetValue<number>;
    organizations: OrganizationData[] = [];

    lifetimeSummary: LifetimeSummary;

    constructor(response: any) {
        this.totalNumberOfAppliances = response.total_number_of_appliances;
        this.wattsOverTotalUsableTiB = response.watts_over_total_usable_tib;
        this.totalActualPower = response.total_actual_power;
        this.wattHourAnnualEnergyConsumption = response.watt_hour_annual_energy_consumption;
        this.wattHourCompetitiveAnnualEnergyConsumption = response.watt_hour_competitive_annual_energy_consumption;
        this.annualEmissionsKg = response.annual_emissions_kg;
        this.numberOfSeedlings = response.number_of_seedlings;
        this.organizations = response.organizations?.map(org => new OrganizationData(org));
        this.lifetimeSummary = new LifetimeSummary(response.lifetime_summary);
    }
}

class LifetimeSummary {
    totalEmissions: number;
    upstreamEmissions: {
        totalNumberOfAppliances: number;
        materials: number;
        transportation: number;
        assemblyAndTesting: number;
        packaging: number;
    };
    usageEmissions: {
        totalNumberOfAppliances: number;
        directCarbonUsage: number;
        coolingCarbonUsage: number;
    };
    downstreamEmissions: {
        totalNumberOfAppliances: number;
        endOfLife: number;
    };
    co2Equivalent: {
        numberOfTreeSeedlings: number;
    };

    constructor(response: any) {
        this.totalEmissions = response?.total_emissions;
        this.upstreamEmissions = {
            totalNumberOfAppliances: response?.upstream_emissions?.total_number_of_appliances,
            materials: response?.upstream_emissions?.materials,
            transportation: response?.upstream_emissions?.transportation,
            assemblyAndTesting: response?.upstream_emissions?.assembly_and_testing,
            packaging: response?.upstream_emissions?.packaging,
        };
        this.usageEmissions = {
            totalNumberOfAppliances: response?.usage_emissions?.total_number_of_appliances,
            directCarbonUsage: response?.usage_emissions?.direct_carbon_usage,
            coolingCarbonUsage: response?.usage_emissions?.cooling_carbon_usage,
        };

        this.downstreamEmissions = {
            totalNumberOfAppliances: response?.downstream_emissions?.total_number_of_appliances,
            endOfLife: response?.downstream_emissions?.end_of_life,
        };

        this.co2Equivalent = {
            numberOfTreeSeedlings: response?.co2_equivalent?.number_of_tree_seedlings,
        };
    }
}

interface TotalFleetValue<T> {
    value: T;
    appliances: number;
}

class OrganizationData {
    orgId?: number;
    cohortId?: string;
    wattsOverTotalUsableTiB?: TotalFleetValue<number>;
    cohortMaxWattsOverTotalUsableTiB?: number;
    cohortMinWattsOverTotalUsableTiB?: number;
    totalNumberOfAppliances?: number;

    constructor(response: any) {
        this.orgId = response.org_id;
        this.cohortId = response.cohort_id;
        this.wattsOverTotalUsableTiB = response.watts_over_total_usable_tib;
        this.cohortMaxWattsOverTotalUsableTiB = response.cohort_max_watts_over_total_usable_tib;
        this.cohortMinWattsOverTotalUsableTiB = response.cohort_min_watts_over_total_usable_tib;
        this.totalNumberOfAppliances = response.total_number_of_appliances;
    }
}
