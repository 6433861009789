import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    AdditionalRole,
    AdditionalRoleAssignment,
    AdditionalUserRolesService,
    CachedCurrentUserService,
    CurrentUser,
    KnownRoleDomains,
    Organization,
    OrganizationService,
    ResetPasswordService,
    RoleAssignmentService,
    RoleType,
    StepUpService,
    User,
    UserRoleID,
    UsersService,
    ViewReference,
    ViewsService,
} from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import { exclude, when } from 'core/src/utils/form-validators';
import { isNull } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, defer, Observable, of, Subject } from 'rxjs';
import { catchError, exhaustMap, finalize, map, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';
import { MpaRequestsBadgeService } from '../../messages/mpa-requests/services/mpa-requests-badge.service';
import { FeatureNames } from '../../model/FeatureNames';
import { ToastService } from '../../services/toast.service';
import { StepUpSupportService } from '../../step-up/services/step-up-support.service';
import { PhoneResult } from '../../ui/components/input-phone.component';
import { timezoneList } from '../../utils/supportedTimezones';
import { BaseAssignmentFormComponent } from '../base-assignment-form/base-assignment-form.component';
import { hasLegacyRoleValidator } from '../helpers/has-legacy-role-validator';
import { isLegacyRole } from '../helpers/is-legacy-role';
import { shouldDisplayRole } from '../helpers/should-display-role';
import { userRoleItemsFactory } from '../helpers/user-role-items-factory';
import { UserRoleStateService } from '../services/user-role-state.service';
import { Assignment } from '../user-role-management.interface';
import { UserRoleItem } from '../user-roles-select/user-role-item';

type Timezone = { value: string; timeOffset: string };

@Component({
    selector: 'user-form',
    templateUrl: './user-form.component.html',
    host: {
        class: 'base-assignment-form',
    },
})
export class UserFormComponent extends BaseAssignmentFormComponent<User> implements OnInit, OnChanges, OnDestroy {
    @Input() readonly isCurrentUser: boolean;
    @Input() readonly currentUser: CurrentUser;

    currentOrg: Organization;
    orgList: Organization[] = []; // Not including the currentOrg
    isPure1Support = false;
    dropdownDisabledMessage: string = null;
    timezones: Timezone[];
    availableRoles: UserRoleItem[] = [];
    loadingRoles = false;
    newUser: User = null;
    warningMessage: string;

    readonly roleMap = new Map<UserRoleID, UserRoleItem>();

    readonly shouldDisplayRoleFn = shouldDisplayRole;

    get rolesControl(): AbstractControl<UserRoleID[]> {
        return this.assignmentForm.get('roles');
    }

    private validPhoneNumber = false;
    private emptyPhoneNumber = true;
    private phoneNumber: string = null;
    private readonly reset$ = new Subject<void>();

    constructor(
        usersService: UsersService,
        viewsService: ViewsService,
        roleAssignmentService: RoleAssignmentService,
        private organizationService: OrganizationService,
        resetPasswordService: ResetPasswordService,
        modalService: NgbModal,
        urStateService: UserRoleStateService,
        angulartics2: Angulartics2,
        toast: ToastService,
        mpaRequestsBadgeService: MpaRequestsBadgeService,
        private fb: UntypedFormBuilder,
        private stepUpSupportService: StepUpSupportService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private additionalUserRolesService: AdditionalUserRolesService,
        private readonly stepUpService: StepUpService,
    ) {
        super(
            viewsService,
            usersService,
            roleAssignmentService,
            urStateService,
            angulartics2,
            resetPasswordService,
            modalService,
            toast,
            mpaRequestsBadgeService,
        );

        this.assignmentForm = this.fb.group({
            roles: [
                [],
                [
                    Validators.required,
                    exclude<UserRoleID>(roleId => !this.isEdit && this.isSafeModeRole(roleId), 'smaRole'),
                    hasLegacyRoleValidator,
                ],
                [this.stepUpEnabledValidator()],
            ],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            phone: [''],
            timezone: ['', Validators.required],
            view: [''],
        });
    }

    ngOnInit(): void {
        combineLatest([this.loadAdditionalRoles(), this.reset$.pipe(startWith(0))])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([additionalRoles]) => {
                this.generateAvailableRoles(additionalRoles);
            });

        // when role is changed, update view selection
        // For admin, set the selection to null and disable the dropdown
        this.assignmentForm
            .get('roles')
            .valueChanges.pipe(takeUntil(this.reset$), takeUntil(this.destroy$))
            .subscribe(roles => {
                const legacyRole = roles.find(roleId => isLegacyRole(roleId));

                if (legacyRole === RoleType.PURE1_ADMIN || legacyRole === RoleType.PORTWORX_ADMIN) {
                    // when we change a user from regular user to admin, we set the view to null and disable the control
                    this.dropdownDisabledMessage = `${legacyRole === RoleType.PURE1_ADMIN ? 'Pure1 Admins' : 'Portworx Admins'} cannot be assigned a view.`;
                    this.assignmentForm.controls.view.setValue(null, { onlySelf: true });
                    this.assignmentForm.controls.view.disable();
                } else if (this.views) {
                    // only enable the views control if this.views has been initialized
                    this.dropdownDisabledMessage = null;
                    this.assignmentForm.controls.view.enable();
                } else {
                    // if user is not admin, and we don't have a list of views yet, then get the views
                    if (!this.isCurrentUser) {
                        this.getViews(); // and enable the views dropdown when done, if we are not the non-admin user
                    }
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.reset$.next();
        this.updateAssignmentObject(this.assignment);
        this.timezones = this.getTimezoneData();
        this.isPure1Support = this.newUser.role === RoleType.PURE1_SUPPORT;

        const roleIds: UserRoleID[] = [...this.newUser.additionalRoles.map(role => role.id)];

        if (this.newUser.role) {
            roleIds.unshift(this.newUser.role);
        }

        this.assignmentForm.patchValue({
            roles: roleIds,
            email: this.newUser.email,
            firstName: this.newUser.firstName,
            lastName: this.newUser.lastName,
            phone: this.newUser.phone,
            timezone: this.newUser.timezone,
            view: this.newUser.view,
        });

        this.assignmentForm.get('view').disable();

        if (this.isEdit) {
            this.assignmentForm.get('email').disable();
        }

        super.ngOnChanges(changes);

        if (!this.assignmentForm.value.timezone) {
            this.assignmentForm.controls.timezone.setValue(this.currentUser.timezone, { onlySelf: true });
        }

        // If it's the current user, we'll want a list of the orgs they belong to
        if (this.isCurrentUser) {
            this.urStateService
                .listWithCache<Organization>(this.organizationService)
                .pipe(
                    map(result => result.response),
                    takeUntil(this.destroy$),
                )
                .subscribe(orgs => {
                    this.currentOrg = orgs.find(org => org.orgId === this.currentUser.organization_id);
                    this.orgList = orgs.filter(org => org.orgId !== this.currentUser.organization_id);
                });
        }

        if (this.newUser.role !== RoleType.PURE1_ADMIN) {
            this.getViews();
        }
    }

    ngOnDestroy(): void {
        this.reset$.complete();
        super.ngOnDestroy();
    }

    create(): Observable<Assignment> {
        const create$ = super.create();

        // If current user updates his profile, we might need to update tabs and current user profile.
        if (this.isCurrentUser && this.isEdit) {
            create$.pipe(take(1)).subscribe(assignment => {
                if (!isNull(assignment)) {
                    // Force update current user
                    this.cachedCurrentUserService.forceRefresh();
                }
            });
        }

        return create$;
    }

    isValidForm(): boolean {
        return this.assignmentForm.valid && (this.validPhoneNumber || this.emptyPhoneNumber);
    }

    onPhoneChange(result: PhoneResult): void {
        this.validPhoneNumber = result.isValid;
        this.emptyPhoneNumber = result.isEmpty;
        this.phoneNumber = result.phoneNumber;
    }

    getLegacyRoles(): RoleType[] {
        return [
            RoleType.DASHBOARD_VIEWER,
            RoleType.VM_VIEWER,
            RoleType.PURE1_VIEWER,
            RoleType.PURE1_ADMIN,
            RoleType.PORTWORX_ADMIN,
        ];
    }

    copyAssignmentFields(newAssignment: User): void {
        newAssignment.phone = this.phoneNumber;
        if (this.isEdit) {
            // populate the not-editable fields
            const oldUser = this.assignment as User;
            newAssignment.id = oldUser.id;
            newAssignment.name = oldUser.name;
            newAssignment.email = oldUser.email;
        }
    }

    updateAssignmentObject(assignment: Assignment): void {
        if (this.isEdit) {
            this.newUser = {
                ...(assignment as User),
                role: (assignment as User)?.role || this.newUser?.role,
            };
        } else {
            this.newUser = new User({});
        }

        this.warningMessage = this.getWarningMessageForRoles(this.assignment?.additionalRoles ?? []);
    }

    makeAssignment(json: any): User {
        const legacyRole = json.roles.find(roleId => isLegacyRole(roleId));
        const additionalRoles = json.roles
            .filter(roleId => !isLegacyRole(roleId))
            .map(id => {
                const { name, domain } = this.roleMap.get(id);

                return { id, name, domain } as AdditionalRoleAssignment;
            });

        return new User({
            ...json,
            role: this.isCurrentUser ? null : legacyRole,
            view: this.isCurrentUser ? null : json.view,
            additionalRoles,
        });
    }

    updateView(viewReference: ViewReference): void {
        this.newUser.view = viewReference;
    }

    stepUpContactSupport(): void {
        this.stepUpSupportService.openStepUpRequestEnrollmentCase();
    }

    isSafeModeRole(roleId: UserRoleID): boolean {
        return this.roleMap.get(roleId)?.domain === KnownRoleDomains.SAFE_MODE;
    }

    private loadAdditionalRoles(): Observable<AdditionalRole[]> {
        return defer(() => {
            this.loadingRoles = true;

            return this.additionalUserRolesService.list().pipe(
                finalize(() => {
                    this.loadingRoles = false;
                }),
            );
        });
    }

    private generateAvailableRoles(additionalRoles: AdditionalRole[]): void {
        const mixedRoles = [...this.getLegacyRoles(), ...additionalRoles];
        const userAdditionalRoles = this.assignment?.additionalRoles ?? [];

        this.availableRoles = userRoleItemsFactory(mixedRoles, userAdditionalRoles, this.isCurrentUser);
        this.availableRoles.forEach(role => {
            this.roleMap.set(role.id, role);
        });
    }

    private getTimezoneData(): Timezone[] {
        return timezoneList.map(zone => {
            return { value: zone, timeOffset: 'GMT' + moment.tz(zone).format('Z') };
        });
    }

    private stepUpEnabledValidator(): AsyncValidatorFn {
        const stepUpRequest$ = of(void 0).pipe(
            exhaustMap(() =>
                this.stepUpService.stepUpEnabled(this.assignment?.email).pipe(
                    catchError(() => of(true)),
                    map((stepUpEnabled: boolean) => (stepUpEnabled ? null : { smaRole: true })),
                ),
            ),
            shareReplay(1),
        );

        return when(
            (ctrl: FormControl<UserRoleID[]>) => this.isEdit && ctrl.value.some(roleId => this.isSafeModeRole(roleId)),
        ).applyAsycValidator(() => stepUpRequest$);
    }
}
