import { Injectable, OnDestroy } from '@angular/core';
import { smartTimer } from '@pstg/smart-timer';
import {
    ArraySafeModeEligibilityInfo,
    ArraySafeModeEligibilityService,
    DataPage,
    FeatureFlagDxpService,
} from '@pure1/data';
import { keyBy } from 'lodash';
import moment from 'moment';
import { of, Subject } from 'rxjs';
import { catchError, exhaustMap, take, takeUntil } from 'rxjs/operators';
import { FeatureNames } from '../../model/FeatureNames';
import { ActionType, SimulationStep } from '../../services/simulation-step.service';

import { isCBS, isFA3xx, isFAE, isFAST, isFBS, isFlashblade, isPhantomFB, isVMware } from '../../utils/marketing';
import { SimulationsService } from '../simulation-summary/simulations.service';
import { ForecastedArray } from './forecasted-arrays-manager.service';
import { ForecastedLicense } from './forecasted-licenses-manager.service';
import { PhantomArrayUtilsService } from './phantom-array-utils.service';

export interface SimulationUiStatus {
    disabled: boolean;
    reason: string;
}

const SAFE_MODE_REFRESH_INTERVAL = moment.duration(30, 'seconds').asMilliseconds();

const ORDERABLE_MODELS = [
    'FA-X20R4',
    'FA-X50R4',
    'FA-X70R4',
    'FA-X90R4',
    'FA-C50R4',
    'FA-C70R4',
    'FA-C90R4',
    'FA-XL130',
    'FA-XL170',
];
/**
 * Handles enabled/disabled status for the different actions that arrays have.
 */
@Injectable() // Not provided in root: only gets used as sandboxed instance
export class SimulationUiStatusService implements OnDestroy {
    destroy$: Subject<void> = new Subject();
    arraySafeModeEligibilityMap: Record<string, ArraySafeModeEligibilityInfo>;
    arraySafeModeEligibilityMapChanged$: Subject<void> = new Subject();

    private flexLicensesQuoteSupported = false;
    private existingFbsHardwareSimulationSupported = false;

    constructor(
        private phantomArrayUtils: PhantomArrayUtilsService,
        private safeModeService: ArraySafeModeEligibilityService,
        private simulationsService: SimulationsService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {
        smartTimer(0, SAFE_MODE_REFRESH_INTERVAL)
            .pipe(
                takeUntil(this.destroy$),
                exhaustMap(() =>
                    this.safeModeService.list().pipe(
                        take(1),
                        catchError(error => {
                            const errorMsg = error && (error.data?.message || error.statusText);
                            console.error('Error updating safemode: ' + errorMsg, error);
                            return of<DataPage<ArraySafeModeEligibilityInfo>>(null);
                        }),
                    ),
                ),
            )
            .subscribe(data => {
                if (data?.response) {
                    this.arraySafeModeEligibilityMap = keyBy(data.response, d => d.arrayId);
                    this.deleteSafeModeSimulationStepForIneligibleArray();
                    this.arraySafeModeEligibilityMapChanged$.next();
                } // else got error
            });

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.FLEX_LICENSES_QUOTE_SUPPORT_IN_PLANNER)
            .pipe(take(1))
            .subscribe(feature => {
                this.flexLicensesQuoteSupported = feature?.enabled === true;
            });

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.EXISTING_FB_S_HARDWARE_SIMULATION)
            .pipe(take(1))
            .subscribe(feature => {
                this.existingFbsHardwareSimulationSupported = feature?.enabled === true;
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getSimulateHardwareButtonStatus(array: PureArray | ForecastedArray): SimulationUiStatus {
        const disabled =
            array == null ||
            isCBS(array.model) ||
            isFA3xx(array.model) ||
            isVMware(array.model) ||
            isFAST(array.model) ||
            (isFlashblade(array.model) &&
                !isPhantomFB(array.model) &&
                !(isFBS(array.model) && this.existingFbsHardwareSimulationSupported));
        let reason: string;
        if (!disabled) {
            reason = null;
        } else {
            if (array == null) {
                reason = 'Select an appliance to simulate';
            } else {
                reason = 'Selected appliance not supported';
            }
        }
        return { disabled, reason };
    }

    getSimulateWorkloadButtonStatus(array: PureArray | ForecastedArray): SimulationUiStatus {
        const disabled =
            array == null ||
            isCBS(array.model) ||
            isFlashblade(array.model) ||
            isFA3xx(array.model) ||
            this.simulationsService.hasSafeModeSimulation(array.id) ||
            isFAE(array.model) ||
            isFAST(array.model);
        let reason: string;
        if (!disabled) {
            reason = null;
        } else {
            if (array == null) {
                reason = 'Select an appliance to simulate';
            } else if (this.simulationsService.hasSafeModeSimulation(array.id)) {
                // we do not support workload simulation against an appliance with SafeMode simulation already
                reason = 'Selected appliance already has a Snapshot Policy (SafeMode) Simulation';
            } else {
                reason = 'Selected appliance not supported';
            }
        }
        return { disabled, reason };
    }

    getCloneButtonStatus(array: ForecastedArray, isCreatingPhantom: boolean): SimulationUiStatus {
        if (array != null && isFAST(array.model)) {
            return {
                disabled: true,
                reason: 'Selected appliance not supported',
            };
        }
        const result = this.phantomArrayUtils.supportsClone(array);

        if (isCreatingPhantom || !array) {
            return {
                disabled: true,
                reason: isCreatingPhantom ? '' : 'Select an appliance to clone',
            };
        }
        return {
            disabled: !result.supportsClone,
            reason: result.supportsClone
                ? ORDERABLE_MODELS.includes(array.model.toUpperCase())
                    ? ''
                    : `This appliance model, ${array.model}, can no longer be purchased. The appliance workload will be cloned to a newer model.`
                : result.reason,
        };
    }

    getSafeModeButtonStatus(array: PureArray | ForecastedArray): SimulationUiStatus {
        if (array != null && isFAST(array.model)) {
            return {
                disabled: true,
                reason: 'Selected appliance not supported',
            };
        }
        if (
            !this.arraySafeModeEligibilityMap ||
            !this.arraySafeModeEligibilityMap[array?.id]?.eligible ||
            this.simulationsService.hasWorkloadChangingSimulation(array?.id)
        ) {
            return {
                disabled: true,
                reason: '',
            };
        }
        return {
            disabled: false,
            reason: '',
        };
    }

    getLicenseRequestQuoteButtonStatus(license: ForecastedLicense): SimulationUiStatus {
        if (!this.flexLicensesQuoteSupported && license.licenseType?.toLowerCase().startsWith('//flex')) {
            return {
                disabled: true,
                reason: 'Not supported for //Flex license simulations, please reach out to your CSM for details.',
            };
        }

        return {
            disabled: false,
            reason: '',
        };
    }

    getModelSelectorFAEOptionStatus(array: ForecastedArray): SimulationUiStatus {
        const workloadSimulationStepTypes: ActionType[] = [
            'copy_array',
            'copy_volumes',
            'migrate_volumes',
            'migrate_array',
            'scale_array',
            'scale_volumes',
        ];
        const allWorkloadSimulationSteps: SimulationStep[] = [];
        workloadSimulationStepTypes.forEach(type => {
            const step = this.simulationsService.findArraySimulationStep(array.id, type);
            if (step) {
                allWorkloadSimulationSteps.push(step);
            }
        });

        if (allWorkloadSimulationSteps.length > 0) {
            return {
                disabled: true,
                reason: 'Current simulation includes a workload simulation that is not supported for FA-E.',
            };
        }

        return {
            disabled: false,
            reason: '',
        };
    }

    private deleteSafeModeSimulationStepForIneligibleArray(): void {
        this.simulationsService.arraysSimulations$
            .pipe(
                // it is triggered once simulationStepService.list() is fetched
                take(1),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.simulationsService
                    .getAllSavedSimulationStepsByActionType('apply_safe_mode')
                    .forEach(simulationStep => {
                        const arrayId = simulationStep?.arrays[0]?.id; // each safeMode simulation is associated with only one array.
                        if (this.arraySafeModeEligibilityMap[arrayId]?.eligible === false) {
                            this.simulationsService.deleteSimulationStep(simulationStep);
                        }
                    });
            });
    }
}
