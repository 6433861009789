import moment from 'moment-timezone';
import { PxCluster } from '@pure1/data';
import { PureArray } from '../model/PureArray';
import { ScheduledArray } from './support-upgrade/types';
import { WorkflowState } from '../software-lifecycle/purity-upgrades/purity-upgrades.interface';

export interface FreeTimeUpdates {
    freeTimesForFlashArray: FreeTime[];
    freeTimesForFlashBlade: FreeTime[];
    conflictingArrays: ScheduledArray[];
}

export interface FreeTime {
    start: moment.Moment;
    end: moment.Moment;
    capacity: number;
}

export enum FreeTimeType {
    UPGRADE = 'UPGRADE',
    SAFEMODE = 'SAFEMODE',
}

export interface Contact {
    id: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    /**
     * @deprecated role based authorization is going to be migrated to permission based.
     * Reach out to #ask-pure1-security-authz for more information.
     */
    isAdmin?: boolean;
    createdByIdpLogin?: string;
}

export interface SupportCaseFormValue {
    alternateContact: Contact;
    category: string;
    contacts: {
        primary: Contact;
        alternate: Contact;
    };
    product: {
        subCategory: SubCategoryOption;
        selectedProduct: {
            product: PureArray | PxCluster | string;
        };
    };
    description: string;
    subject: string;
}

export enum ProductLine {
    FlashArray = 'FlashArray',
    FlashBlade = 'FlashBlade',
    Portworx = 'Portworx',
    Others = 'Other',
    FlashRecover = 'FlashRecover',
    DisasterRecovery = 'PureProtect',
    None = '',
}

export interface CaseComposite {
    case: Case;
    array?: PureArray & { purityVersion: string };
    lastUserView?: Date | moment.Moment;
    status?: string;
}

export interface CommentComposite {
    comment: CaseComment;
    createdByUser?: User;
}

export interface AttachmentComposite {
    attachment: CaseAttachment;
    createdByUser?: User;
}

export interface Case {
    id: string;
    parentId?: string;
    caseNumber: string;
    contact: Contact;
    alternateContact?: Contact;
    arrayId: string;
    productLine: ProductLine;
    category: CaseCategory;
    subCategory: CaseSubCategory;
    nextActionDueDate: number;
    upgradeEndDateTime: number;
    numOfHops?: number;
    targetPurityVersion: string;
    purityVersion?: string;
    isUpgrade: boolean;
    upgradeCase: boolean;
    origin: string;
    description: string;
    maintenanceExecutionDateLocal?: Date | moment.Moment;
    maintenanceExecutionTimezoneLocal?: string;
    array?: Partial<PureArray & { purityVersion: string }>;
    cadence?: string;
    highestSeverity?: string;
    closedDate?: Date | moment.Moment;
    createdDate?: Date | moment.Moment;
    lastModifiedDate?: Date | moment.Moment;
    lastCaseActivity?: Date | moment.Moment;
    lastUserView?: Date | moment.Moment;
    severity?: CaseSeverity;
    subject?: string;
    isEscalated?: boolean;
    isClosed?: boolean;
    isReopenable: boolean;
    resolutionSummary?: string;
    preferredContactMethod?: string;
    timeFrameType?: FreeTimeType;
    supportProvider?: string;
}

export interface SupportCase extends Case {
    status?: string;
}

export interface SupportCaseWithUrl extends SupportCase {
    url: string;
}

export interface BulkSchedule {
    origin: string;
    schedule?: BulkScheduleItem[];
    category: CaseCategory; // category and subcategory are not required for Safemode cases (they are set in the BE)
    subCategory: CaseSubCategory;
    timeFrameType: FreeTimeType;
    description: string;
    contact: Contact;
    nextActionDueDate?: number;
    upgradeEndDateTime?: number;
    maintenanceExecutionTimezoneLocal?: string;
}

export interface BulkScheduleItem {
    arrayId: string;
    nextActionDueDate?: number;
    upgradeEndDateTime?: number;
    targetPurityVersion?: string;
    maintenanceExecutionTimezoneLocal?: string;
    numOfHops: number;
}

export interface UpgradeScheduleDraft {
    schedule: { arrayId: string; targetPurityVersion: string }[];
    safeModeSchedule: string[];
    description: string;
}

export enum ItemType {
    Attachment = 'attachment',
    Comment = 'comment',
    Email = 'email',
    Escalation = 'escalation',
}

export interface Parent {
    customer_CASE: string;
    escalated_CASE: string;
    internal_CASE: string;
    proactiveCase: boolean;
    reopenable: boolean;
    upgradeCase: boolean;
}

export interface User {
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    external: boolean;
    partner: boolean;
    cdmId?: string;
    serviceNowId?: string;
}

export interface SupportCaseItem {
    id: string;
    itemType: ItemType;
    createdDate: Date | moment.Moment;
    tmp?: boolean;
}

export interface CaseEmail extends SupportCaseItem {
    itemType: typeof ItemType.Email;
    from: string;
    to: string[];
    cc?: string[];
    bcc?: string[];
    subject: string;
    body: string;
    htmlBody: string;
}

export interface CaseAttachment extends SupportCaseItem {
    itemType: typeof ItemType.Attachment;
    caseId: string;
    key: string | null;
    name: string;
    description: string;
    bodyLength: number;
    bodyUrl: string;
    container: any;
    contentType: string;
    createdById: string | null;
    createdByUser: User;
}

export interface CaseEscalation extends SupportCaseItem {
    itemType: typeof ItemType.Escalation;
    caseId: string;
    comment: string;
    contactName: string;
    contactPhone: string;
    reason: string;
}

export interface CaseComment extends SupportCaseItem {
    itemType: typeof ItemType.Comment;
    caseId: string;
    body: string;
    createdById: string | null;
    createdByUser: User;
    lastModifiedDate: Date | moment.Moment;
    published: boolean;
    parent: Parent;
}

export interface CaseRecentItems {
    caseId: string;
    attachments: CaseAttachment[];
    comments: CaseComment[];
    escalations: CaseEscalation[];
    emails: CaseEmail[];
}

export interface CaseRecentCompositeItems {
    caseId: string;
    attachments: AttachmentComposite[];
    comments: CommentComposite[];
    escalations: CaseEscalation[];
    emails: CaseEmail[];
}

export interface SubCategoryOption {
    group: string;
    displayName: string;
    subCategory: CaseSubCategory;
    productLine: ProductLine;
}

export enum CaseSeverity {
    CRITICAL = '1',
    MAJOR = '2',
    MINOR = '3',
    INFORMATIONAL = '4',
}

export enum CaseCategory {
    // Normal cases
    ADMINISTRATIVE = '3',
    EVERGREENONEADMINISTRATION = '30',
    ENVIRONMENTALS = '4',
    HARDWARE = '5',
    SOFTWARE = '6',

    // Scheduled cases (require timeframe)
    UPGRADE = '12',
    DEPLOYMENT = '14',
}

// Subcategories are prefixed with main category for clarity which subcategory belongs to which category
export enum CaseSubCategory {
    // ADMINISTRATIVE
    ADMINISTRATIVE_SUBSCRIPTION_ONBOARD_OFFBOARD = '210',
    ADMINISTRATIVE_ACCOUNT_ADJUSTMENT = '228',
    ADMINISTRATIVE_GENERAL_INQUIRY = '172',

    // EVERGREENONEADMINISTRATION
    HARDWARE_MANAGEMENT = '252',
    LICENSE_MANAGEMENT = '253',
    RENEWALS_AND_EXPANSIONS = '251',
    SLA_MANAGEMENT_REQUEST_SLA_CREDITS = '254',
    USAGE_AND_BILLING = '250',

    // ENVIRONMENTALS
    ENVIRONMENTALS_NON_SPECIFIC = ' ',
    ENVIRONMENTALS_MICROSOFT = '187',
    ENVIRONMENTALS_VM_WARE_VIRTUALIZATION = '188',
    ENVIRONMENTALS_NIX = '189',
    ENVIRONMENTALS_CLOUD = '190',
    ENVIRONMENTALS_DATABASE = '191',
    ENVIRONMENTALS_CONTAINERS = '192',
    ENVIRONMENTALS_FILE_SHARING = '193',
    ENVIRONMENTALS_FC = '194',
    ENVIRONMENTALS_ETHERNET = '195',

    // HARDWARE
    HARDWARE_SOFTWARE = '174',
    HARDWARE_REPLICATION = '180',
    HARDWARE_PLUGINS = '181',
    HARDWARE_SPACE = '182',
    HARDWARE_PERFORMANCE = '184',
    HARDWARE_HARDWARE = '196',

    // SOFTWARE
    SOFTWARE_SOFTWARE = '197',
    SOFTWARE_REPLICATION = '177',
    SOFTWARE_EDGE_SERVICES = '260',
    SOFTWARE_PLUGINS = '226',
    SOFTWARE_SPACE = '175',
    SOFTWARE_PERFORMANCE = '223',
    SOFTWARE_HARDWARE = '183',

    // UPGRADE
    UPGRADE_SOFTWARE_UPGRADE = '161',
    UPGRADE_HARDWARE_UPGRADE = '162',

    // DEPLOYMENT
    DEPLOYMENT_ACTIVE_CLUSTER = '163',
    DEPLOYMENT_EDGE_SERVICES = '259',
    DEPLOYMENT_SAFEMODE = '164',
    DEPLOYMENT_VM_ANALYTICS = '165',
    DEPLOYMENT_VVOLS = '166',
    DEPLOYMENT_WFS = '167',
    DEPLOYMENT_FILE_SERVICE = '168',
    DEPLOYMENT_CLOUD_BLOCK_STORE = '169',
    DEPLOYMENT_PURITY_MIGRATION = '170',
    DEPLOYMENT_FIELD_WIPE = '171',
    DEPLOYMENT_TUNABLE = '222',
    DEPLOYMENT_HARDWARE_ORDER = '173',

    // DISPATCH
    DISPATCH_DISPATCH_HARDWARE = '224',
    DISPATCH_DISPATCH_REPLACEMENT = '225',
    DISPATCH_DISPATCH_ONSITE_ENGINEER = '176',
}

export enum CaseEscalationReason {
    CLOSED_BUT_NOT_RESOLVED = '5',
    PROGRESS_BY_EMPLOYEE = '10',
    PROGRESS_BY_CUSTOMER = '15',
    SEVERITY_INCREASED = '20',
    COMMUNICATION_DELAY = '25',
    CUSTOMER_REQUEST = '30',
    MISSED_SCHEDULED_COMMITMENT = '35',
    RMA_ISSUE = '40',
    OTHER = '100',
}

export interface CreatedUpgradeCaseResult {
    workflowStates: WorkflowState[];
    caseId: string;
    internalCaseId: string;
}

export interface CasesQuery {
    closed: boolean;
}
