import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SfdcPurchaseOrder, OpportunityQuote } from '@pure1/data';
import { QuoteUrlService } from '../../../../service-catalog/services/quote-url.service';
import { QuoteStatus } from '../../../../data/models/opportunity-quote';

export const NO_PO_MESSAGE = 'Please select a PO file to upload by clicking "Select PO File".';

@Component({
    selector: 'quote-poupload-dialog',
    templateUrl: './quote-poupload-dialog.component.html',
})
export class QuotePOUploadDialogComponent {
    @Input() readonly opportunityQuote: OpportunityQuote;
    @Input() readonly errorMessage: string;
    @Input() readonly fileToUpload: File;

    @Output() readonly fileChange = new EventEmitter<File>();

    @ViewChild('fileInput') readonly fileInput: ElementRef<HTMLInputElement>;

    readonly MAX_FILE_SIZE_IN_BYTES = 500 * 1024 * 1024; // 500MiB
    readonly ERROR_PREFIX = 'Error: ';
    readonly FILE_TOO_LARGE = 'file too large';
    readonly FILE_UPLOADED_STATUS = 'File uploaded';
    readonly QuoteStatus = QuoteStatus;

    isDragover = false;

    constructor(private quoteUrlService: QuoteUrlService) {}

    onFileChange(event: Event): void {
        const target = event.target as HTMLInputElement;
        const file = target.files?.[0];

        this.fileChange.emit(file);
    }

    onDragover(): void {
        this.isDragover = true;
    }

    onDragleave(): void {
        this.isDragover = false;
    }

    onDrop(): void {
        this.isDragover = false;
    }

    onRemoveFile(): void {
        this.fileInput.nativeElement.value = '';
        this.fileChange.emit(null);
    }

    openNewWindowForPOFileDownload(poFile: SfdcPurchaseOrder): void {
        this.quoteUrlService.openNewWindowOnDownloadUrlWith302Response(
            this.opportunityQuote.salesFlowId,
            poFile.pure1Id,
            'PO',
        );
    }
}
