import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OpportunityQuote, QuoteStatus } from '@pure1/data';
import { AssetManagementSummaryInfo } from '../../asset-management-summary-card/asset-management-summary-card.component';

@Component({
    selector: 'quote-management-summary-totals',
    templateUrl: './quote-management-summary-totals.component.html',
})
export class QuoteManagementSummaryTotalsComponent implements OnChanges {
    @Input() readonly opportunityQuotes: OpportunityQuote[];

    summaries: AssetManagementSummaryInfo[];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.opportunityQuotes) {
            this.summaries = [
                {
                    title: 'Total',
                    svg: 'file-grey.svg',
                    value: this.opportunityQuotes?.length || 0,
                },
                {
                    title: 'Action Required',
                    svg: 'file-blue.svg',
                    value: this.getStatusCount(QuoteStatus.OPEN, QuoteStatus.ACTION_REQUIRED),
                },
                {
                    title: 'Accepted',
                    svg: 'file-checkmark-teal.svg',
                    value: this.getStatusCount(QuoteStatus.ACCEPTED),
                },
                {
                    title: 'PO Uploaded',
                    svg: 'po-uploaded.svg',
                    value: this.getStatusCount(QuoteStatus.PO_UPLOADED),
                },
                {
                    title: 'PO Approved',
                    svg: 'file-checkmark-teal.svg',
                    value: this.getStatusCount(QuoteStatus.PO_APPROVED),
                },
                {
                    title: 'PO Rejected',
                    svg: 'po-rejected.svg',
                    value: this.getStatusCount(QuoteStatus.PO_REJECTED),
                },
                {
                    title: 'Order Booked',
                    svg: 'order-booked.svg',
                    value: this.getStatusCount(QuoteStatus.ORDER_BOOKED),
                },
            ];
        }
    }

    private getStatusCount(...statuses: QuoteStatus[]): number {
        return this.opportunityQuotes?.filter(quote => statuses.includes(quote.status)).length || 0;
    }
}
