import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StepUpVerifyResponse } from '../../../data';
import { addStepUpToken, removeExpiredStepUpTokens } from '../../../redux/actions';
import { NgRedux } from '../../../redux/ng-redux.service';
import { IState } from '../../../redux/pure-redux.service';
import { StepUpModalService } from '../../../step-up/services/step-up-modal.service';
import { StepUpAuthRequirements, WorkflowEventType, WorkflowState } from '../purity-upgrades.interface';
import { UpgradePrechecksErrorService } from './error.service';

const AUTHORIZATION_STEP_UP_HEADER_NAME = 'Authorization-Step-Up';
export const BULK_INSTALL_TOKEN_KEY = 'bulk-install';

@Injectable({ providedIn: 'root' })
export class BulkInstallService {
    private baseUrl = '/rest/cfwd/api/v1/bulk-operation/';

    constructor(
        private http: HttpClient,
        private stepUpModalService: StepUpModalService,
        private ngRedux: NgRedux<IState>,
        private errorService: UpgradePrechecksErrorService,
    ) {}

    startInstallWithStepUp(workFlowIDs: number[], stepUpHeader?: string): Observable<WorkflowState[]> {
        let headers = new HttpHeaders();
        if (stepUpHeader) {
            headers = headers.append(AUTHORIZATION_STEP_UP_HEADER_NAME, stepUpHeader);
        }

        return this.http.post<WorkflowState[]>(this.baseUrl + 'event', this.getRequestObject(workFlowIDs), { headers });
    }

    startInstall(workFlowIDs: number[]): Observable<WorkflowState[]> {
        let headers = new HttpHeaders();

        this.ngRedux.dispatch(removeExpiredStepUpTokens(moment()));
        const tokenState = this.ngRedux.getState().stepUpTokens[BULK_INSTALL_TOKEN_KEY];

        if (tokenState) {
            headers = headers.append(AUTHORIZATION_STEP_UP_HEADER_NAME, tokenState.accessToken);
        }

        const requestObject = this.getRequestObject(workFlowIDs);

        return this.http
            .post<StepUpAuthRequirements>(this.baseUrl + 'event/preflight', requestObject, { headers })
            .pipe(
                mergeMap(requirements => {
                    if (requirements) {
                        return this.stepUpModalService
                            .stepUp(
                                requirements.aud,
                                requirements.authorizationDetails,
                                requirements.singleUse,
                                requirements.ttl,
                            )
                            .pipe(
                                mergeMap((stepUpResponse: StepUpVerifyResponse) => {
                                    if (stepUpResponse === null) {
                                        throw new Error('cancelled');
                                    }

                                    if (!requirements.singleUse) {
                                        const expire = moment().add(requirements.ttl, 'seconds');
                                        this.ngRedux.dispatch(
                                            addStepUpToken(BULK_INSTALL_TOKEN_KEY, stepUpResponse.accessToken, expire),
                                        );
                                    }

                                    return this.startInstallWithStepUp(workFlowIDs, stepUpResponse.accessToken);
                                }),
                                catchError(this.errorService.errorInterceptor),
                            );
                    } else if (headers.has(AUTHORIZATION_STEP_UP_HEADER_NAME)) {
                        return this.startInstallWithStepUp(workFlowIDs, tokenState.accessToken);
                    } else {
                        return this.startInstallWithStepUp(workFlowIDs);
                    }
                }),
            );
    }

    getRequestObject(workFlowIDs: number[]) {
        return {
            event: {
                type: WorkflowEventType.StartInstallationAsync,
            },
            upgradeWorkflowIds: workFlowIDs,
        };
    }
}
