import { Directive, OnInit } from '@angular/core';
import { DisasterRecoveryClusterConfiguration, DisasterRecoveryClusterConfigurationService } from '@pure1/data';
import { map, mergeMap, Observable, of, Subject } from 'rxjs';
import { NgRedux } from '../redux/ng-redux.service';
import { IState } from '../redux/pure-redux.service';
import { deselectDisasterRecoveryCluster, selectDisasterRecoveryCluster } from '../redux/actions';
import { catchError } from 'rxjs/operators';

@Directive()
export class DisasterRecoveryBaseComponent implements OnInit {
    loadingCluster = false;
    clusterId: string | null = null;
    clusterLoaded$ = new Subject<void>();
    clusterAwsProviderId: string | null = null;
    clusterBootstrapped = false;
    isV2Cluster = false;

    constructor(
        protected clusterConfigurationService: DisasterRecoveryClusterConfigurationService,
        protected ngRedux: NgRedux<IState>,
    ) {}

    ngOnInit(): void {
        this.clusterId = this.ngRedux.getState().disasterRecoveryCluster.clusterId;

        this.loadingCluster = true;
        const clusterConfiguration$: Observable<DisasterRecoveryClusterConfiguration> = this.clusterId
            ? this.loadClusterConfigurationFromRedux()
            : this.loadClusterConfigurationAndPersistToRedux();

        clusterConfiguration$.subscribe({
            next: clusterConfiguration => {
                this.clusterAwsProviderId = clusterConfiguration?.targetAwsCredentials?.providerId ?? null;
                this.clusterBootstrapped = this.isClusterBootstrapped(clusterConfiguration);
                this.isV2Cluster = clusterConfiguration?.cluster?.isV2 === true;
                this.loadingCluster = false;
                this.clusterLoaded$.next();
            },
            error: () => {
                this.clusterBootstrapped = false;
                this.loadingCluster = false;
            },
        });
    }

    /**
     * The clusterId is stored in redux because we want to remember which cluster we are operating on. This method
     * loads cluster configuration based on the stored clusterId.
     * Note that this method will remove the clusterId stored in redux in case the API returns an error while loading
     * cluster configuration for the stored clusterId, load a fresh clusterId from the API, and store that back to redux.
     * Usually the API would return 404 in case the cluster was removed in the meantime.
     */
    private loadClusterConfigurationFromRedux(): Observable<DisasterRecoveryClusterConfiguration> {
        return of(this.clusterId).pipe(
            mergeMap(clusterId => this.loadClusterConfigurationForClusterId(clusterId)),
            catchError(() => {
                // Deselect cached cluster on error and load a fresh one
                this.ngRedux.dispatch(deselectDisasterRecoveryCluster());
                return this.loadClusterConfigurationAndPersistToRedux();
            }),
        );
    }

    /**
     * This method loads clusterId from the API, stores it to redux and then loads cluster configuration for the
     * loaded clusterId.
     */
    private loadClusterConfigurationAndPersistToRedux(): Observable<DisasterRecoveryClusterConfiguration> {
        return this.clusterConfigurationService.getCluster().pipe(
            map(cluster => {
                this.ngRedux.dispatch(selectDisasterRecoveryCluster(cluster?.id));
                this.clusterId = cluster?.id;
                return cluster?.id;
            }),
            mergeMap(clusterId => this.loadClusterConfigurationForClusterId(clusterId)),
        );
    }

    private loadClusterConfigurationForClusterId(clusterId: string): Observable<DisasterRecoveryClusterConfiguration> {
        if (clusterId === null) {
            return of(null);
        }
        return this.clusterConfigurationService.getClusterConfiguration(clusterId);
    }

    private isClusterBootstrapped(clusterConfiguration: DisasterRecoveryClusterConfiguration): boolean {
        if (clusterConfiguration?.cluster?.isV2 === true) {
            return (
                clusterConfiguration.sourceNetwork != null &&
                clusterConfiguration.sourceStorage != null &&
                clusterConfiguration.targetNetwork != null &&
                ![
                    'NOT_STARTED',
                    'PENDING_DEPLOYMENT',
                    'DEPLOYED',
                    'PENDING_SEED_NODE_CONNECTION',
                    'PENDING_TARGET_NODE_CONNECTION',
                    'PENDING_VPN',
                ].includes(clusterConfiguration.cluster.status)
            );
        }

        // VPN is last step in the wizard for V1
        return clusterConfiguration?.vpn?.status === 'VALID';
    }
}
