import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ButtonComponent, EmptyStateComponent, LinkComponent } from '@pure/hive';
import { Angulartics2Module } from 'angulartics2';

import { AlertsSharedModule } from '../messages/alerts/alerts.shared.module';
import { SupportNavComponent } from './support-nav/support-nav.component';
import { UIModule } from '../ui/ui.module';
import { SharedModule } from './shared/shared.module';

import { SupportTabComponent } from './support-tab/support-tab.component';
import { SupportTabNavComponent } from './tab-nav/tab-nav.component';
import { MergeArraysPipe } from './pipes/merge-arrays/merge-arrays.pipe';
import { EscalationReasonPipe } from './pipes/escalation-reason/escalation-reason.pipe';
import { SupportNavCasesListComponent } from './support-nav-cases-list/support-nav-cases-list.component';
import { SupportCaseDraftService } from './services/support-case-draft.service';
import { CaseItemDataService } from './services/case-item.service';
import { CaseAttachmentService } from './services/case-attachment.service';
import { CaseAsyncJobService } from './services/case-async-job.service';
import { CaseCommentService } from './services/case-comment.service';
import { CaseManager } from './services/case-data.service';
import { CaseEscalationService } from './services/case-escalation.service';
import { SupportErrorService } from './services/error.service';
import { SupportContactService } from './services/support-contact.service';
import { CaseService } from './services/case.service';
import { CaseEmailService } from './services/case-email.service';
import { SupportCaseService } from './services/support-create-edit.service';
import { FileReaderService } from './services/file-reader.service';
import { EscalateButtonComponent } from './escalate-button/escalate-button.component';
import { EscalateModalComponent } from './escalate-button/escalate-modal.component';
import { SupportCaseOrchestratorService } from './services/support-case-orchestrator.service';
import { SupportCaseComponent } from './support-case/support-case.component';
import { UploadModalComponent } from './upload-modal/upload-modal.component';
import { NoSupportModalComponent } from './no-support-modal/no-support-modal.component';
import { SupportUpgradeModule } from './support-upgrade/support-upgrade.module';
import { EmailFromPipe } from './pipes/email-from/email-from.pipe';
import { PureSupportAvatarComponent } from './pure-support-avatar/pure-support-avatar.component';
import { SafeModeModule } from '../safemode/safemode.module';
import { ProductSectionModule } from './product-section/product-section.module';
import { AuthorizerModule } from '@pure/authz-authorizer';
import { SupportRoutingModule } from './support-routing.module';
import { SupportCaseComponentWrapper } from '../upgrade-directives/support-case.component';
import { ContactSectionModule } from './contact-section/contact-section.module';
import { CreateEditSupportCaseModule } from './create-edit-support-case/create-edit-support-case.module';

@NgModule({
    imports: [
        AlertsSharedModule,
        AuthorizerModule,
        CommonModule,
        ContactSectionModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        UIModule,
        Angulartics2Module,
        SharedModule,
        SupportUpgradeModule,
        ProductSectionModule,
        SafeModeModule,
        SupportRoutingModule,
        CreateEditSupportCaseModule,
        EmptyStateComponent,
        ButtonComponent,
        LinkComponent,
    ],
    declarations: [
        EscalateButtonComponent,
        EscalateModalComponent,
        SupportCaseComponent,
        SupportNavComponent,
        SupportNavCasesListComponent,
        SupportTabNavComponent,
        SupportCaseComponentWrapper,
        NoSupportModalComponent,
        SupportTabComponent,
        MergeArraysPipe,
        EscalationReasonPipe,
        EmailFromPipe,
        UploadModalComponent,
        PureSupportAvatarComponent,
    ],
    providers: [
        CaseItemDataService,
        CaseAttachmentService,
        CaseAsyncJobService,
        CaseCommentService,
        CaseManager,
        CaseService,
        CaseEmailService,
        CaseEscalationService,
        SupportContactService,
        SupportErrorService,
        SupportCaseDraftService,
        SupportCaseService,
        SupportCaseOrchestratorService,
        FileReaderService,
    ],
})
export class SupportModule {}
